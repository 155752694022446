<div class="fh-page-wrapper">
  <header class="fh-header header bottom-border-divider">
    <app-backbuttontemplate></app-backbuttontemplate>
    <h1 class="title">Muted users</h1>
  </header>

  <main class="fh-main content">
    <h2 class="sub-title" #title>Muted Users</h2>

    <app-loadingcircle *ngIf="isLoading && !isEmpty"> </app-loadingcircle>

    <app-emptyhinttemplate *ngIf="!isLoading && isEmpty">
    </app-emptyhinttemplate>

    <div id="listWrapper">
      <div class="item" *ngFor="let item of mutedusers; let i = index">
        <div>
          <div class="muted-24-hint" *ngIf="item.temp">Muted for 24 hours</div>

          <div class="itemStartWrapper">
            <img
              src="{{
                item.profilePhoto
                  ? (item.profilePhoto | imgScaleHelper: 50)
                  : '/assets/default_profile_pic.jpg'
              }}"
              onerror="this.src='/assets/default_profile_pic.jpg';this.onerror='';"
              class="userImage"
              (click)="routingHelper.user(item.username, item.userID)"
              alt="{{ item.username }} photo"
            />

            <span
              class="username"
              (click)="routingHelper.user(item.username, item.userID)"
            >
              {{ item.username ? item.username : 'username' }}</span
            >
          </div>
        </div>

        <span
          class="removeButton"
          (click)="unmuteUser(item.username, item.userID, item.temp, i)"
        >
          Unmute
          <div class="tooltiptext">Unmute this user</div>
        </span>
      </div>
    </div>
  </main>
</div>
