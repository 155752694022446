import { Component, DestroyRef, inject } from '@angular/core'
import { UsualDividerComponent } from '../../templates/dividers/usual-divider/usual-divider.component'
import { CommonModule } from '@angular/common'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { MainStateService } from 'src/app/shared/services/main/main-state.service'
import { SystemService } from 'src/app/shared/services/system/systemservice.service'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { Router, RouterModule } from '@angular/router'
import { MaindataService } from 'src/app/shared/services/data/maindata.service'
import { FooterNavItemComponent } from './footer-nav-item/footer-nav-item.component'
import { tap } from 'rxjs'

@Component({
  selector: 'app-main-footer-navigation',
  standalone: true,
  imports: [
    UsualDividerComponent,
    CommonModule,
    RouterModule,
    FooterNavItemComponent
  ],
  templateUrl: './main-footer-navigation.component.html',
  styleUrl: './main-footer-navigation.component.css'
})
export class MainFooterNavigationComponent {
  selectedTab = 0
  isLoggedIn: boolean
  isDarkmode: boolean

  private destroyRef = inject(DestroyRef)

  constructor(
    private authService: AuthService,
    private mainStateService: MainStateService,
    public maindataService: MaindataService,
    private router: Router
  ) {
    this.isLoggedIn = this.authService.isLoggedIn()
    this.isDarkmode = SystemService.isDarkmode()
  }

  ngOnInit(): void {
    this.setUpNavBarLogic()
  }

  setUpNavBarLogic() {
    this.mainStateService.currentlySelectedNavTabSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((newTabNumber) => (this.selectedTab = newTabNumber))
  }

  navClick_0 = () => this.navigationClick(0)
  navClick_1 = () => this.navigationClick(1)
  navClick_3 = () => this.navigationClick(3)
  navClick_4 = () => this.navigationClick(4)
  navClick_12 = () => this.navigationClick(12)

  /**
   * Navigates to this tab if valid input. If already navigated to this tab, scrolls to top.
   */
  navigationClick(tabNr: number) {
    if (this.selectedTab == tabNr) {
      this.mainStateService.emitScrollUpCall(tabNr)
    } else {
      if (tabNr == 4) {
        const stateInput = {
          state: {
            newNotificationsCount: this.maindataService.newNotificationsCount
          }
        }
        this.router.navigate(['notifications'], stateInput)
        this.selectedTab = tabNr
      }
    }
  }
}
