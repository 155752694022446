<button
  class="subtle-button wrapper"
  [ngClass]="{ background: background }"
  (click)="back()"
  aria-label="Go back"
>
  <div id="goback-button" matTooltip="Go back" [matTooltipShowDelay]="1000">
    <img
      class="dark-icon"
      id="arrow-back"
      src="/assets/arrow_back.png"
      alt="go back"
    />
  </div>

  <p class="text" *ngIf="showText">Go back</p>
</button>
