import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { getDatabase, ref } from 'firebase/database'
import { getFirestore } from 'firebase/firestore'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { HTMLFormattingService } from 'src/app/shared/services/formatting/html/htmlformatting.service'
import { NumberFormatService } from 'src/app/shared/services/formatting/number/numberformat.service'
import { ImageLoadingService } from 'src/app/shared/services/imageloading/imageloading.service'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { RoutinghelperService } from 'src/app/shared/services/router/routinghelper.service'
import { ChatDataService } from 'src/app/shared/services/data/chatdata.service'
import { DatasharingService } from 'src/app/shared/services/data/datasharing.service'
import { SystemService } from 'src/app/shared/services/system/systemservice.service'
import { ChatactionComponent } from '../dialogs/chataction/chataction.component'
import { GroupsdataService } from 'src/app/shared/services/data/groupsdata.service'
import { CreategroupComponent } from '../groups/creategroup/creategroup.component'
import { MainComponent } from '../main/main.component'
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling'
import { MainStateService } from 'src/app/shared/services/main/main-state.service'
import { Subscription } from 'rxjs'
import { SeoHelperService } from 'src/app/shared/services/seo/seo-helper.service'
import { SetTimeoutService } from 'src/app/shared/services/ssr/set-timeout.service'
import { WaitForService } from 'src/app/shared/services/ssr/wait-for.service'
import { IsBrowserService } from 'src/app/shared/services/ssr/isbrowser.service'

@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.css']
})
export class ChatsComponent implements OnInit {
  isLoggedIn = this.authService.isLoggedIn()
  allowSelectMode = this.authService.isLoggedIn()

  pageTitle = this.authService.isLoggedIn()
    ? 'Chats'
    : `${StrHlp.COMMUNITY_NAME} Chat Groups`

  chatsLoadingInProgress: boolean = false

  userID: any = null
  dbFirestore = getFirestore()
  rtdb = ref(getDatabase())
  dbRTDB = getDatabase()

  openChatCallback: (item: any) => void

  isMobile = SystemService.isMobile()

  urlHash = ''

  @ViewChild('scrollContainerChats')
  scrollContainerChats!: CdkVirtualScrollViewport
  @ViewChild('scrollContainerGroups') scrollContainerGroups!: ElementRef

  /**
   * 0 = chats tab
   * 1 = groups tab
   */
  selectedMode = 0

  scrollUpSubscription: Subscription | null = null

  constructor(
    public htmlFormattingService: HTMLFormattingService,
    public numberFormatService: NumberFormatService,
    public dialog: MatDialog,
    public imgHlp: ImageLoadingService,
    public authService: AuthService,
    private router: Router,
    public routingHelper: RoutinghelperService,
    public chataDataService: ChatDataService,
    public datasharingService: DatasharingService,
    public groupdataService: GroupsdataService,
    private mainStateService: MainStateService,
    private seoHelper: SeoHelperService,
    private setTimeoutService: SetTimeoutService,
    private waitForService: WaitForService,
    private isBrowserService: IsBrowserService
  ) {
    this.openChatCallback = (item: any) => {
      this.openChat(item)
    }

    this.groupdataService.loadFromServerSide()

    /*
    if (this.isBrowserService.isServer()) {
      //this.waitForService.waitFor(
      //() => this.groupdataService.loadFromServerSide(),
      //"group_data_service"
      //)
    } else if (this.isBrowserService.isBrowser()) {
      this.groupdataService.transferStateData()
    }
    */
  }

  ngOnInit(): void {
    this.userID = AuthService.getUID()

    this.isMobile = SystemService.isMobile()

    // scroll to top listener
    this.scrollUpSubscription = this.mainStateService.srollUpSubject.subscribe(
      (tab) => {
        if (tab == 3) {
          this.scrollTop()
        }
      }
    )

    if (this.isLoggedIn) {
      // check if its /chats or /groupchats
      if (this.router.url.includes('/groupchats')) {
        this.selectedMode = 1
      }
    } else {
      // not logged in
      // only show groups
      this.selectedMode = 1
    }

    if (this.router.url.includes('/groupchats')) {
      this.seoHelper.setForSomePage(
        `${StrHlp.COMMUNITY_NAME} Chat Rooms`,
        `Global chat rooms for the ${StrHlp.COMMUNITY_NAME} community. Find the best group chats for you. Discover trending ${StrHlp.COMMUNITY_NAME} group chats or create your own.`
      )
    } else {
      this.seoHelper.setForSomePage('Chats', 'Your chats')
    }
  }

  ngAfterViewInit(): void {
    // set url hash
    this.urlHash = this.router.url

    if (this.scrollContainerChats) {
      // apply scroll state
      let scrollTop = 0
      if (MainComponent.scrollSavingMap.has(this.urlHash)) {
        scrollTop = MainComponent.scrollSavingMap.get(this.urlHash)
      }

      this.setTimeoutService.setTimeout(() => {
        // must be inside of this
        this.scrollContainerChats.elementRef.nativeElement.scrollTop = scrollTop
      }, 0)
    }
  }

  ngOnDestroy() {
    //console.log("Drago1: router.url="+this.router.url+", scrollTop="+this.body.nativeElement.scrollTop);
    if (this.scrollContainerChats) {
      MainComponent.scrollSavingMap.set(
        this.urlHash,
        this.scrollContainerChats.elementRef.nativeElement.scrollTop
      )
    }

    if (this.scrollContainerGroups) {
      MainComponent.scrollSavingMap.set(
        this.urlHash,
        this.scrollContainerGroups.nativeElement.scrollTop
      )
    }

    if (this.scrollUpSubscription) {
      this.scrollUpSubscription.unsubscribe()
    }
  }

  scrollTop() {
    if (this.scrollContainerChats) {
      this.scrollContainerChats.elementRef.nativeElement.scrollTop = 0
    }

    if (this.scrollContainerGroups) {
      this.scrollContainerGroups.nativeElement.scrollTop = 0
    }
  }

  openChat(chatItem: any): void {
    const data = {
      chatID: chatItem.chatID,
      name: chatItem.name,
      verified: chatItem.verified,
      image: chatItem.image,
      isGroup: chatItem.isGroup,
      isPrivate: chatItem.isPrivate,
      otherUserID: chatItem.otherUserID,
      isMeUser1: chatItem.isMeUser1,
      newMessagesCount: chatItem.newMessagesCount,
      chatOK1: chatItem.chatOK1,
      chatOK2: chatItem.chatOK2
    }

    this.router.navigate(['message'], { state: data })

    // mark chat as read locally for UI
    chatItem.newMessagesCount = -1
    this.chataDataService.chatList[chatItem.chatID] = chatItem
  }

  pinChatCallback(chatID: string) {
    this.chataDataService.pinChat(chatID)
  }
  unpinChatCallback(chatID: string) {
    this.chataDataService.unpinChat(chatID)
  }

  searchButtonClick() {
    const data = {
      pageTitle: 'Search chats...',
      disableAutoFocus: false,
      clickCallback: (itemChat: any) => {
        this.openChatCallback(itemChat)
      }
    }

    this.dialog.open(ChatactionComponent, {
      panelClass: 'bottom-sheet-dialog',
      maxWidth: '100vw',
      width: '100%',
      data: data
    })
  }

  onSelectChatsMode_AsParam = () => this.onSelectChatsMode()
  onSelectGroupsMode_AsParam = () => this.onSelectGroupsMode()

  onSelectChatsMode() {
    if (this.allowSelectMode) {
      if (this.selectedMode == 0) {
        // is already selected, scroll to top
        this.mainStateService.emitScrollUpCall(3)
      } else {
        this.selectedMode = 0
      }
    }
  }

  onSelectGroupsMode() {
    if (this.selectedMode == 1) {
      // is already selected, scroll to top
      this.mainStateService.emitScrollUpCall(3)
    } else {
      this.selectedMode = 1
    }
  }

  onSwipeRight() {
    if (!this.allowSelectMode) {
      return
    }

    let newVal = this.selectedMode - 1
    if (newVal < 0) {
      newVal = 0
    }

    this.selectedMode = newVal
  }

  onSwipeLeft() {
    if (!this.allowSelectMode) {
      return
    }

    let newVal = this.selectedMode + 1
    if (newVal > 1) {
      newVal = 1
    }

    this.selectedMode = newVal
  }

  openCreateNewGroup() {
    const data = {
      isForCreating: true
    }
    this.dialog.open(CreategroupComponent, { data: data })
  }
}
