import { Injectable } from '@angular/core'
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  count,
  defaultIfEmpty,
  distinctUntilChanged,
  filter,
  forkJoin,
  map,
  repeat,
  share,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
  throttleTime,
  withLatestFrom,
  zip
} from 'rxjs'
import { SystemService } from '../system/systemservice.service'

@Injectable({
  providedIn: 'root'
})
export class ClipsStateService {
  isMuted = false //SystemService.isMobileIOS();

  /**
   * bool: true=pause, false=play
   */
  private pausePlaySubject = new Subject<void>()

  private throttleTimePausePlay = 200
  private throttleTimeTouchEvent = 70

  pausePlay$: Observable<void> | null = null

  touchStartSubject = new Subject<TouchEvent>()
  touchEndSubject = new Subject<TouchEvent>()

  // those touchends where the respective touchstart has a minimum vertical scroll dist
  // so we consider them scroll touchevents.
  scrollTouchEnds$ = new Observable<TouchEvent | null>()

  // values emitted here iif a clip becomes visible but was invisible before.
  // implies that concurrent values are distinct. init with 0.
  currentClipIndexSubject = new BehaviorSubject<number>(0)

  /**
   * combine latest. Not ideal but good enough. (other solutions like zip were not reliable)
   */
  touchEndEventCurrentClipIndex_Obs$: Observable<
    [number, TouchEvent | null]
  > | null = null

  closeSubject = new Subject<void>()

  constructor() {
    this.setUpPlayPauseLogic()
    this.setUpAutoplayLogic()
  }

  setUpAutoplayLogic() {
    this.scrollTouchEnds$ = zip([
      this.touchStartSubject,
      this.touchEndSubject
    ]).pipe(
      filter(([touchStartEvent, touchEndEvent]) => {
        // filter min vertical dist.
        // important to filter out normal clicks for example.
        const minimumDist = 30

        const y1 = touchStartEvent.changedTouches[0].clientY
        const y2 = touchEndEvent.changedTouches[0].clientY

        const verticalDistance = Math.abs(y1 - y2)
        //console.log("vrSc. y1:",y1," || y2:",y2, " || verticalDistance:",verticalDistance);

        return verticalDistance >= minimumDist
      }),
      map(([touchStartEvent, touchEndEvent]) => touchEndEvent),
      throttleTime(this.throttleTimeTouchEvent),
      share()
    )

    // autoplay-logic obs
    /**
     * new solution uses withLatestFrom. It will hence only emit on index update
     * and might use old events (max one-scroll-old though).
     *
     * We only use this logic for iOS devices due to their autoplay policy.
     *
     * On non-apple devices, we still use this approach, but we provide a default
     * value for scrollTouchEnds$, so it doesnt require to be clicked to start
     * playing initially.
     */
    if (SystemService.isIOS()) {
      // Old workaround
      this.touchEndEventCurrentClipIndex_Obs$ =
        this.currentClipIndexSubject.pipe(
          withLatestFrom(this.scrollTouchEnds$),
          share()
        )
    } else {
      // provide null start event to emit without needed user interaction
      this.touchEndEventCurrentClipIndex_Obs$ =
        this.currentClipIndexSubject.pipe(
          withLatestFrom(this.scrollTouchEnds$.pipe(startWith(null))),
          share()
        )
    }
  }

  setUpPlayPauseLogic() {
    // pause play obs
    this.pausePlay$ = this.pausePlaySubject.pipe(
      throttleTime(this.throttleTimePausePlay)
    )
  }

  emitPausePlay() {
    this.pausePlaySubject.next()
  }

  emitShownClipIndex(index: number) {
    this.currentClipIndexSubject.next(index)
  }

  emitTouchStartEvent(event: TouchEvent) {
    this.touchStartSubject.next(event)
  }

  emitTouchEndEvent(event: TouchEvent) {
    this.touchEndSubject.next(event)
  }

  emitInitialEvent(event: TouchEvent) {
    // skip the "filtering", emit to important obs right away
    this.touchStartSubject.next(event)
    this.touchEndSubject.next(event)
  }

  ngOnDestroy() {
    this.pausePlaySubject.complete()
  }
}
