import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Database, get, getDatabase, ref } from 'firebase/database'
import { HotToastService } from '@ngneat/hot-toast'
import { StrHlp } from '../StringGetter/getstring.service'
import {
  Auth,
  createUserWithEmailAndPassword,
  getAuth,
  getMultiFactorResolver,
  GoogleAuthProvider,
  onAuthStateChanged,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  User
} from 'firebase/auth'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { SignInComponent } from 'src/app/components/sign-in/sign-in.component'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { LoadingDialogComponent } from 'src/app/components/dialogs/loading-dialog/loading-dialog.component'
import { initializeApp } from 'firebase/app'
import { environment } from 'src/environments/environment'
import { SystemService } from '../system/systemservice.service'
import { Location } from '@angular/common'
import { OnedialogserviceService } from '../dialogs/onedialogservice.service'
import { LocalstorageService } from '../ssr/localstorage.service'

@Injectable({
  providedIn: 'root'
})

/**
 * Simple system:
 * State is observed purely by onAuthStateChanged.
 * However, on app start, the first event that is fired takes ~1sec which makes
 * the home start screen appear the not-logged-in type of way for about one sec. Bad UI.
 * To fix this, whenever an onAuthStateChanged event is fired, it persists the userID to
 * localStorage, or, if not logged in, it sets it to null in localStorage.
 * On website/app start, the init value is read from localStorage, and then, after 1sec
 * updated/verified by onAuthStateChanged.
 *
 * Note: The init via localStorage strategy is only applied to the var "userID", not to the var "user"
 */
export class AuthService {
  private key_uid = 'key_uid'

  db: Database
  auth: Auth

  /**
   * the userID if signed in, null otherwise
   */
  private static userID: string | null = null
  private static user: User | null = null

  private static afterLoadedCallbacks: ((user: User) => void)[] = []
  public static registerAfterLoadedCallback(callback: (user: User) => void) {
    if (AuthService.user !== null) {
      callback(AuthService.user)
    } else {
      AuthService.afterLoadedCallbacks.push(callback)
    }
  }
  private static handleAfterLoadCallbacks() {
    for (let i = 0; i < AuthService.afterLoadedCallbacks.length; i++) {
      AuthService.afterLoadedCallbacks[i](AuthService.user!)
    }

    // empty list
    AuthService.afterLoadedCallbacks.length = 0
  }

  constructor(
    public router: Router,
    private toast: HotToastService,
    public dialog: MatDialog,
    private location: Location,
    private oneButtonDialogService: OnedialogserviceService,
    private localstorageService: LocalstorageService
  ) {
    // Init and reference auth
    const app = initializeApp(environment.firebase)
    this.auth = getAuth(app)
    this.db = getDatabase()

    // set up auth state listener
    onAuthStateChanged(this.auth, (user) => {
      //console.log("------------------------------------------------");
      //console.log("onAuthStateChanged...");

      if (user) {
        // User is signed in
        AuthService.user = user
        AuthService.userID = user.uid

        AuthService.handleAfterLoadCallbacks()
      } else {
        // User is signed out
        AuthService.user = null
        AuthService.userID = null
      }

      // make update
      this.updateLocalStorage()
    })

    // for init purposes, load from local storage
    this.initLoadingFromLocalStorage()
  }

  initLoadingFromLocalStorage() {
    const val = this.localstorageService.getItem(this.key_uid)
    if (val !== null && val !== '') {
      AuthService.userID = val
    }
  }

  updateLocalStorage() {
    const val = AuthService.userID == null ? '' : AuthService.userID
    this.localstorageService.setItem(this.key_uid, val)
  }

  public static getUser(): User | null {
    return AuthService.user
  }

  public static getUID(): string | null {
    return AuthService.userID
  }
  public getUserID() {
    return AuthService.userID
  }

  public static isUserLoggedIn(): boolean {
    return AuthService.userID !== null
  }
  public isLoggedIn(): boolean {
    return AuthService.userID !== null
  }

  public afterLogInSuccess(redirectURL: string = ''): void {
    if (!AuthService.userID) {
      return
    }

    //console.log("------------------------------------------------");
    //console.log("afterLogInSuccess...: userID: " + AuthService.userID);

    /*
     * Important check:
     * Probably the user has an account in Hub-A but not in Hub-B, and this is Hub-B
     * so he would have no profile. Check this and eventually create a profile
     */
    const path = `${StrHlp.CLOUD_PATH}/Users/${AuthService.userID}/username`

    get(ref(this.db, path))
      .then((snapshot) => {
        //console.log("Checking if profile exists...: " + snapshot.exists() + " (userID: " + AuthService.userID + ")");

        if (snapshot.exists()) {
          // user has a profile already, simply continue
          this.navigateAfterSuccess(false, redirectURL)
        } else {
          // no profile yet, call cloud function
          const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
            disableClose: true
          })

          const functions = getFunctions()
          const createAccountLogic = httpsCallable(
            functions,
            'createAccountLogic'
          )

          createAccountLogic({
            hubname: StrHlp.CLOUD_PATH
          })
            .then((result) => {
              loadingDialogRef.close()

              // profile created
              // continue
              this.navigateAfterSuccess(true, redirectURL)
            })
            .catch((error) => {
              loadingDialogRef.close()
              console.log(error)
              this.toast.error('Failed')
            })
        }
      })
      .catch((error) => {
        console.log(error)
        this.toast.error('An error has occurred')
      })
  }

  navigateAfterSuccess(
    openUsernameSettingsAfter: boolean,
    redirectURL: string
  ): void {
    if (redirectURL === '') {
      if (openUsernameSettingsAfter) {
        // set home as last nav without actually navigating there
        this.location.go('home')

        const data = {
          closeAfterChange: true
        }
        if (SystemService.isMobile()) {
          this.router.navigate(['mb-settings/account/username'], {
            state: data
          })
        } else {
          this.router.navigate(['settings/account/username'], { state: data })
        }
      } else {
        // navigate home
        this.router.navigate(['home'])
      }
    } else {
      //this.router.navigateByUrl(redirectURL, { replaceUrl: true });
      this.router.navigate(['home'], { queryParams: { redirect: redirectURL } })
    }
  }

  logInViaMail(
    email: string,
    password: string,
    redirectURL: string = '',
    failcallback: (() => void) | null = null
  ) {
    signInWithEmailAndPassword(this.auth, email, password)
      .then((userCredential) => {
        // Success

        //const user = userCredential.user;
        //console.log("------------------------------------------------");
        //console.log("Login via email worked!");

        this.afterLogInSuccess(redirectURL)
      })
      .catch((error) => {
        if (error.code == 'auth/multi-factor-auth-required') {
          this.handle2FA(error)
        } else {
          console.log(error)

          let errorMessage = 'Unknown error'
          switch (error.code) {
            case 'auth/invalid-email':
              errorMessage = 'The email address is badly formatted.'
              break
            case 'auth/user-disabled':
              errorMessage =
                'The user account has been disabled by an administrator.'
              break
            case 'auth/user-not-found':
              errorMessage =
                'There is no user record corresponding to this email.'
              break
            case 'auth/wrong-password':
              errorMessage = 'The password is invalid for the given email.'
              break
            default:
              errorMessage = error.message
          }
          this.oneButtonDialogService.show('Error occurred', errorMessage)

          if (failcallback != null) {
            failcallback()
          }
        }
      })
  }

  handle2FA(error: any) {
    this.toast.show('Two factor authentication loading...')

    // The user is a multi-factor user. Second factor challenge is required.
    const resolver = getMultiFactorResolver(this.auth, error)

    const phoneInfoOptions = {
      multiFactorHint: resolver.hints[0],
      session: resolver.session
    }

    // Send SMS verification code.
    const phoneAuthProvider = new PhoneAuthProvider(this.auth)

    const recaptchaVerifier = new RecaptchaVerifier(
      this.auth,
      'recaptcha-container-id'
    )

    phoneAuthProvider
      .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
      .then((verificationId) => {
        // verificationId will be needed for sign-in completion.

        // Ask user for the SMS verification code via prompt (yeah, very bad UI)
        const verificationCode = prompt(
          'Enter the verification code we sent to your number. It may take a minute to reach your phone. If you have problems receiving it, make sure that the airplane mode is turned off and your phone has a good connection. You can try to send the code again in 2 minutes.'
        )

        if (verificationCode !== null) {
          const cred = PhoneAuthProvider.credential(
            verificationId,
            verificationCode
          )
          const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)

          // Complete sign-in.
          return resolver.resolveSignIn(multiFactorAssertion)
        } else {
          this.toast.error('Entered wrong code')
          return null
        }
      })
      .then((userCredential) => {
        // User successfully signed in with the second factor phone number.
        this.toast.success('Code is correct. Logged in')
        this.afterLogInSuccess()
      })
      .catch((error) => {
        // failed
        console.log(error)
        this.toast.error(error.message)
      })
  }

  signUp(
    email: string,
    password: string,
    redirectURL: string = '',
    errorCallback: (arg0: string) => void
  ) {
    createUserWithEmailAndPassword(this.auth, email, password)
      .then((result) => {
        this.afterLogInSuccess(redirectURL)
      })
      .catch((error) => {
        let errorMessage = 'Unknown error'
        switch (error.code) {
          case 'auth/email-already-in-use':
            errorMessage =
              'The email address is already in use by another account.'
            break
          case 'auth/invalid-email':
            errorMessage = 'The email address is badly formatted.'
            break
          case 'auth/operation-not-allowed':
            errorMessage = 'Email/password accounts are not enabled.'
            break
          case 'auth/weak-password':
            errorMessage = 'The password is too weak.'
            break
          default:
            errorMessage = error.message
        }
        this.oneButtonDialogService.show('Error occurred', errorMessage)

        errorCallback(error.message)
      })
  }

  sendVerificationMail() {
    sendEmailVerification(AuthService.user!)
      .then(() => {
        this.toast.show('Please check your inbox and verify your email')
      })
      .catch((error) => {
        let errorMessage = 'Unknown error'
        switch (error.code) {
          case 'auth/invalid-user-token':
            errorMessage =
              "The user's credential is no longer valid. The user must sign in again."
            break
          case 'auth/user-token-expired':
            errorMessage =
              "The user's credential has expired. The user must sign in again."
            break
          case 'auth/user-not-found':
            errorMessage =
              'There is no user record corresponding to this identifier. The user may have been deleted.'
            break
          default:
            errorMessage = error.message
        }
        this.oneButtonDialogService.show('Error occurred', errorMessage)

        console.log(error)
      })
  }

  forgotPassword(passwordResetEmail: string) {
    sendPasswordResetEmail(this.auth, passwordResetEmail)
      .then(() => {
        this.toast.success(
          'We sent you an email where you can reset your password.'
        )
      })
      .catch((error) => {
        let errorMessage = 'Unknown error'
        switch (error.code) {
          case 'auth/invalid-email':
            errorMessage = 'The email address is badly formatted.'
            break
          case 'auth/user-not-found':
            errorMessage =
              'There is no user record corresponding to this email.'
            break
          default:
            errorMessage = error.message
        }
        this.oneButtonDialogService.show('Error occurred', errorMessage)

        console.log(error)
      })
  }

  isEmailVerified(): boolean {
    return AuthService.user !== null && AuthService.user.emailVerified
  }

  continueWithGoogle(redirectURL: string = ''): void {
    const provider = new GoogleAuthProvider()

    signInWithPopup(this.auth, provider)
      .then((result) => {
        // Success

        // This gives you a Google Access Token. You can use it to access the Google API.
        //const credential = GoogleAuthProvider.credentialFromResult(result)!;
        //const token = credential.accessToken;

        // The signed-in user info.
        //const user = result.user;

        //console.log("------------------------------------------------");
        //console.log("Google login success");

        // continue
        this.afterLogInSuccess(redirectURL)
      })
      .catch((error) => {
        if (error.code == 'auth/multi-factor-auth-required') {
          this.handle2FA(error)
        } else {
          // Handle Errors here.
          const errorCode = error.code

          // The email of the user's account used.
          //const email = error.customData.email;
          // The AuthCredential type that was used.
          //const credential = GoogleAuthProvider.credentialFromError(error);

          let errorMessage = 'Unknown error'
          switch (error.code) {
            case 'auth/account-exists-with-different-credential':
              errorMessage =
                'An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.'
              break
            case 'auth/auth-domain-config-required':
              errorMessage = 'An auth domain configuration is required.'
              break
            case 'auth/cancelled-popup-request':
              errorMessage =
                'This operation has been cancelled due to another conflicting popup being opened.'
              break
            case 'auth/operation-not-allowed':
              errorMessage =
                'The given sign-in provider is disabled for this Firebase project. Enable it in the Firebase console, under the sign-in method tab of the Auth section.'
              break
            case 'auth/operation-not-supported-in-this-environment':
              errorMessage =
                'This operation is not supported in the environment this application is running on. "location.protocol" must be http or https.'
              break
            case 'auth/popup-blocked':
              errorMessage =
                'Unable to establish a connection with the popup. It may have been blocked by the browser.'
              break
            case 'auth/popup-closed-by-user':
              errorMessage =
                'The popup has been closed by the user before finalizing the operation.'
              break
            case 'auth/unauthorized-domain':
              errorMessage =
                'The domain of this operation is not authorized. White-list it in the Firebase console, under the sign-in method tab of the Auth section.'
              break
            default:
              errorMessage = error.message
          }
          this.oneButtonDialogService.show('Error occurred', errorMessage)

          console.log(
            `Google login failed: errorCode=${errorCode}, errorMessage=${errorMessage}`
          )
        }
      })
  }

  signOut() {
    this.auth
      .signOut()
      .then(() => {
        // nothing, handled in auth state listener
        this.router.navigate(['home'])
      })
      .catch((error) => {
        this.toast.error('Error occurred')
      })
  }

  showLoginDialog(redirectURL = '') {
    const dialogConfig = new MatDialogConfig()

    dialogConfig.autoFocus = false
    dialogConfig.maxWidth = '100vw'
    dialogConfig.panelClass = 'bottom-sheet-dialog'

    if (SystemService.isMobile()) {
      // on mobile always take up whole width.
      dialogConfig.width = '100vw'
    }

    dialogConfig.data = {
      redirectURL: redirectURL
    }

    this.dialog.open(SignInComponent, dialogConfig)
  }
}
