<div class="fh-page-wrapper">
  <header class="fh-header header bottom-border-divider">
    <app-backbuttontemplate></app-backbuttontemplate>
    <h1 class="title">Last seen settings</h1>
  </header>

  <main class="fh-main content">
    <p>
      You have the option to decide whether you want your profile to display the
      last time you were online or not. However, other users can still see
      whether you are online or offline.
    </p>

    <div class="below-text-wrapper">
      <div *ngIf="showLastSeen_Det">
        <input
          name="radio"
          type="checkbox"
          (change)="update()"
          [(ngModel)]="showLastSeen"
          name="action"
        />

        <span class="radio-text">Show last seen</span>
      </div>

      <div *ngIf="!showLastSeen_Det">
        <span class="loading-text">Loading...</span>
      </div>
    </div>
  </main>
</div>
