<div class="fh-page-wrapper">
  <header class="fh-header header bottom-border-divider">
    <app-backbuttontemplate></app-backbuttontemplate>

    <h1 class="title" (click)="testCode($event)">Manage account</h1>
  </header>

  <main class="fh-main content">
    <h2 class="sub-title">Account information</h2>

    <hr class="divider-no-vertical-margin" />
    <div class="account-info-wrapper">
      <div class="account-info-entry">
        <img class="dark-icon settings-img" src="/assets/abc.png" alt="text" />
        <div class="account-info-text-wrapper">
          <div class="account-info-text">
            {{ yourUsername$ | async }}
          </div>
          <div class="account-info-text-hint">Username</div>
        </div>
      </div>

      <hr class="inline-divider" />

      <div class="account-info-entry">
        <img
          class="dark-icon settings-img"
          src="/assets/ic_email3.png"
          alt="email"
        />
        <div class="account-info-text-wrapper">
          <div class="account-info-text">
            {{ yourEmail }}
          </div>
          <div class="account-info-text-hint">Email</div>
        </div>
      </div>

      <hr class="inline-divider" />

      <div class="account-info-entry">
        <img
          class="dark-icon settings-img"
          src="/assets/ic_set_avatar.png"
          alt="avatar"
        />
        <div class="account-info-text-wrapper">
          <div class="account-info-text">
            {{ createdProfileString }}
          </div>
          <div class="account-info-text-hint">Created profile</div>
        </div>
      </div>
    </div>
    <hr class="divider-no-vertical-margin" />

    <h2 class="sub-title">Security</h2>
    <div class="settings-item" routerLink="security">
      <span class="settings-img-wrap settings-gray">
        <img
          class="light-icon settings-img"
          src="/assets/ic_alert7.png"
          alt="warning"
        />
      </span>
      <span class="settings-text"> Keep account secure </span>
    </div>
    <h2 class="sub-title">General</h2>

    <div class="settings-item" routerLink="username">
      <span class="settings-img-wrap settings-cyan2">
        <img
          class="light-icon settings-img"
          src="/assets/text.png"
          alt="text"
        />
      </span>
      <span class="settings-text"> Username </span>
    </div>

    <div class="settings-item" routerLink="email">
      <span class="settings-img-wrap settings-green1">
        <img
          class="light-icon settings-img"
          src="/assets/ic_email3.png"
          alt="email"
        />
      </span>
      <span class="settings-text"> Email </span>
    </div>

    <div class="settings-item" routerLink="password">
      <span class="settings-img-wrap settings-green2">
        <img
          class="light-icon settings-img"
          src="/assets/ic_set_password2.png"
          alt="password"
        />
      </span>
      <span class="settings-text"> Password </span>
    </div>

    <h2 class="sub-title">App version</h2>

    <button
      class="subtle-button width-100 settings-item"
      (click)="copyAppVersion()"
      aria-label="App version"
    >
      <span class="settings-img-wrap">
        <img
          class="settings-img-keep-original"
          alt="app logo"
          src="/assets/favico.png"
        />
      </span>

      <span class="settings-text">
        {{ versionString }}
      </span>
    </button>

    <h2 class="sub-title">Delete</h2>

    <div class="settings-item" routerLink="deleteaccount">
      <span class="settings-img-wrap settings-black">
        <img
          class="light-icon settings-img"
          src="/assets/ic_set_delete.png"
          alt="delete"
        />
      </span>
      <span class="settings-text"> Delete Account </span>
    </div>
  </main>
</div>
