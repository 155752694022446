<div class="fh-page-wrapper">
  <header class="fh-header bottom-border-divider" id="header">
    <app-backbuttontemplate></app-backbuttontemplate>

    <p class="action-button action-button-active" (click)="saveInput()">
      Save changes
    </p>
  </header>

  <main class="fh-main content" id="container">
    <h1>Profile image</h1>

    <app-imageuploadtemplate
      [mediaChangedCallback]="imageUploadedCallback"
      [initImageURL]="profileImage$ | async"
      [treatGifsLikeVideos]="false"
    >
    </app-imageuploadtemplate>

    <p id="nsfw-not-allowed-hint" *ngIf="strHlp.getAllowsNSFW()">
      Nudity not allowed here
    </p>

    <div
      class="photoActionButton"
      (click)="deleteProfileImage()"
      *ngIf="user && user.profilePhoto && user.profilePhoto.length > 0"
    >
      <img
        src="/assets/ic_set_delete.png"
        class="dark-icon photoActionIcon"
        alt="delete"
      />
      <p class="photoActionText">Delete photo</p>
    </div>

    <hr class="inline-divider2" />

    <div class="sub-title-wrapper">
      <h1>Full name</h1>

      <img
        id="emojiButton"
        class="dark-icon"
        (click)="emojiButtonClick_FullName()"
        src="{{
          showEmojiPicker && !emojiPickerOnBio
            ? '/assets/ic_keyboard.png'
            : '/assets/ic_emoji_button.png'
        }}"
        alt="emoji picker"
      />
    </div>

    <textarea
      class="inputArea"
      placeholder="What is your name?"
      maxlength="40"
      onkeydown="return /^[^\[\]{}@§%#<>]+$/g.test(event.key)"
      [(ngModel)]="fullnameEntered"
    >
    </textarea>
    <p #fullnameError class="fullname-error">Cannot be empty</p>

    <hr class="inline-divider2" />

    <div class="sub-title-wrapper">
      <h1>Bio</h1>

      <img
        id="emojiButton"
        class="dark-icon"
        (click)="emojiButtonClick_Bio()"
        src="{{
          showEmojiPicker && emojiPickerOnBio
            ? '/assets/ic_keyboard.png'
            : '/assets/ic_emoji_button.png'
        }}"
        alt="emoji picker"
      />
    </div>

    <textarea
      class="inputArea"
      placeholder="Add a bio to your profile..."
      maxlength="2000"
      [(ngModel)]="bioEntered"
    >
    </textarea>

    <hr class="inline-divider2" />

    <h1>Change username</h1>

    <button
      class="subtle-button action-button username-button"
      routerLink="../account/username"
    >
      Username settings
    </button>

    <hr class="inline-divider2" />

    <h1>Private profile</h1>
    <p class="">
      With a private profile only people who follow you can see your posts and
      your profile. You need to approve follower requests manually.
    </p>
    <div class="checkbox-wrapper">
      <label class="switch">
        <input
          type="checkbox"
          #privateProfileCheck
          (click)="onPrivateProfileCheckClick()"
        />
        <span class="slider round"></span>
      </label>
      <span class="checkbox-desc">Private profile</span>
    </div>

    <hr class="inline-divider2" />
  </main>
</div>
