<a class="main-branding-wrapper" routerLink="/home/auto">
  <img
    class="main-branding-logo"
    src="/assets/applogo_transparent.png"
    alt="{{ name }} logo"
  />

  <h1 id="branding-app-title">
    {{ name }}
  </h1>
</a>
