<div class="fh-page-wrapper">
  <header class="fh-header header bottom-border-divider">
    <app-backbuttontemplate></app-backbuttontemplate>
    <h1 class="title">NSFW settings</h1>
  </header>

  <main class="fh-main content">
    <h2 class="sub-title" *ngIf="!settingsActivated">Activate NSFW</h2>

    <div *ngIf="!settingsActivated">
      <p>Use this button to activate NSFW Settings in your app.</p>

      <div class="button-container">
        <div class="cancel-button" (click)="cancelClick()">Cancel</div>

        <div class="activate-nsfw-button" (click)="onActivateClick()">
          Activate NSFW
        </div>
      </div>
    </div>

    <div *ngIf="settingsActivated">
      <p>Already activated NSFW Settings in your app.</p>

      <div class="cancel-button" (click)="cancelClick()">Home</div>
    </div>
  </main>
</div>
