<!--

    Where we (usually) make style changes:
    - Normal
    - <= 1000px width
    - <= 600px width

-->

<div
  [ngClass]="{
    'cookie-banner': !cookieBannerFullHeight,
    'cookie-banner-full-height': cookieBannerFullHeight
  }"
  #cookiebanner
  *ngIf="isEU && !agreedToCookies && agreedToCookies_Loaded"
>
  <div class="cookie-header">
    <div class="cookie-title">Cookie settings</div>
    <span class="reject-button" (click)="rejectCookies()"> Reject all </span>
  </div>
  <div class="cookie-desc">
    We use cookies and similar technologies on our website and process your
    personal data (e.g. IP address), for example, to personalize content and
    ads, to integrate media from third-party providers or to analyze traffic on
    our website. Data processing may also happen as a result of cookies being
    set. We share this data with third parties that we name in the privacy
    settings. See our
    <span class="privacy-link" routerLink="/privacy">Privacy Policy</span> for
    details.
  </div>
  <div class="consent-wrapper">
    <span class="consent-button" (click)="agreeToCookies()">
      Only allow essential cookies
    </span>
  </div>
</div>

<div
  class="internet-connection-banner user-back-online"
  *ngIf="showBackOnlineBanner"
>
  Back online
</div>
<div class="internet-connection-banner user-offline" *ngIf="showOfflineBanner">
  No internet connection
</div>

<!-- App content -->
<router-outlet />

<!-- Overlay for heart animations etc. -->
<div class="app-overlay-wrapper" #appOverlayWrapper></div>
