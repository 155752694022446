<main #contentWrapper class="main">
  <div id="listWrapper">
    <div class="item" *ngFor="let item of items; let i = index">
      <div class="itemStartWrapper">
        <a
          class="subtle-button"
          role="link"
          aria-label="Open user profile"
          href="/user/{{ item.userID }}"
          routerLink="/user/{{ item.userID }}"
          mat-dialog-close
        >
          <img
            [src]="item.image$ | async | imgScaleHelper: 50"
            alt="user profile photo"
            onerror="this.src='/assets/default_profile_pic.jpg';this.onerror='';"
            class="userImage"
          />
        </a>

        <div class="username-container">
          <app-usernametemplate
            mat-dialog-close
            [values]="{
              userID: item.userID
            }"
          >
          </app-usernametemplate>
        </div>
      </div>

      <div class="buttons-at-end-wrapper">
        <span class="removeButton" (click)="denyRequest(i)"> Deny </span>

        <span class="removeButton" (click)="allowRequest(i)"> Accept </span>
      </div>
    </div>
  </div>

  <app-loadingcircle *ngIf="loadingInProgress"> </app-loadingcircle>

  <app-emptyhinttemplate *ngIf="empty"> </app-emptyhinttemplate>
</main>
