import { Component, Input } from '@angular/core'
import { ChoosingBarTextItemComponent } from '../../feed/choosing-bar-text-item/choosing-bar-text-item.component'
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { AuthService } from 'src/app/shared/services/auth/auth.service'

@Component({
  selector: 'app-chat-choosing-bar',
  standalone: true,
  imports: [
    ChoosingBarTextItemComponent,
    RouterModule,
    CommonModule,
    ChoosingBarTextItemComponent
  ],
  templateUrl: './chat-choosing-bar.component.html',
  styleUrls: [
    './chat-choosing-bar.component.css',
    '../../../stylesheets/feed-choosing-bar.css'
  ]
})
export class ChatChoosingBarComponent {
  @Input({ required: true })
  selectedMode!: number

  @Input({ required: true })
  onSelectChatsMode!: () => void

  @Input({ required: true })
  onSelectGroupsMode!: () => void

  isLoggedIn = false

  constructor(private authService: AuthService) {
    this.isLoggedIn = this.authService.isLoggedIn()
  }
}
