<div class="container" appBottomSheetDialogContainer>
  <div id="header">
    <p class="title">Share this post</p>

    <img
      class="dark-icon"
      id="cancel-button"
      src="/assets/ic_close_2.png"
      alt="close"
      mat-dialog-close
    />
  </div>

  <div class="copy-link-wrapper" mat-dialog-close (click)="copyLink()">
    Copy link
  </div>

  <div id="buttons-wrapper">
    <div
      class="action-wrapper"
      mat-dialog-close
      (click)="forwardToChat()"
      *ngIf="authService.isLoggedIn()"
    >
      <div class="image-wrapper lila">
        <img
          src="/assets/navbar_chat_selected_black.png"
          class="action-image"
          alt="forward"
        />
      </div>

      Chat
    </div>

    <div
      class="action-wrapper"
      mat-dialog-close
      (click)="repost()"
      *ngIf="authService.isLoggedIn()"
    >
      <div class="image-wrapper darkred">
        <img src="/assets/repost.png" class="action-image" alt="repost" />
      </div>

      Repost
    </div>

    <div class="action-wrapper" mat-dialog-close (click)="shareToOtherApps()">
      <div class="image-wrapper lila-2">
        <img
          src="/assets/menu_waagerecht.png"
          class="action-image"
          alt="send icon"
        />
      </div>

      Other
    </div>
  </div>

  <section class="start-at-wrapper" *ngIf="post.vid">
    <hr class="inline-divider" />

    <form [formGroup]="startAtForm" class="start-at-form">
      <input
        type="checkbox"
        [formControl]="startAtCheckbox"
        class="start-at-checkbox"
        id="start-at-check"
      />

      <label for="start-at-check">Start at</label>

      <input
        matInput
        [formControl]="startAtInput"
        type="number"
        class="start-at-input"
        [class.disabled-form-part]="!startAtCheckbox.value"
      />

      <span [class.disabled-form-part]="!startAtCheckbox.value"> seconds </span>
    </form>
  </section>
</div>
