<div id="wrapper">
  <h1>Version History Web App</h1>
  <p class="normal-text">
    This page displays the version history of the {{ strHlp.getAppName() }} Web,
    starting from June 2023. Please note that this history is specific to the
    website version of the app and may not be complete. In the past, there have
    been a total of {{ strHlp.getVersionCode() }} updates.
  </p>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>5.3.0</h2>
    <p>Released on: 15/08/2024</p>
    <ul>
      <li>Added right-click for chat messages on desktop</li>
      <li>Fixed fullscreen image swipe-gestures</li>
      <li>SSR added to group pages</li>
      <li>Bugs fixed</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>5.2.0</h2>
    <p>Released on: 12/08/2024</p>
    <ul>
      <li>Reduced single-tap time threshold</li>
      <li>Explore page fixed</li>
      <li>Bugs fixed</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>5.1.0</h2>
    <p>Released on: 16/06/2024</p>
    <ul>
      <li>Further internal changes regarding SSR</li>
      <li>Major bugs fixed</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>5.0.0 Beta</h2>
    <p>Released on: 14/04/2024</p>
    <ul>
      <p>
        The biggest changes of this update are internally. You, as our user,
        will still notice many changes in layout, design, performance etc. - but
        there were some fundmental changes under the hood. They will bring us
        higher positions on Google & co and therefore more users soon!
      </p>
      <br />
      <br />
      <li>
        We use SSR (Server-Side Rendering) now. What that means is, more happens
        on the server. Google, Bing & co like that and will show our site more.
        Simply put: More users soon!
      </li>
      <li>Big layout changes and fixes</li>
      <li>Many animations added</li>
      <li>Bugs fixed and performance improved</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>4.4.1</h2>
    <p>Released on: 23/03/2024</p>
    <ul>
      <li>Layout improvements</li>
      <li>Reduced bundle size</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>4.4.0</h2>
    <p>Released on: 11/01/2024</p>
    <ul>
      <li>Chat page massively improved</li>
      <li>Added custom page for every group</li>
      <li>Most parts of the app became accessible without having an account</li>
      <li>App completely restructed for users that are not logged in</li>
      <li>Huge SEO improvements</li>
      <li>Many bug fixes</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>4.3.2</h2>
    <p>Released on: 06/01/2024</p>
    <ul>
      <li>Big SEO improvements</li>
      <li>Search without entering a search term!</li>
      <li>Many bug fixes</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>4.3.1</h2>
    <p>Released on: 03/01/2024</p>
    <ul>
      <li>Bug fixes</li>
      <li>Performance improved</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>4.3.0</h2>
    <p>Released on: 01/12/2023</p>
    <ul>
      <li>Clips auto play for iOS</li>
      <li>New like animation on posts</li>
      <li>New animations for chat message reactions</li>
      <li>Big performance improvements in both chat and feed</li>
      <li>Emoji and gif keyboard heavily improved</li>
      <li>UI improvements</li>
      <li>More...</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>4.2.2</h2>
    <p>Released on: 20/11/2023</p>
    <ul>
      <li>Major UI improvements, in particular regarding dialogs</li>
      <li>Bug fixes</li>
      <li>More...</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>4.2.1</h2>
    <p>Released on: 26/10/2023</p>
    <ul>
      <li>Improvements to clips</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>4.2.0</h2>
    <p>Released on: 26/10/2023</p>
    <ul>
      <li>Big improvements to clips</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>4.1.0</h2>
    <p>Released on: 23/10/2023</p>
    <ul>
      <li>Big improvements to clips</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>4.0.2</h2>
    <p>Released on: 18/10/2023</p>
    <ul>
      <li>You can now download videos</li>
      <li>Clips improved</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>4.0.1</h2>
    <p>Released on: 14/10/2023</p>
    <ul>
      <li>Bug fixes and improvements</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>v4.0.0</h2>
    <p>Released on: 30/09/2023</p>
    <ul>
      We are glad to introduce
      {{
        strHlp.getAppName()
      }}
      4.0 to you! Swipe endlessly through
      {{
        strHlp.getCommunityName()
      }}
      videos.
      <br />
      <br />
      <li>
        {{ strHlp.getAppName() }} Clips. You may call it
        {{ strHlp.getCommunityName() }}-TikTok!
      </li>
      <li>
        All videos now open in Clips, so you can swipe endlessly to see more
        videos!
      </li>
      <li>We re-organized the main page and the feed for Clips.</li>
      <li>Minor UI improvements</li>
      <li>Many bug fixes</li>
      <li>More...</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>v3.5.0</h2>
    <p>Released on: 28/09/2023</p>
    <ul>
      <li>Pull to refresh added to profiles and navigation sites</li>
      <li>Fullscreen image site improved</li>
      <li>
        Main navigation sites have scroll-to-top shortcuts now (click on the
        title to scroll to the top)
      </li>
      <li>Major bugs fixed</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>v3.4.7</h2>
    <p>Released on: 27/09/2023</p>
    <ul>
      <li>Major UI improvement in the video player</li>
      <li>Scrolling position is kept and reused for main navigation pages</li>
      <li>Statistics updated</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>v3.4.6</h2>
    <p>Released on: 27/09/2023</p>
    <ul>
      <li>Videos in feed open in fullscreen video player now</li>
      <li>
        Fullscreen image view improved heavily (pan added, text added, design
        improved, swipe issue fixed and more)
      </li>
      <li>More...</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>v3.4.5</h2>
    <p>Released on: 27/09/2023</p>
    <ul>
      <li>Trending searches added to the explore page!</li>
      <li>Recent searches queries are shown now</li>
      <li>Typing hint fixed in chats</li>
      <li>Inbox: "Older" replaced by actual dates</li>
      <li>Other bug fixes</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>v3.4.4</h2>
    <p>Released on: 25/09/2023</p>
    <ul>
      <li>Image collages improved (navigation buttons on Desktop devices)</li>
      <li>Major bugs fixed related to search</li>
      <li>Following feed: Major performance improvement</li>
      <li>Single-post-page: Comment section improved</li>
      <li>More...</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>v3.4.3</h2>
    <p>Released on: 24/09/2023</p>
    <ul>
      <li>Choose your starting page (home is default)</li>
      <li>Better links for sharing posts</li>
      <li>Profile URLs changed to: .../&#64;username</li>
      <li>Hashtag design issue fixed</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>v3.4.2</h2>
    <p>Released on: 19/09/2023</p>
    <ul>
      <li>
        Virtual scrolling bug fixed (the feed was "jumping" while scrolling)
      </li>
      <li>Many other bug fixes</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>v3.4.1</h2>
    <p>Released on: 09/09/2023</p>
    <ul>
      <li>
        Click on your selected images when making a post to see them in
        fullscreen
      </li>
      <li>Same in chat</li>
      <li>Reply-click fixed</li>
      <li>Reposting bug fixed</li>
      <li>AM/PM chat bug fixed</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>v3.4.0</h2>
    <p>Released on: 09/09/2023</p>
    <ul>
      <li><b>Major</b> performance improvements</li>
      <li *ngIf="strHlp.getAllowsNSFW()">
        Mark your posts as NSFW even after posting
      </li>
      <li>Following feed bug fixed</li>
      <li>More...</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>v3.3.0</h2>
    <p>Released on: 02/09/2023</p>
    <ul>
      <li>Posts now support multiple images!</li>
      <li>Add up to 5 images to your post</li>
      <li>More...</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>v3.2.0</h2>
    <p>Released on: 28/08/2023</p>
    <ul>
      <li>Search for posts</li>
      <li>Search for hashtags</li>
      <li>Search for locations</li>
      <li>
        Sort and filter your search results! For example: Sort by most liked
        posts with images. Or sort by most recent and only videos from this
        month.
      </li>
      <li>More...</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>v3.0.0</h2>
    <p>Released on: 26/08/2023</p>
    <ul>
      <p>
        We are excited to announce the return of videos in
        {{ strHlp.getAppName() }} 3.0! Not only can you watch and post videos
        again, but they are now of higher quality and faster than ever before.
        Additionally, there are other major improvements.
      </p>
      <br />
      <li>Post and watch videos</li>
      <li>
        Video player has playback speed, fullscreen, resolution, pic in pic,
        forward/jump back, and more controls
      </li>
      <li>AirPlay support to stream videos to your TV</li>
      <li>Post GIFs from your gallery</li>
      <li>Post sharing improved</li>
      <li>Zoom on posts and fullscreen images enabled</li>
      <li>More...</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>v2.3.2</h2>
    <p>Released on: 16/08/2023</p>
    <ul>
      <li>Premium, donations and active subscriptions added</li>
      <li>Improved chat messages interaction (swipe)</li>
      <li>Cumulative layout shift fixed in chats</li>
      <li>Bottom navigation bar: Icons bigger</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>v2.3.1</h2>
    <p>Released on: 16/08/2023</p>
    <ul>
      <li>Major internal improvements, especially regarding SEO</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>v2.3.1</h2>
    <p>Released on: 15/08/2023</p>
    <ul>
      <li>Full support for group chats</li>
      <li>
        Use a GIF as your profile image! It will be shown animated when users
        open your profile, and it will show a "frozen" frame of the GIF
        otherwise, for example in chat messages.
      </li>
      <li>More...</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>v2.2.3</h2>
    <p>Released on: 11/08/2023</p>
    <ul>
      <li>Add GIFs from other people to your favorites</li>
      <li>Group chats support extended</li>
      <li>
        Text formatting added to posts, messages, comments and bios: Style your
        text bold, italic, etc.
      </li>
      <li>Last-seen and online/offline status: major bug fixed</li>
      <li>
        Last-seen and online/offline status update automatically - without
        refreshing the site
      </li>
      <li>
        Use swipe gestures to navigate the GIF keyboard, change between "Chats"
        and "Groups" and close fullscreen images.
      </li>
      <li>Swipe-to-reply bug fixed for android</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>v2.2.2</h2>
    <p>Released on: 11/08/2023</p>
    <ul>
      <li>
        Full support for push notifications, including settings, muting chats
        and more
      </li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>v2.2.1</h2>
    <p>Released on: 09/08/2023</p>
    <ul>
      <li>Support for push notifications added</li>
      <li>GIF keyboard: Added recent-tab and favorite functionality</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>v2.2.0</h2>
    <p>Released on: 08/08/2023</p>
    <ul>
      <li>Added support for group chats</li>
      <li>GIFs enabled for comments</li>
      <li>Load-more button added for GIFs</li>
      <li>Bug fixes</li>
      <li>UI fixes</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>v2.1.0</h2>
    <p>Released on: 06/08/2023</p>
    <ul>
      <li>GIF support in chats added</li>
      <li>Search bar improved</li>
      <li>Bugs fixed</li>
      <li>Swipe down to close a fullscreen image</li>
      <li>More...</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>v2.0.3</h2>
    <p>Released on: 06/08/2023</p>
    <ul>
      <li>Pin chats</li>
      <li>Search for chats</li>
      <li>"Visited profile" added</li>
      <li>Bookmark and hashtag page: Bug fixed</li>
      <li>Scrolling state handling improved</li>
      <li>Responsive design: bug fixed</li>
      <li>Settings UI improved</li>
      <li>Account security page added</li>
      <li>Manage account page extended</li>
      <li>Legal pages UI heavily improved</li>
      <li>More...</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>v2.0.2</h2>
    <p>Released on: 05/08/2023</p>
    <ul>
      <li>Major chat bug fixed</li>
      <li>Chat UI improved</li>
      <li>Chat: Date indicator at top added</li>
      <li>Chat: Date dividers between messages added</li>
      <li>Better error handling for login and sign up</li>
      <li>Link detection improved</li>
      <li>Numerous other fixes and improvements</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>v2.0.1</h2>
    <p>Released on: 03/08/2023</p>
    <ul>
      <li>Typos fixed</li>
      <li>Minor UI improvements</li>
      <li>Error handling improved for profiles</li>
      <li>See more text bug fixed</li>
      <li>PWA installation page improved</li>
      <li>Sign up process improved</li>
      <li>Username settings UI improved</li>
      <li>Explore UI improved</li>
      <li>Post UI improved</li>
      <li>App installation issue fixed</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>v2.0.0</h2>
    <p>Released on: 01/08/2023</p>
    <ul>
      <p>
        We are glad to introduce {{ strHlp.getAppName() }} 2.0 to you! We have
        transformed the website into a Web App. You can now install
        {{ strHlp.getAppName() }} on your phone and use it just like a native
        app. Click <a routerLink="/settings/webapp">here</a> to see how.
        Additionally, there are numerous other significant improvements.
      </p>
      <br />
      <li>Dark mode added</li>
      <li>PWA functionality added (Progressive Web App)</li>
      <li>Post UI heavily improved</li>
      <li>Navigation pages stay loaded (home, explore, chats and inbox)</li>
      <li>Chat UI heavily improved</li>
      <li>Fullscreen-image page improved</li>
      <li>Mute users bug fixed</li>
      <li>New-notifications-summary added to mobile devices</li>
      <li>Username and verified UI improved</li>
      <li>Switched back to blue verified badge</li>
      <li>Font issue fixed for iOS devices</li>
      <li>Haptic feedback added (vibrations)</li>
      <li>Long tapping for button interaction added</li>
      <li>Cookie settings improved</li>
      <li>Many pages are fullscreen now for mobile devices (e.g. profiles)</li>
      <li>Main and inbox page rearranged for mobile devices</li>
      <li>Inbox page heavily improved</li>
      <li>Scrolling to top feature added for navigation bar</li>
      <li>Search has moved to the explore page on mobile devices</li>
      <li>UI of all dialogs heavily improved</li>
      <li>Comments UI heavily improved</li>
      <li>See-more-button bug fixed</li>
      <li>See-more added for long chat messages</li>
      <li>Empty feed issue fixed</li>
      <li>Not published messages and posts are saved as drafts now</li>
      <li>App wide caching system introduced</li>
      <li>Log in and sign up page heavily improved</li>
      <li>Major internal system improvements</li>
      <li>Many bugs were fixed</li>
      <li>More...</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>v1.10.2</h2>
    <p>Released on: 21/07/2023</p>
    <ul>
      <li>Major UI improvements</li>
      <li>"3D-effect" for images</li>
      <li>Image loading improved with thumbnail</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>v1.10.1</h2>
    <p>Released on: 20/07/2023</p>
    <ul>
      <li>Major UI improvements</li>
      <li>Messages and posts are drafted if not sent / posted</li>
      <li>
        Feed and notification data are saved regardless of website navigation
      </li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version">
    <h2>v1.9.7</h2>
    <p>Released on: 17/07/2023</p>
    <ul>
      <li>Emoji keyboard heavily improved</li>
      <li>Chat UI improved</li>
      <li>General UI improvements</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version">
    <h2>v1.9.6</h2>
    <p>Released on: 13/07/2023</p>
    <ul>
      <li>Muting feature added: Mute users from your feed and chat groups</li>
      <li>Statistics page added</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version">
    <h2>v1.9.5</h2>
    <p>Released on: 13/07/2023</p>
    <ul>
      <li>Message reaction UI improved</li>
      <li>Deleted message UI improved</li>
      <li>Only-emoji-messages issue fixed</li>
      <li>Chat background issue fixed</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version">
    <h2>v1.9.4</h2>
    <p>Released on: 05/07/2023</p>
    <ul>
      <li>Message UI improved</li>
      <li>Emoji keyboard closes after sending a message</li>
      <li>Username, hashtag and link detection fixed</li>
      <li>General UI improvements</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version">
    <h2>v1.9.3</h2>
    <p>Released on: 28/06/2023</p>
    <ul>
      <li>Scrolling bug fixed</li>
      <li>Post UI improved</li>
      <li>Inbox UI improved</li>
      <li>Discover UI improved</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version">
    <h2>v1.9.1</h2>
    <p>Released on: 26/06/2023</p>
    <ul>
      <li>Navigation bar moved to the bottom for mobile devices</li>
      <li>Chat UI improved</li>
      <li>Inbox UI improved</li>
      <li>Big mobile UI improvements</li>
      <li>Comments UI improvements</li>
      <li>Comment issues fixed</li>
      <li>More...</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version">
    <h2>v1.8.9</h2>
    <p>Released on: 25/06/2023</p>
    <ul>
      <li>Double click issues fixed for iOS</li>
      <li>Forwarding message bug fixed</li>
      <li>Loading cycle UI improved</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version">
    <h2>v1.8.8</h2>
    <p>Released on: 18/06/2023</p>
    <ul>
      <li>About page extended</li>
      <li>Forward messages in chat</li>
      <li>Sharing menu of posts extended</li>
      <li>Make reposts</li>
      <li>Scrolling to replied-message issue fixed</li>
      <li>More...</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version">
    <h2>v1.8.7</h2>
    <p>Released on: 17/06/2023</p>
    <ul>
      <li>About page added</li>
      <li>Dialog opening animation issue fixed</li>
      <li>Settings UI improved</li>
      <li>Notification information UI improved</li>
      <li>Chat message spacing issue fixed</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version">
    <h2>v1.8.6</h2>
    <p>Released on: 17/06/2023</p>
    <ul>
      <li>Chats: Toolbar issue fixed for mobile devices</li>
      <li>Detailed information about new notifications added</li>
      <li>More...</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version">
    <h2>v1.8.5</h2>
    <p>Released on: 16/06/2023</p>
    <ul>
      <li>Chats: Loading new messages after scrolling improved</li>
      <li>Posting UI improved</li>
      <li>Download button for images in chats added</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version">
    <h2>v1.8.4</h2>
    <p>Released on: 14/06/2023</p>
    <ul>
      <li>Chat UI improved</li>
      <li>Comprehensive UI improvements for mobile ends</li>
      <li>Version history page added</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version">
    <h2>v1.8.3</h2>
    <p>Released on: 11/06/2023</p>
    <ul>
      <li>Download button added for images</li>
      <li>Fullscreen-image-UI improved</li>
      <li>Moved online and last-seen status in profiles</li>
      <li>Navigation issues fixed</li>
      <li>Bookmarking issue fixed</li>
      <li>Posting UI improved</li>
      <li>Added a copy button to the post options</li>
      <li>URL issue fixed</li>
      <li>Login UI improved</li>
      <li>Chats reloading issue fixed</li>
      <li>More...</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version normal-text">
    <h2>No information for older updates.</h2>
  </div>
</div>
