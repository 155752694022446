import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { RouterModule } from '@angular/router'
import { SpaceyIfDirective } from 'src/app/directives/spacey-if.directive'

@Component({
  selector: 'app-choosing-bar-text-item',
  standalone: true,
  imports: [RouterModule, CommonModule, SpaceyIfDirective],
  templateUrl: './choosing-bar-text-item.component.html',
  styleUrl: './choosing-bar-text-item.component.css'
})
export class ChoosingBarTextItemComponent {
  @Input({ required: true })
  isSelected!: boolean

  @Input({ required: true })
  text!: string

  @Input()
  link?: string

  @Input({ required: true })
  ariaLabel!: string

  @Input()
  handleClickInput?: () => void

  handleClick() {
    if (this.handleClickInput) {
      this.handleClickInput()
    }
  }
}
