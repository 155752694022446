import { CUSTOM_ELEMENTS_SCHEMA, Injectable, NgModule } from '@angular/core'
import { provideHttpClient, withFetch } from '@angular/common/http'

import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { CommonModule } from '@angular/common'
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog'

import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics'
import { AngularFireModule } from '@angular/fire/compat'
import { AngularFireAuthModule } from '@angular/fire/compat/auth'
import { AngularFireStorageModule } from '@angular/fire/compat/storage'
import { AngularFirestoreModule } from '@angular/fire/compat/firestore'
import { AngularFireDatabaseModule } from '@angular/fire/compat/database'

import {
  CdkScrollable,
  CdkScrollableModule,
  ScrollingModule
} from '@angular/cdk/scrolling'
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog'
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu'

import {
  BrowserAnimationsModule,
  NoopAnimationsModule
} from '@angular/platform-browser/animations'

import {
  BrowserModule,
  HammerModule,
  provideClientHydration
} from '@angular/platform-browser'

import { environment } from '../environments/environment'
import { AppComponent } from './app.component'
import { FeedComponent } from './components/feed/feed.component'
import { MainComponent } from './components/main/main.component'
import { NotfoundComponent } from './components/notfound/notfound.component'
import { SignInComponent } from './components/sign-in/sign-in.component'
import { SignUpComponent } from './components/sign-up/sign-up.component'

import { RouterModule, Routes } from '@angular/router'

import { EmailComponent } from './components/email/email.component'
import { ImprintComponent } from './components/imprint/imprint.component'
import { PrivacyComponent } from './components/privacy/privacy.component'
import { SettingsComponent } from './components/settings/settingspage/settings.component'
import { TermsComponent } from './components/terms/terms.component'
import { VersionhistoryComponent } from './components/versionhistory/versionhistory.component'

//import { HotToastModule } from "@ngneat/hot-toast";

import { PickerModule } from '@ctrl/ngx-emoji-mart'
import { HttpClientModule } from '@angular/common/http'
import { ChatPageComponent } from './components/chat-page/chat-page.component'
import { ChatsComponent } from './components/chats/chats.component'
import { CommentsDialogComponent } from './components/comments-dialog/comments-dialog.component'
import { CopyrightClaimComponent } from './components/copyright-claim/copyright-claim.component'
import { InputdialogComponent } from './components/dialogs/inputdialog/inputdialog.component'
import { EditprofileComponent } from './components/editprofile/editprofile.component'
import { ExploreComponent } from './components/explore/explore.component'
import { FullscreenimageComponent } from './components/fullscreenimage/fullscreenimage.component'
import { HashtagComponent } from './components/hashtag/hashtag.component'
import { LikedComponent } from './components/liked/liked.component'
import { MakepostComponent } from './components/makepost/makepost.component'
import { NotificationsComponent } from './components/notifications/notifications.component'
import { OrdersuccessComponent } from './components/premium/ordersuccess/ordersuccess.component'
import { PremiumComponent } from './components/premium/premium/premium.component'
import { ReportComponent } from './components/report/report.component'
import { SearchComponent } from './components/search/search.component'
import { ChatsettingsComponent } from './components/settings/chatsettings/chatsettings.component'
import { DeleteaccountComponent } from './components/settings/deleteaccount/deleteaccount.component'
import { EmailsettingsComponent } from './components/settings/emailsettings/emailsettings.component'
import { FeedsettingsComponent } from './components/settings/feedsettings/feedsettings.component'
import { ManageaccountComponent } from './components/settings/manageaccount/manageaccount.component'
import { ManageblockedusersComponent } from './components/settings/manageblockedusers/manageblockedusers.component'
import { PasswordsettingsComponent } from './components/settings/passwordsettings/passwordsettings.component'
import { UsernameComponent } from './components/settings/username/username.component'
import { SinglePostComponent } from './components/single-post/single-post.component'
import { FollowersComponent } from './components/user/followers/followers.component'
import { UserComponent } from './components/user/user.component'

// stripe payments
import { NgxStripeModule } from 'ngx-stripe'

import { ServiceWorkerModule } from '@angular/service-worker'

import { AboutlukasniessenComponent } from './components/aboutlukasniessen/aboutlukasniessen.component'
import { AboutpageComponent } from './components/aboutpage/aboutpage.component'
import { ChatactionComponent } from './components/dialogs/chataction/chataction.component'
import { LoadingDialogComponent } from './components/dialogs/loading-dialog/loading-dialog.component'
import { PostshareclickComponent } from './components/dialogs/postshareclick/postshareclick.component'
import { ThreeButtonsDialogComponent } from './components/dialogs/three-buttons-dialog/three-buttons-dialog.component'
import { GifkeyboardComponent } from './components/gif/gifkeyboard/gifkeyboard.component'
import { DarkmodeComponent } from './components/settings/darkmode/darkmode.component'
import { LastseensettingsComponent } from './components/settings/lastseensettings/lastseensettings.component'
import { ManagemutedusersComponent } from './components/settings/managemutedusers/managemutedusers.component'
import { NsfwsettingsComponent } from './components/settings/nsfwsettings/nsfwsettings.component'
import { PwaComponent } from './components/settings/pwa/pwa.component'
import { SecurityComponent } from './components/settings/security/security.component'
import { StatisticspageComponent } from './components/statistics/statisticspage.component'
import { BackbuttontemplateComponent } from './components/templates/backbuttontemplate/backbuttontemplate.component'
import { ClosebuttonComponent } from './components/templates/buttons/closebutton/closebutton.component'
import { ChatitemtemplateComponent } from './components/templates/chats/chatitemtemplate/chatitemtemplate.component'
import { MessagetemplateComponent } from './components/templates/chats/messagetemplate/messagetemplate.component'
import { CommentpagetemplateComponent } from './components/templates/commentpagetemplate/commentpagetemplate.component'
import { CommenttemplateComponent } from './components/templates/commenttemplate/commenttemplate.component'
import { EmptyhinttemplateComponent } from './components/templates/emptyhinttemplate/emptyhinttemplate.component'
import { LoadingcircleComponent } from './components/templates/loadingcircle/loadingcircle.component'
import { NotificationdetailspreviewboxComponent } from './components/templates/notificationdetailspreviewbox/notificationdetailspreviewbox.component'
import { NotificationitemtemplateComponent } from './components/templates/notificationitemtemplate/notificationitemtemplate.component'
import { PosttemplateComponent } from './components/templates/posttemplate/posttemplate.component'
import { SearchbarComponent } from './components/templates/searchbar/searchbar.component'
import { ImageuploadtemplateComponent } from './components/templates/upload/imageuploadtemplate/imageuploadtemplate.component'
import { UsernametemplateComponent } from './components/templates/username/usernametemplate.component'
import { RoutinghelperService } from './shared/services/router/routinghelper.service'

import { DonationamountchooseComponent } from './components/dialogs/donationamountchoose/donationamountchoose.component'
import { RequestnotifsdialogComponent } from './components/dialogs/requestnotifsdialog/requestnotifsdialog.component'
import { DonationsinfoComponent } from './components/donations/donationsinfo/donationsinfo.component'
import { GroupinfoComponent } from './components/groupinfo/groupinfo.component'
import { CreategroupComponent } from './components/groups/creategroup/creategroup.component'
import { PushnotificationsettingsComponent } from './components/settings/pushnotificationsettings/pushnotificationsettings.component'
import { SubscriptionsComponent } from './components/settings/subscriptions/subscriptions.component'
import { DonationsProgressBarComponent } from './components/templates/donations-progress-bar/donations-progress-bar.component'
import { GroupitemtemplateComponent } from './components/templates/groupitemtemplate/groupitemtemplate.component'

import { FollowrequestsComponent } from './components/dialogs/followrequests/followrequests.component'
import { DonationreceivedComponent } from './components/donations/donationreceived/donationreceived.component'
import { StartingpagesettingsComponent } from './components/settings/startingpagesettings/startingpagesettings.component'
import { ClipsComponent } from './components/video/clips/clips.component'
import { VideoplayerComponent } from './components/videoplayer/videoplayer.component'
import { StartPageGuard } from './shared/guard/StartPageGuard'
import { VirtualscrollpredictingstrategyDirective } from './virtualscrollpredictingstrategy.directive'

import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play'

import { MatTooltipModule } from '@angular/material/tooltip'
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering'
import { VgControlsModule } from '@videogular/ngx-videogular/controls'
import { VgCoreModule } from '@videogular/ngx-videogular/core'
import { EmojiKeyboardComponent } from './components/keyboards/emoji-keyboard/emoji-keyboard.component'
import { NsfwConfirmationComponent } from './components/nsfw-confirmation/nsfw-confirmation.component'
import { MilestonesComponent } from './components/statistics/milestones/milestones.component'
import { BrandingComponent } from './components/templates/branding/branding.component'
import { BottomsheetBottomBrandingSpacerComponent } from './components/templates/dialogs/bottomsheet-bottom-branding-spacer/bottomsheet-bottom-branding-spacer.component'
import { ImageOverlayComponent } from './components/templates/overlays/image-overlay/image-overlay.component'
import { ClipplayerComponent } from './components/video/clipplayer/clipplayer.component'
import { HideAfterDirective } from './directives/hide-after.directive'
import { ShowOnlyOnMobileDirective } from './directives/show-only-on-mobile.directive'
import { SpaceyIfDirective } from './directives/spacey-if.directive'
import { TapDetectDirective } from './directives/tap-detect.directive'
import { LoggedInRequiredWithRedirectGuard } from './logged-in-required-with-redirect.guard'
import { LoggedInRequiredGuard } from './logged-in-required.guard'
import { ImgScaleHelperPipe } from './pipes/img-scale-helper.pipe'
import { NumberAbbrPipe } from './pipes/number-abbr.pipe'
import { NumberCommaFormatPipe } from './pipes/number-comma-format.pipe'
import { TimeformatPipe } from './pipes/timeformat.pipe'
import { NsfwConfirmGuardGuard } from './shared/guard/nsfw-confirm-guard.guard'
import { ShowOnlyOnMobileTestComponent } from './testing-components/show-only-on-mobile-test/show-only-on-mobile-test.component'
import { SpaceyIfTestingComponent } from './testing-components/spacey-if-testing/spacey-if-testing.component'
import { TapDirTestComponent } from './testing-components/tap-dir-test/tap-dir-test.component'
import { CdkDrag } from '@angular/cdk/drag-drop'
import { FeedChoosingBarComponent } from './components/feed/feed-choosing-bar/feed-choosing-bar.component'
import { UsualDividerComponent } from './components/templates/dividers/usual-divider/usual-divider.component'
import { AccountBannedComponent } from './components/templates/banned/account-banned/account-banned.component'
import { IpBannedComponent } from './components/templates/banned/ip-banned/ip-banned.component'
import { MainHeaderComponent } from './components/main/main-header/main-header.component'
import { MainBrandingComponent } from './components/templates/branding/main-branding/main-branding.component'
import { MainRowComponent } from './components/main/main-row/main-row.component'
import { ExploreHashtagItemComponent } from './components/explore/explore-hashtag-item/explore-hashtag-item.component'
import { ExploreUserItemComponent } from './components/explore/explore-user-item/explore-user-item.component'
import { ExploreSearchItemComponent } from './components/explore/explore-search-item/explore-search-item.component'
import { ExploreLoadMoreItemComponent } from './components/explore/explore-load-more-item/explore-load-more-item.component'
import { ExploreLoadingItemComponent } from './components/explore/explore-loading-item/explore-loading-item.component'
import { ChatChoosingBarComponent } from './components/chats/chat-choosing-bar/chat-choosing-bar.component'
import { SearchChoosingBarComponent } from './components/search/search-choosing-bar/search-choosing-bar.component'
import { BottomSheetDialogContainerDirective } from './directives/dialogs/bottom-sheet-dialog-container.directive'
import { PopUpFadeInDialogDirective } from './directives/dialogs/pop-up-fade-in-dialog.directive'
import { ExploreLocationItemComponent } from './components/explore/explore-location-item/explore-location-item.component'
import { LongTapDirective } from './directives/long-tap.directive'
import { SinglePostResolver } from './resolver/SinglePostResolver'
import { UserResolver } from './resolver/UserResolver'
import { ExploreResolver } from './resolver/ExploreResolver'
import { GroupPageResolver } from './resolver/GroupPageResolver'

const appRoutes: Routes = [
  { path: '', redirectTo: '/home/auto', pathMatch: 'full' },

  { path: 'login', component: SignInComponent },
  { path: 'login/email', component: EmailComponent },
  { path: 'signup', component: SignUpComponent },

  // nested routing
  // empty path here, so the sub-paths like settings are still like this: magahub.com/settings
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'startpage',
        canActivate: [StartPageGuard],
        component: FeedComponent
      },

      // For the two below, see **Note1**
      { path: 'home', component: FeedComponent },
      { path: 'home/:feedtype', component: FeedComponent },
      {
        path: 'adult/:feedtype',
        component: FeedComponent,
        canActivate: [NsfwConfirmGuardGuard]
      },

      { path: 'clips', component: ClipsComponent },
      { path: 'clip/:postID', component: ClipsComponent },
      {
        path: 'hentai-tiktok',
        component: ClipsComponent,
        canActivate: [NsfwConfirmGuardGuard]
      },

      // Deprecated. We now use /@username
      {
        path: 'u/:username',
        component: UserComponent,
        resolve: {
          userData: UserResolver
        }
      },

      {
        path: 'user/:userID',
        component: UserComponent,
        resolve: {
          userData: UserResolver
        }
      },

      // Match username but only if it starts with "@"
      {
        component: UserComponent,
        matcher: (url) => {
          return url.length === 1 && url[0].path.startsWith('@')
            ? { consumed: url }
            : null
        },
        resolve: {
          userData: UserResolver
        }
      },

      { path: 'recent', component: FeedComponent },

      {
        path: 'p/:postID',
        component: SinglePostComponent,
        resolve: {
          postData: SinglePostResolver
        }
      },

      {
        path: 'explore',
        component: ExploreComponent,
        resolve: { exploreData: ExploreResolver }
      },
      { path: 'explore/:explore', component: FeedComponent },

      // We use the explore component to display the group inv link dialog
      // since its easy and accessible for logged out users too
      {
        path: 'invite/:link',
        component: GroupinfoComponent,
        canActivate: [LoggedInRequiredWithRedirectGuard],
        resolve: { groupInfo: GroupPageResolver }
      },

      // **Note1**
      /**
       * both deprecated. use explore/... instead because of SEO
       */
      { path: 'search', component: SearchComponent },
      { path: 'results', component: SearchComponent },

      { path: 'discover/:query', component: SearchComponent },

      { path: 'tag/:i', component: HashtagComponent },

      { path: 'location/:i', component: HashtagComponent },

      // **Note1**
      // DEPRECATED (this whole note intention is no longer used and needed)
      // These two below are exactly the same
      // This is for technical reasons:
      // The 'router-bug' makes it so that when you are e.g. in the liked-tab, and then click
      // on the nav-bar bookmarked-tab, it will not load the actual bookmarks tab because
      // it's "the same path". So we artificially make it two different paths ("saved" vs "saves")
      // Note: There might be a better solution for this, but it works
      {
        path: 'saved/:basepath',
        component: LikedComponent,
        canActivate: [LoggedInRequiredWithRedirectGuard]
      },

      {
        path: 'notifications',
        component: NotificationsComponent,
        canActivate: [LoggedInRequiredWithRedirectGuard]
      },
      {
        path: 'editprofile',
        component: EditprofileComponent,
        canActivate: [LoggedInRequiredWithRedirectGuard]
      },
      { path: 'post', component: MakepostComponent },

      { path: 'chats', component: ChatsComponent },
      { path: 'groupchats', component: ChatsComponent },
      {
        path: 'chat',
        component: ChatsComponent,
        canActivate: [LoggedInRequiredWithRedirectGuard]
      },

      {
        path: 'group/:groupID',
        component: GroupinfoComponent,
        resolve: { groupInfo: GroupPageResolver }
      },
      {
        path: 'group/:groupID/:desc',
        component: GroupinfoComponent,
        resolve: { groupInfo: GroupPageResolver }
      },

      {
        path: 'settings/premium/handle',
        component: OrdersuccessComponent,
        canActivate: [LoggedInRequiredGuard]
      },

      { path: 'changelog', component: VersionhistoryComponent },
      { path: 'milestones', component: MilestonesComponent },
      { path: 'statistics', component: StatisticspageComponent },
      { path: 'about', component: AboutpageComponent },
      { path: 'lukasniessen', component: AboutlukasniessenComponent },

      // not found component shows in here now
      { path: '404', component: NotfoundComponent },

      {
        path: 'user/:uid/:basepath',
        component: FollowersComponent,
        canActivate: [LoggedInRequiredGuard]
      },

      { path: 'settings', component: SettingsComponent },
      {
        path: 'settings/account',
        component: ManageaccountComponent,
        canActivate: [LoggedInRequiredWithRedirectGuard]
      },
      {
        path: 'settings/nsfw',
        component: NsfwsettingsComponent,
        canActivate: [LoggedInRequiredWithRedirectGuard]
      },
      {
        path: 'settings/feed',
        component: FeedsettingsComponent,
        canActivate: [LoggedInRequiredWithRedirectGuard]
      },
      {
        path: 'settings/blockedusers',
        component: ManageblockedusersComponent,
        canActivate: [LoggedInRequiredWithRedirectGuard]
      },
      {
        path: 'settings/mutedusers',
        component: ManagemutedusersComponent,
        canActivate: [LoggedInRequiredWithRedirectGuard]
      },
      {
        path: 'settings/account/username',
        component: UsernameComponent,
        canActivate: [LoggedInRequiredWithRedirectGuard]
      },
      {
        path: 'settings/account/email',
        component: EmailsettingsComponent,
        canActivate: [LoggedInRequiredWithRedirectGuard]
      },
      {
        path: 'settings/account/password',
        component: PasswordsettingsComponent,
        canActivate: [LoggedInRequiredWithRedirectGuard]
      },
      {
        path: 'settings/account/deleteaccount',
        component: DeleteaccountComponent,
        canActivate: [LoggedInRequiredWithRedirectGuard]
      },
      {
        path: 'settings/premium',
        component: PremiumComponent,
        canActivate: [LoggedInRequiredWithRedirectGuard]
      },
      {
        path: 'premium',
        component: PremiumComponent,
        canActivate: [LoggedInRequiredWithRedirectGuard]
      },
      { path: 'donate', component: DonationsinfoComponent },
      {
        path: 'settings/chats',
        component: ChatsettingsComponent,
        canActivate: [LoggedInRequiredWithRedirectGuard]
      },
      {
        path: 'settings/editprofile',
        component: EditprofileComponent,
        canActivate: [LoggedInRequiredWithRedirectGuard]
      },
      { path: 'settings/darkmode', component: DarkmodeComponent },
      {
        path: 'settings/lastseen',
        component: LastseensettingsComponent,
        canActivate: [LoggedInRequiredWithRedirectGuard]
      },
      { path: 'settings/webapp', component: PwaComponent },
      {
        path: 'settings/account/security',
        component: SecurityComponent,
        canActivate: [LoggedInRequiredWithRedirectGuard]
      },
      {
        path: 'settings/pushnotifications',
        component: PushnotificationsettingsComponent,
        canActivate: [LoggedInRequiredWithRedirectGuard]
      },
      {
        path: 'settings/subscriptions',
        component: SubscriptionsComponent,
        canActivate: [LoggedInRequiredWithRedirectGuard]
      },
      {
        path: 'settings/startingpage',
        component: StartingpagesettingsComponent
      },

      { path: 'webapp', component: PwaComponent },
      { path: 'app', component: PwaComponent },
      { path: 'apk', component: PwaComponent },

      { path: 'privacy', component: PrivacyComponent },
      { path: 'terms', component: TermsComponent },
      { path: 'imprint', component: ImprintComponent },

      { path: 'donate/received', component: DonationreceivedComponent }
    ]
  },
  // --

  // ---
  // full screen components here
  // ---

  {
    path: 'message',
    component: ChatPageComponent,
    canActivate: [LoggedInRequiredGuard]
  },
  {
    path: 'msg',
    component: ChatPageComponent,
    canActivate: [LoggedInRequiredGuard]
  },

  {
    path: 'message/:chatID',
    component: ChatPageComponent,
    canActivate: [LoggedInRequiredGuard]
  },
  {
    path: 'msg/:chatID',
    component: ChatPageComponent,
    canActivate: [LoggedInRequiredGuard]
  },

  { path: 'makepost', component: MakepostComponent },

  // settings pages
  {
    path: 'mb-settings',
    component: SettingsComponent,
    canActivate: [LoggedInRequiredWithRedirectGuard]
  },
  {
    path: 'mb-settings/account',
    component: ManageaccountComponent,
    canActivate: [LoggedInRequiredWithRedirectGuard]
  },
  {
    path: 'mb-settings/nsfw',
    component: NsfwsettingsComponent,
    canActivate: [LoggedInRequiredWithRedirectGuard]
  },
  {
    path: 'mb-settings/feed',
    component: FeedsettingsComponent,
    canActivate: [LoggedInRequiredWithRedirectGuard]
  },
  {
    path: 'mb-settings/blockedusers',
    component: ManageblockedusersComponent,
    canActivate: [LoggedInRequiredWithRedirectGuard]
  },
  {
    path: 'mb-settings/mutedusers',
    component: ManagemutedusersComponent,
    canActivate: [LoggedInRequiredWithRedirectGuard]
  },
  {
    path: 'mb-settings/account/username',
    component: UsernameComponent,
    canActivate: [LoggedInRequiredWithRedirectGuard]
  },
  {
    path: 'mb-settings/account/email',
    component: EmailsettingsComponent,
    canActivate: [LoggedInRequiredWithRedirectGuard]
  },
  {
    path: 'mb-settings/account/password',
    component: PasswordsettingsComponent,
    canActivate: [LoggedInRequiredWithRedirectGuard]
  },
  {
    path: 'mb-settings/account/deleteaccount',
    component: DeleteaccountComponent,
    canActivate: [LoggedInRequiredWithRedirectGuard]
  },
  {
    path: 'mb-settings/premium',
    component: PremiumComponent,
    canActivate: [LoggedInRequiredWithRedirectGuard]
  },
  {
    path: 'mb-settings/chats',
    component: ChatsettingsComponent,
    canActivate: [LoggedInRequiredWithRedirectGuard]
  },
  {
    path: 'mb-settings/editprofile',
    component: EditprofileComponent,
    canActivate: [LoggedInRequiredWithRedirectGuard]
  },
  { path: 'mb-settings/darkmode', component: DarkmodeComponent },
  { path: 'mb-settings/webapp', component: PwaComponent },
  {
    path: 'mb-settings/lastseen',
    component: LastseensettingsComponent,
    canActivate: [LoggedInRequiredWithRedirectGuard]
  },
  {
    path: 'mb-settings/account/security',
    component: SecurityComponent,
    canActivate: [LoggedInRequiredWithRedirectGuard]
  },
  {
    path: 'mb-settings/pushnotifications',
    component: PushnotificationsettingsComponent,
    canActivate: [LoggedInRequiredWithRedirectGuard]
  },
  {
    path: 'mb-settings/subscriptions',
    component: SubscriptionsComponent,
    canActivate: [LoggedInRequiredWithRedirectGuard]
  },
  {
    path: 'mb-settings/startingpage',
    component: StartingpagesettingsComponent
  },

  {
    path: 'mb-u/:username',
    component: UserComponent,
    resolve: {
      userData: UserResolver
    }
  },
  {
    path: 'mb-user/:userID',
    component: UserComponent,
    resolve: {
      userData: UserResolver
    }
  },
  {
    path: 'mb-user/:uid/:basepath',
    component: FollowersComponent,
    canActivate: [LoggedInRequiredGuard]
  },

  { path: '**', pathMatch: 'full', redirectTo: '/404' }
]

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    SignInComponent,
    SignUpComponent,
    FeedComponent,
    NotfoundComponent,
    SettingsComponent,
    MainComponent,
    PrivacyComponent,
    TermsComponent,
    ImprintComponent,
    EmailComponent,
    ManageaccountComponent,
    FeedsettingsComponent,
    ManageblockedusersComponent,
    UsernameComponent,
    EmailsettingsComponent,
    PasswordsettingsComponent,
    DeleteaccountComponent,
    UserComponent,
    LikedComponent,
    SinglePostComponent,
    FollowersComponent,
    NotificationsComponent,
    CommentsDialogComponent,
    EditprofileComponent,
    MakepostComponent,
    SearchComponent,
    ReportComponent,
    CopyrightClaimComponent,
    HashtagComponent,
    ChatsComponent,
    ChatPageComponent,
    PremiumComponent,
    ChatsettingsComponent,
    OrdersuccessComponent,
    FullscreenimageComponent,
    InputdialogComponent,
    ExploreComponent,
    LoadingDialogComponent,
    NsfwsettingsComponent,
    VersionhistoryComponent,
    AboutpageComponent,
    AboutlukasniessenComponent,
    ChatactionComponent,
    PostshareclickComponent,
    ThreeButtonsDialogComponent,
    ManagemutedusersComponent,
    StatisticspageComponent,
    PosttemplateComponent,
    CommenttemplateComponent,
    CommentpagetemplateComponent,
    LoadingcircleComponent,
    ClosebuttonComponent,
    ImageuploadtemplateComponent,
    ChatitemtemplateComponent,
    MessagetemplateComponent,
    EmptyhinttemplateComponent,
    BackbuttontemplateComponent,
    NotificationitemtemplateComponent,
    DarkmodeComponent,
    PwaComponent,
    LastseensettingsComponent,
    SecurityComponent,
    GifkeyboardComponent,
    RequestnotifsdialogComponent,
    PushnotificationsettingsComponent,
    GroupitemtemplateComponent,
    GroupinfoComponent,
    CreategroupComponent,
    DonationsinfoComponent,
    DonationsProgressBarComponent,
    SubscriptionsComponent,
    DonationamountchooseComponent,
    VideoplayerComponent,
    DonationreceivedComponent,
    FollowrequestsComponent,
    VirtualscrollpredictingstrategyDirective,
    StartingpagesettingsComponent,
    ClipsComponent,
    ClipplayerComponent,
    BottomsheetBottomBrandingSpacerComponent,
    ShowOnlyOnMobileDirective,
    EmojiKeyboardComponent,
    NumberAbbrPipe,
    BrandingComponent,
    ImageOverlayComponent,
    TapDetectDirective,
    TimeformatPipe,
    MilestonesComponent,
    NsfwConfirmationComponent,
    SpaceyIfTestingComponent,
    ShowOnlyOnMobileTestComponent,
    TapDirTestComponent
  ],
  imports: [
    CdkScrollable,
    CdkScrollableModule,
    PickerModule,
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatMenuModule,
    MatInputModule,
    CdkDrag,
    AngularFireModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    RouterModule.forRoot(appRoutes, {
      enableTracing: true,
      scrollPositionRestoration: 'enabled'
    }),
    ScrollingModule,
    //HotToastModule.forRoot(),
    NgxStripeModule.forRoot(
      'pk_live_51MSI4lGWrHvGRwmY8UvjmckQwdhn9m7OxrdOe223JRt587Knn7wH9vGwJJMzPhJ8y9I5yUuDHtSFJSqSJWctwcp90076M1ONm4'
    ),
    HammerModule,
    HttpClientModule,
    NoopAnimationsModule,
    ServiceWorkerModule.register('combined-sw.js', {
      enabled: environment.production
    }),
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    FeedChoosingBarComponent,
    SpaceyIfDirective,
    UsualDividerComponent,
    ImgScaleHelperPipe,
    AccountBannedComponent,
    IpBannedComponent,
    MainHeaderComponent,
    MainBrandingComponent,
    SearchbarComponent,
    MainRowComponent,
    MainHeaderComponent,
    NotificationdetailspreviewboxComponent,
    UsernametemplateComponent,
    NumberCommaFormatPipe,
    HideAfterDirective,
    ExploreHashtagItemComponent,
    ExploreUserItemComponent,
    ExploreSearchItemComponent,
    ExploreLoadMoreItemComponent,
    ExploreLoadingItemComponent,
    ChatChoosingBarComponent,
    SearchChoosingBarComponent,
    BottomSheetDialogContainerDirective,
    PopUpFadeInDialogDirective,
    ExploreLocationItemComponent,
    LongTapDirective
  ],
  providers: [
    RoutinghelperService,
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { closeOnNavigation: true }
    },
    TimeformatPipe,
    provideClientHydration(),
    provideHttpClient(withFetch())
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
