import { Injectable } from '@angular/core'
import { DataSnapshot, child, get, getDatabase, ref } from 'firebase/database'
import { StrHlp } from '../StringGetter/getstring.service'
import { Observable, catchError, from, map, of } from 'rxjs'
import { ChatPageComponent } from 'src/app/components/chat-page/chat-page.component'
import { GLOBAL_CHAT_ID } from '../../constants'

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  private rtdb = ref(getDatabase())

  private cache = new Map<string, Observable<any>>()

  constructor() {}

  getUsername(userID: string): Observable<string> {
    const errorAnswer = 'username'
    const path = `${StrHlp.CLOUD_PATH}/Users/${userID}/username`
    return this.getObservable(path, errorAnswer)
  }

  getFullname(userID: string): Observable<string> {
    const errorAnswer = 'username'
    const path = `${StrHlp.CLOUD_PATH}/Users/${userID}/fullName`
    return this.getObservable(path, errorAnswer)
  }

  getGroupName(groupID: string): Observable<string> {
    // special case GC
    if (groupID === GLOBAL_CHAT_ID) {
      return of('Global Chat')
    }

    const errorAnswer = 'name'
    const path = `${StrHlp.CLOUD_PATH}/ChatGruppen/${groupID}/Infos/Name`
    return this.getObservable(path, errorAnswer)
  }

  getProfileImage(userID: string): Observable<string> {
    const errorAnswer = '/assets/default_profile_pic.jpg'
    const path = `${StrHlp.CLOUD_PATH}/Users/${userID}/profilePhoto`
    return this.getObservable(path, errorAnswer, false, true)
  }

  getGroupImage(groupID: string): Observable<string> {
    // special case GC
    if (groupID === GLOBAL_CHAT_ID) {
      return of('/assets/favico.png')
    }

    const errorAnswer = '/assets/default_group_pic.jpg'
    const path = `${StrHlp.CLOUD_PATH}/ChatGruppen/${groupID}/Infos/Bild`
    return this.getObservable(path, errorAnswer, false, true)
  }

  getGroupMessageCount(groupID: string): Observable<number> {
    const path =
      groupID === GLOBAL_CHAT_ID
        ? `${StrHlp.CLOUD_PATH}/GlobalChat/MessageCount`
        : `${StrHlp.CLOUD_PATH}/ChatGruppen/${groupID}/Dynamisch/MessageCount`

    const errorAnswer = 0
    return this.getObservable(path, errorAnswer)
  }

  getVerified(userID: string): Observable<boolean> {
    const path = `${StrHlp.CLOUD_PATH}/verifiedusers/${userID}`
    return this.getObservable(path, '', true)
  }

  private getHash(rtdbPath: string): string {
    return `${rtdbPath}`
  }

  private getObservable(
    rtdbPath: string,
    errorAnswer: any,
    returnExists: boolean = false,
    useErrorAnswerForFalsy: boolean = false
  ): Observable<any> {
    const hash = this.getHash(rtdbPath)

    if (this.cache.has(hash)) {
      return this.cache.get(hash)!
    } else {
      const returnObs = from(get(child(this.rtdb, rtdbPath))).pipe(
        catchError((e) => {
          console.error('Cache-service error:', e)
          return of(null) // Handle errors by returning null
        }),
        map((data) => {
          if (returnExists) {
            return data && data.exists()
          }

          if (data instanceof DataSnapshot) {
            if (data.exists()) {
              const val = data.val()

              if (useErrorAnswerForFalsy) {
                if (Boolean(val)) {
                  return val
                } else {
                  return errorAnswer
                }
              } else {
                return val
              }
            } else {
              return errorAnswer
            }
          } else {
            // data is a string
            return data
          }
        })
      )

      // cache SYNCHRONOUSLY!!
      this.cache.set(hash, returnObs)

      return returnObs
    }
  }
}
