<p class="date-hint" *ngIf="item.showDateHint">
  {{ getDateHintString(item.dateHintType) }}
</p>

<div class="item-wrapper">
  <button
    class="item subtle-button width-100"
    (click)="openNotification(item)"
    (longTap)="triggerMenu()"
    aria-label="Open notification"
  >
    <div class="notification">
      <div class="subWrapper1">
        <img
          [src]="image$ | async | imgScaleHelper: 50"
          alt="{{ name$ | async }} photo"
          onerror="this.src='/assets/default_profile_pic.jpg';this.onerror='';"
          class="profile-image userImage"
          [routerLink]="'/@' + (name$ | async)"
          loading="lazy"
        />
        <div>
          <app-usernametemplate [values]="{ userID: item.userid }" />

          <p class="text">
            {{
              item && item.descText && item.descText.length > 0
                ? item.descText
                : ''
            }}
          </p>

          <div class="subWrapper2">
            <span class="timeAsAgo">{{ item.timestamp | timeformat }}</span>
            <span class="simple_divider">•</span>
            <span class="timeAsClocktime">{{
              item.timestamp | timeformat: 1
            }}</span>
          </div>
        </div>
      </div>

      <img
        class="previewImage"
        *ngIf="item && item.previewURL && item.previewURL.length > 0"
        src="{{
          item && item.previewURL && item.previewURL.length > 0
            ? (item.previewURL | imgScaleHelper: 150)
            : ''
        }}"
        alt="Notification image"
        onerror="this.src='/assets/broken_file.png';this.onerror='';"
        loading="lazy"
      />
    </div>
  </button>

  <span
    [ngClass]="{
      'zero-width': hideMenuButton,
      'option-button': !hideMenuButton
    }"
    mat-button
    [matMenuTriggerFor]="menu"
    #menuTrigger="matMenuTrigger"
    matTooltip="Options"
    [matTooltipShowDelay]="1000"
  >
    <img
      class="dark-icon option-icon"
      src="/assets/menu.png"
      alt="options icon"
      loading="lazy"
    />
  </span>

  <mat-menu
    class="standard-options-menu bottom-sheet-matmenu-wrapper"
    #menu="matMenu"
    backdropClass="black-backdrop-light"
  >
    <div appBottomSheetDialogContainer>
      <div class="standard-options-dialog-item" (click)="openProfile()">
        <span class="options-img-wrap">
          <img
            class="dark-icon options-img"
            src="/assets/ic_set_avatar.png"
            alt="open profile icon"
            loading="lazy"
          />
        </span>
        <span class="options-text"> Open profile </span>
      </div>

      <div class="standard-options-dialog-item" (click)="deleteNotifDialog()">
        <span class="options-img-wrap">
          <img
            class="dark-icon options-img"
            src="/assets/ic_set_delete.png"
            alt="delete icon"
            loading="lazy"
          />
        </span>
        <span class="options-text"> Delete notification </span>
      </div>

      <app-bottomsheet-bottom-branding-spacer *appShowOnlyOnMobile />
    </div>
  </mat-menu>
</div>

<!-- xxx-new-notifications hint -->
<div class="new-activities-hint" *ngIf="showNewNotificationsHint">
  {{
    newNotificationsCount == 1
      ? '1 New Notification'
      : newNotificationsCount + ' New Notifications'
  }}
</div>
