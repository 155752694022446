<div id="container">
  <main id="container-content">
    <div id="header">
      <app-branding></app-branding>
    </div>

    <form [formGroup]="formGroup" (ngSubmit)="register()">
      <p class="divider-hint">Enter information</p>

      <input
        [formControl]="emailControl"
        type="email"
        placeholder="Email"
        maxlength="500"
      />

      <input
        [formControl]="passwordControl"
        type="{{ passwordVisible ? 'text' : 'password' }}"
        placeholder="Password"
        maxlength="500"
      />

      @if (passwordControl) {
        <div
          class="subtle-button password-options"
          (click)="changePasswordVisibility()"
        >
          {{ passwordVisible ? 'Hide password' : 'Show password' }}
        </div>
      }

      <p class="divider-hint">Terms</p>

      <div id="agree-wrapper">
        <input
          id="agree-terms-checkbox"
          type="checkbox"
          [formControl]="agreedControl"
        />

        <label for="agree-terms-checkbox" id="terms-label">
          I have read and agree to the
          <a class="law-link" href="/terms" target="_blank">Terms of Service</a>
          and
          <a class="law-link" href="/privacy" target="_blank">Privacy Policy</a
          >.
        </label>
      </div>

      <p class="divider-hint">Continue</p>

      <button
        type="submit"
        class="action-button"
        [ngClass]="{
          'action-button-disabled': !formGroup.valid,
          'red-button': formGroup.valid
        }"
        [disabled]="!formGroup.valid"
      >
        {{ indicateLoading ? 'Loading...' : 'Register' }}
      </button>
    </form>

    <p class="error-hint" *ngIf="errorHint">
      {{ errorHint }}
    </p>

    <div class="back-wrapper">
      <app-backbuttontemplate [showText]="true" [background]="true">
      </app-backbuttontemplate>
    </div>
  </main>
</div>
