<div class="fh-page-wrapper">
  <header class="fh-header header bottom-border-divider">
    <app-backbuttontemplate></app-backbuttontemplate>
    <h1 class="title">Username settings</h1>
  </header>

  <main class="fh-main content">
    <div class="formGroup" id="username-wrapper">
      <input
        type="text"
        class="formControl"
        placeholder="New username"
        maxlength="40"
        onkeydown="return /[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_.0123456789]/g.test(event.key)"
        [(ngModel)]="usernameEntered"
      />
    </div>

    <p *ngIf="showError" class="error-hint">
      {{ error }}
    </p>

    <button
      class="change-btn"
      [ngClass]="{
        'change-btn-disabled':
          buttonDisabled || !usernameEntered || showNotAllowed_TooRecent,
        'change-btn-enabled': !(buttonDisabled || !usernameEntered)
      }"
      [disabled]="buttonDisabled"
      (click)="changeUsername()"
    >
      Change username
    </button>

    <p class="error-hint" *ngIf="showNotAllowed_TooRecent">
      You cannot change your username because you have already changed it in the
      last 7 days.
    </p>

    <hr class="inline-divider-thick" />

    <p id="username-info-below" class="username-desc remove-middle">
      Your current username:
      <br />
      <br />
      &#64;{{ currUsername$ | async }}
      <br />
      {{ currProfileURL$ | async }}
      <br />
      <br />
      Usernames may only contain letters, numbers, underscores, and periods.
      Changing your username will also change your profile link. You can change
      your username once every 7 days.
    </p>
  </main>
</div>
