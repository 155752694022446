import { Component, Inject, OnInit } from '@angular/core'
import { HotToastService } from '@ngneat/hot-toast'
import { getDatabase, ref } from 'firebase/database'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { CopyrightClaimComponent } from '../copyright-claim/copyright-claim.component'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { LoadingDialogComponent } from '../dialogs/loading-dialog/loading-dialog.component'
import { TwobuttonsdialogService } from 'src/app/shared/services/dialogs/twobuttonsdialogservice.service'
import { AuthService } from 'src/app/shared/services/auth/auth.service'

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: [
    './report.component.css',
    '../makepost/makepost.component.css',
    '../settings/settingspage/settings.component.css',
    '../../stylesheets/dialogs/dialogstylesheet.css'
  ]
})
export class ReportComponent implements OnInit {
  static partsDivider = '_&%&_'

  prefixDivider = '_%&%_'

  userID: any = null
  rtdb = ref(getDatabase())

  /**
   * 0 = post
   * 1 = profile
   * 2 = story
   * 3 = message
   * 4 = komment
   * 5 = group
   */
  reportType: number = 0

  reportID: string = ''

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: HotToastService,
    public dialog: MatDialog,
    private twobuttonsdialogService: TwobuttonsdialogService
  ) {}

  ngOnInit(): void {
    this.userID = AuthService.getUID()
    this.reportType = this.data.reportType // post, comment, profile
    this.reportID = this.data.reportID // e.g. the postID, commentID, profileID
  }

  /**
   *
   * @param reportReason 1 = nudity
   *                      2 = illegal
   *                      3 = scam, spam
   *                      4 = violence
   *                      5 = child abuse
   *                      6 = other
   *
   */
  askMakeReport(reportReason: number): void {
    // ask if sure
    this.twobuttonsdialogService.show(
      'Report',
      'Do you want to send this report?',
      () => {
        // nothing
      },
      () => {
        this.makeReport(reportReason)
      },
      'Cancel',
      'Yes'
    )
  }

  makeReport(reportReason: number): void {
    const functions = getFunctions()
    const makeReport = httpsCallable(functions, 'makeReport')

    const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
      disableClose: true
    })

    makeReport({
      reportID: this.reportID,
      reportReason: reportReason + '',
      hubname: StrHlp.CLOUD_PATH,
      reportType: this.reportType + ''
    })
      .then((result) => {
        loadingDialogRef.close()
        const data = result.data
        this.toast.success('Report received')
      })
      .catch((error) => {
        loadingDialogRef.close()
        console.log(error)
        this.toast.error('Failed')
      })
  }

  openLegalIssueReporting(): void {
    let reportTypeAsString = ''
    if (this.reportType == 0) {
      reportTypeAsString = 'POST'
    } else if (this.reportType == 1) {
      reportTypeAsString = 'PROFILE'
    } else if (this.reportType == 2) {
      reportTypeAsString = 'STORY'
    } else if (this.reportType == 3) {
      reportTypeAsString = 'MESSAGE'
    } else if (this.reportType == 4) {
      reportTypeAsString = 'KOMMENT'
    } else if (this.reportType == 5) {
      reportTypeAsString = 'GROUP'
    }

    const data = {
      reportTypeAsString: reportTypeAsString,
      reportID: this.reportID
    }

    const dialogRef = this.dialog.open(CopyrightClaimComponent, {
      panelClass: 'report-dialog',
      autoFocus: false,
      data: data
    })
  }
}
