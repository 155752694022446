<div>
  <header
    class="query-bar"
    [ngClass]="{ 'bottom-border-divider': !scrollIsAtTop }"
  >
    <app-backbuttontemplate />

    <app-searchbar id="search-bar-top" [queryInput]="query" />
  </header>

  <div
    class="below-search-header-height"
    cdkVirtualScrollingElement
    #scrollingEl
  >
    <div class="empty-fill-to-header"></div>

    <section class="query-title-wrapper" aria-label="title">
      <h1 class="h1-query">
        {{ query }}
      </h1>

      <h3 class="h3-desc">Explore {{ strHlp.getCommunityName() }} content</h3>
    </section>

    <!-- tab selection bar -->
    <app-search-choosing-bar
      [selectedMode]="tabselected"
      [onSelectTopMode]="selectTab_0"
      [onSelectUsersMode]="selectTab_1"
      [onSelectTagsMode]="selectTab_2"
      [onSelectLocationsMode]="selectTab_3"
    />

    @if (tabselected == 0) {
      <div>
        <h3 class="subheader">Users</h3>

        <div id="listWrapper">
          <app-usual-divider />

          @for (item of items | slice: 0 : 3; track item.userID) {
            <a
              class="subtle-button item"
              role="link"
              aria-label="Open profile"
              routerLink="/@{{ item.username }}"
            >
              <img
                [src]="item.image$ | async | imgScaleHelper: 50"
                onerror="this.src='/assets/default_profile_pic.jpg';this.onerror='';"
                class="profile-image userImage"
                alt="{{ item.username }} photo"
              />

              <app-usernametemplate
                [values]="{
                  userID: item.userID,
                  createAsDiv: true
                }"
              />
            </a>
          }

          @if (items.length >= countLoadUsers_FY) {
            <div class="showAllUsersButton" (click)="selectTab(1)">
              Show all...
            </div>
          }
        </div>

        @if (!emptyUsers && items.length == 0) {
          <app-loadingcircle />
        }

        @if (emptyUsers) {
          <app-emptyhinttemplate
            [bigPadding]="false"
            [text]="'No users found...'"
          />
        }

        <h3 class="subheader">Posts</h3>

        @if (!loadPostsInProgress) {
          @if (strHlp.getAllowsNSFW()) {
            <div class="sort-filter-wrapper">
              <button
                class="subtle-button sort-filter-button usual-border-radius"
                [ngClass]="{
                  'sort-filter-button-active': !isNSFW,
                  'sort-filter-button-inactive': isNSFW
                }"
                (click)="changeNSFW(false)"
              >
                Normal
              </button>

              <button
                class="subtle-button sort-filter-button usual-border-radius"
                [ngClass]="{
                  'sort-filter-button-active': isNSFW,
                  'sort-filter-button-inactive': !isNSFW
                }"
                (click)="changeNSFW(true)"
              >
                NSFW
              </button>
            </div>
          }

          <div class="sort-filter-wrapper">
            <button
              class="subtle-button sort-filter-button usual-border-radius"
              [ngClass]="{
                'sort-filter-button-active': sortMode == 0,
                'sort-filter-button-inactive': sortMode != 0
              }"
              (click)="changeSortTo(0)"
            >
              Likes
            </button>

            <button
              class="subtle-button sort-filter-button usual-border-radius"
              [ngClass]="{
                'sort-filter-button-active': sortMode == 1,
                'sort-filter-button-inactive': sortMode != 1
              }"
              (click)="changeSortTo(1)"
            >
              Trending
            </button>

            <button
              class="subtle-button sort-filter-button usual-border-radius"
              [ngClass]="{
                'sort-filter-button-active': sortMode == 2,
                'sort-filter-button-inactive': sortMode != 2
              }"
              (click)="changeSortTo(2)"
            >
              Newest
            </button>
          </div>

          <div class="sort-filter-wrapper">
            <button
              class="subtle-button sort-filter-button usual-border-radius"
              [ngClass]="{
                'sort-filter-button-active': contentType == 0,
                'sort-filter-button-inactive': contentType != 0
              }"
              (click)="changeContentType(0)"
            >
              All
            </button>

            <button
              class="subtle-button sort-filter-button usual-border-radius"
              [ngClass]="{
                'sort-filter-button-active': contentType == 1,
                'sort-filter-button-inactive': contentType != 1
              }"
              (click)="changeContentType(1)"
            >
              Video
            </button>

            <button
              class="subtle-button sort-filter-button usual-border-radius"
              [ngClass]="{
                'sort-filter-button-active': contentType == 2,
                'sort-filter-button-inactive': contentType != 2
              }"
              (click)="changeContentType(2)"
            >
              Image
            </button>

            <button
              class="subtle-button sort-filter-button usual-border-radius"
              [ngClass]="{
                'sort-filter-button-active': contentType == 3,
                'sort-filter-button-inactive': contentType != 3
              }"
              (click)="changeContentType(3)"
            >
              Only text
            </button>
          </div>

          <div class="sort-filter-wrapper" *ngIf="sortMode != 2">
            <button
              class="subtle-button sort-filter-button usual-border-radius"
              [ngClass]="{
                'sort-filter-button-active': timeframe == 0,
                'sort-filter-button-inactive': timeframe != 0
              }"
              (click)="changeTimeFrame(0)"
            >
              All time
            </button>

            <button
              class="subtle-button sort-filter-button usual-border-radius"
              [ngClass]="{
                'sort-filter-button-active': timeframe == 1,
                'sort-filter-button-inactive': timeframe != 1
              }"
              (click)="changeTimeFrame(1)"
            >
              This year
            </button>

            <button
              class="subtle-button sort-filter-button usual-border-radius"
              [ngClass]="{
                'sort-filter-button-active': timeframe == 2,
                'sort-filter-button-inactive': timeframe != 2
              }"
              (click)="changeTimeFrame(2)"
            >
              This month
            </button>

            <button
              class="subtle-button sort-filter-button usual-border-radius"
              [ngClass]="{
                'sort-filter-button-active': timeframe == 3,
                'sort-filter-button-inactive': timeframe != 3
              }"
              (click)="changeTimeFrame(3)"
            >
              Today
            </button>
          </div>
        }

        <app-usual-divider />

        <section id="postsWrapper">
          <cdk-virtual-scroll-viewport
            #virtualScrollViewport
            class="post-list"
            predictingVirtualScroll
            [data]="{
              items: feedDataService.postList,
              itemType: 0,
              idAttrName: 'postID'
            }"
          >
            <div
              class="post"
              *cdkVirtualFor="
                let post of feedDataService.postList;
                let i = index
              "
              [attr.data-hm-id]="post.postID"
            >
              <post-template
                [post]="{
                  post: post,
                  indexInList: i,
                  hideMuteButton: true,
                  postEnv: 0
                }"
              >
              </post-template>
            </div>
          </cdk-virtual-scroll-viewport>

          @if (loadPostsInProgress) {
            <app-loadingcircle />
          }
        </section>

        <app-emptyhinttemplate
          *ngIf="emptyPosts"
          [bigPadding]="false"
          [text]="'No posts found...'"
        />
      </div>
    }

    <!-- Users -->
    @if (tabselected == 1) {
      @if (!emptyUsers) {
        <div class="sort-hint">Sorted alphabetically</div>
      }

      @for (item of items; track item.userID) {
        <a
          class="subtle-button item"
          role="link"
          aria-label="Open profile"
          routerLink="/@{{ item.username }}"
        >
          <img
            [src]="item.image$ | async | imgScaleHelper: 50"
            onerror="this.src='/assets/default_profile_pic.jpg';this.onerror='';"
            class="profile-image userImage"
            alt="user profile image"
          />

          <app-usernametemplate
            [values]="{
              userID: item.userID,
              createAsDiv: true
            }"
          />
        </a>
      }

      @if (loadingUsersInProgress) {
        <app-loadingcircle />
      }

      @if (emptyUsers) {
        <app-emptyhinttemplate
          [bigPadding]="false"
          [text]="'No users found...'"
        />
      }
    }

    <!-- Tags -->
    @if (tabselected == 2) {
      @if (!emptyTags) {
        <div class="sort-hint">Sorted alphabetically</div>
      }

      @for (hashtag of items_Tags; track hashtag.hashtag) {
        <app-explore-hashtag-item
          [tag]="hashtag.hashtag"
          [countInput]="hashtag.count"
        />
      }

      @if (loadingInProgress_Tags) {
        <app-loadingcircle />
      }

      @if (emptyTags) {
        <app-emptyhinttemplate
          [bigPadding]="false"
          [text]="'No hashtags found...'"
        />
      }
    }

    <!-- Locations -->
    @if (tabselected == 3) {
      @if (emptyLocations) {
        <app-emptyhinttemplate
          [bigPadding]="false"
          [text]="'No locations found...'"
        />
      } @else {
        <div class="sort-hint">Sorted alphabetically</div>
      }

      @for (item of items_Locations; track item.location) {
        <app-explore-location-item
          [location]="item.location"
          [countInput]="item.count"
        />
      }

      @if (loadingInProgress_Locations) {
        <app-loadingcircle />
      }
    }
  </div>
</div>
