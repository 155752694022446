import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { RouterModule } from '@angular/router'
import { MainFooterNavigationComponent } from 'src/app/components/navigation/main-footer-navigation/main-footer-navigation.component'
import { NotificationdetailspreviewboxComponent } from 'src/app/components/templates/notificationdetailspreviewbox/notificationdetailspreviewbox.component'
import { HideAfterDirective } from 'src/app/directives/hide-after.directive'
import { MaindataService } from 'src/app/shared/services/data/maindata.service'
import { SystemService } from 'src/app/shared/services/system/systemservice.service'

@Component({
  selector: 'app-content-row',
  standalone: true,
  imports: [
    CommonModule,
    MainFooterNavigationComponent,
    NotificationdetailspreviewboxComponent,
    RouterModule,
    HideAfterDirective
  ],
  templateUrl: './content-row.component.html',
  styleUrl: './content-row.component.css'
})
export class ContentRowComponent {
  treatAsMobile = SystemService.isMobile()

  constructor(public maindataService: MaindataService) {}
}
