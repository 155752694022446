import { Injectable } from '@angular/core'
import { Haptics, ImpactStyle } from '@capacitor/haptics'
import * as Bowser from 'bowser'
import { AuthService } from '../auth/auth.service'
import { getMessaging, getToken } from 'firebase/messaging'
import { getDatabase, ref, set } from 'firebase/database'
import { StrHlp } from '../StringGetter/getstring.service'
import { isPlatformBrowser } from '@angular/common'

@Injectable({
  providedIn: 'root'
})
export class SystemService {
  private static key_alreadyRequestedNotifPermissionToday =
    'key_alreadyRequestedNotifPermissionToday'

  private static key_darkmode_setting = 'key_darkmode_setting'
  private static currDarkmodeSetting: boolean = false

  private static isMobileInit: boolean = false
  private static isMobileVar: boolean = false

  /**
   * Means: a device token was provided, especially the user allowed push notifs
   */
  public static pushNotifsCapable: boolean = false

  constructor() {}

  public static setUpPushNotifs(forceAsk: boolean = false) {
    // bad ssr guard
    if (typeof window === 'undefined') {
      return
    }

    // user must be logged in, even if forceAsk
    if (!AuthService.isUserLoggedIn()) {
      return
    }

    // only do it on desktop or PWA
    if (!forceAsk) {
      if (!SystemService.isPWA() && SystemService.isMobile()) {
        return
      }
    }

    const rtdb = getDatabase()
    const messaging = getMessaging()
    const vapidKey =
      'BEPMc0rRH2TEQ4PVt0oDTuyiGa05wGux0rEQ4LSDiO57RSpK7yYPjDah4RSCLLKeQBRukb4wdQUt5gmstf2o7LY'

    getToken(messaging, { vapidKey: vapidKey })
      .then(async (currentToken) => {
        if (currentToken) {
          SystemService.pushNotifsCapable = true

          // save to server
          console.log('currentToken: ' + currentToken)
          await set(
            ref(rtdb, `${StrHlp.CLOUD_PATH}/Tokens/${AuthService.getUID()}`),
            currentToken
          )
        } else {
          // Show permission request UI
          console.log(
            'No registration token available. Request permission to generate one.'
          )

          if (forceAsk) {
            SystemService.requestPermission_CldMsg()
          } else {
            // check when the last time sent, no more than once per day
            if (!SystemService.alreadyRequestedNotifPermissionToday()) {
              SystemService.requestPermission_CldMsg()
              SystemService.setAlreadyRequestedNotifPermissionToday()
            }
          }
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err)
        // ...
      })
  }

  private static alreadyRequestedNotifPermissionToday(): boolean {
    if (typeof localStorage === 'undefined') {
      // ssr guard
      return true
    }

    const dayInMillis = 86400000
    let stamp = 0
    const stampRaw = localStorage.getItem(
      SystemService.key_alreadyRequestedNotifPermissionToday
    )
    if (stampRaw !== null) {
      stamp = +stampRaw
    }
    return Date.now() - stamp < dayInMillis
  }

  private static setAlreadyRequestedNotifPermissionToday() {
    if (typeof localStorage === 'undefined') {
      // ssr guard
      return
    }

    localStorage.setItem(
      SystemService.key_alreadyRequestedNotifPermissionToday,
      Date.now() + ''
    )
  }

  private static requestPermission_CldMsg() {
    // request permission
    //console.log('Requesting permission...');
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        //console.log('Notification permission granted.');
      }
    })
  }

  static isMobile(): boolean {
    // check if its node environment (ssr server)
    if (typeof window === 'undefined') {
      return true
    }

    if (!SystemService.isMobileInit) {
      const userAgent = navigator.userAgent
      const mobileRegex =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i
      SystemService.isMobileVar = mobileRegex.test(userAgent)
      SystemService.isMobileInit = true
    }
    return SystemService.isMobileVar
  }

  static isMobileIOS(): boolean {
    // check if its node environment (ssr server)
    if (typeof window === 'undefined') {
      return false
    }
    if (typeof document === 'undefined') {
      return false
    }

    return (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    )
  }

  static isIOS() {
    // check if its node environment (ssr server)
    if (typeof window === 'undefined') {
      return false
    }
    if (typeof document === 'undefined') {
      return false
    }

    return (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
        'MacIntel' // Added this to include desktop iOS
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    )
  }

  static isChrome(): boolean {
    // check if its node environment (ssr server)
    if (typeof window === 'undefined') {
      return true
    }

    //const browser = Bowser.getParser(window.navigator.userAgent);
    //return browser.satisfies({ chrome: '>=0' }) == true;

    return navigator.userAgent.indexOf('Chrome') != -1
  }

  static isSafari(): boolean {
    // check if its node environment (ssr server)
    if (typeof window === 'undefined') {
      return false
    }

    //const browser = Bowser.getParser(window.navigator.userAgent);
    //return browser.satisfies({ safari: '>=0' }) == true;

    return navigator.userAgent.indexOf('Safari') != -1
  }

  static isAndroid(): boolean {
    return /(android)/i.test(navigator.userAgent)
  }

  static isPWA(): boolean {
    // ssr-guarded
    if (typeof window === 'undefined') {
      return false
    }
    if (typeof document === 'undefined') {
      return false
    }

    const isInStandaloneMode = () =>
      window.matchMedia('(display-mode: standalone)').matches ||
      (window.navigator as any).standalone ||
      document.referrer.includes('android-app://')

    return isInStandaloneMode()
  }

  static hapticsImpactLight = async () => {
    await Haptics.impact({ style: ImpactStyle.Light })
  }
  static hapticsImpactMedium = async () => {
    await Haptics.impact({ style: ImpactStyle.Medium })
  }

  static isInEU(): boolean {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    //console.log("-------------------------------------------------------------");
    //console.log(timezone);
    //console.log("-------------------------------------------------------------");
    return timezone.includes('Europe')
  }

  static updateDarkmode(darkMode: boolean) {
    if (typeof localStorage === 'undefined') {
      // ssr guard
      return
    }

    const updateNeeded = SystemService.currDarkmodeSetting != darkMode

    if (updateNeeded) {
      // locally
      SystemService.currDarkmodeSetting = darkMode

      // persist
      localStorage.setItem(SystemService.key_darkmode_setting, '' + darkMode)

      // apply
      SystemService.toggleDarkmodeSetting()
    }
  }

  static toggleDarkmodeSetting() {
    if (typeof document === 'undefined') {
      return
    }

    document.body.classList.toggle('dark-theme')

    // update index.HTML too
    SystemService.updateDarkmodeIndexHTML()
  }

  static updateDarkmodeIndexHTML() {
    if (typeof document === 'undefined') {
      return
    }

    const themeColorMetaTag = document.querySelector('meta[name="theme-color"]')
    const backgroundColorMetaTag = document.querySelector(
      'meta[name="background-color"]'
    )

    if (SystemService.currDarkmodeSetting) {
      if (themeColorMetaTag) {
        themeColorMetaTag.setAttribute('content', '#000000')
      }
      if (backgroundColorMetaTag) {
        backgroundColorMetaTag.setAttribute('content', '#000000')
      }
    } else {
      if (themeColorMetaTag) {
        themeColorMetaTag.setAttribute('content', '#ffffff')
      }
      if (backgroundColorMetaTag) {
        backgroundColorMetaTag.setAttribute('content', '#ffffff')
      }
    }
  }

  static loadDarkmodeOnStart() {
    if (typeof localStorage === 'undefined') {
      // ssr guard
      return
    }

    SystemService.currDarkmodeSetting =
      localStorage.getItem(SystemService.key_darkmode_setting) === 'true'

    if (SystemService.currDarkmodeSetting) {
      SystemService.toggleDarkmodeSetting()
    }
  }

  static isDarkmode(): boolean {
    return SystemService.currDarkmodeSetting
  }
}
