import { Injectable, NgZone } from '@angular/core'
import { isObservable, Observable, firstValueFrom } from 'rxjs'
import {
  util_GetPassedTime_Formatted,
  util_TrackTime
} from '../../helper/TimeTracker'

// DEPRECATED

/**
 * This is a common pattern used to make Angular’s Server-Side Rendering (SSR)
 * wait for asynchronous operations to complete before sending the HTML back to the client.
 * This is done by scheduling a macro task in the Angular zone that will only be resolved when
 * the promise or observable is completed.
 *
 * Here’s a breakdown of how it works:
 * The waitFor function takes a promise or an observable as an argument.
 * If the argument is an observable, it is converted to a promise using the firstValueFrom
 * function from rxjs. This will return a promise that resolves with the first value emitted
 * by the observable.
 * A macro task is scheduled in the Angular zone. This task does nothing but it prevents Angular
 * from considering the application as stable (which is the condition for the HTML to be sent
 * back to the client).
 * When the promise is resolved, the macro task is invoked, which removes it from the task queue
 * and allows Angular to consider the application as stable again.
 */

declare const Zone: any

const TIMEOUT_AFTER_MS = 2500

const LOG_KEY = 'WaitForServiceLogs'

@Injectable({
  providedIn: 'root'
})
export class WaitForService {
  private taskId = 0

  constructor(private ngZone: NgZone) {}

  /*
  waitForOld<T>(
    promise: Promise<T> | Observable<T>,
    descriptionOfTask: string
  ) {
    if (isObservable(promise)) {
      promise = firstValueFrom(promise)
    }

    const macrotaskName = `WAITFOR-${this.taskId++}`

    const macroTask = Zone.current.scheduleMacroTask(
      macrotaskName,
      () => {},
      {},
      () => {}
    )

    util_TrackTime(macrotaskName)

    promise
      .then(() =>
        console.log(
          LOG_KEY,
          'macroTask',
          macrotaskName,
          ' > then()... was called',
          descriptionOfTask
        )
      )
      .catch((e) =>
        console.log(
          LOG_KEY,
          'macroTask',
          macrotaskName,
          ' > catch()... was called',
          descriptionOfTask,
          '|',
          e
        )
      )
      .finally(() => {
        macroTask.invoke()
        console.log(
          LOG_KEY,
          'macroTask',
          macrotaskName,
          ' was INVOKED.',
          descriptionOfTask
        )
        console.log(
          LOG_KEY,
          'this took:',
          util_GetPassedTime_Formatted(macrotaskName)
        )

        // DOES THIS CANCEL THE MACRO TASK FROM THE TASK QUEUE?
        // OR ONLY THE CALLBACK NOT BE CALLED?
        // MEANING, WILL THE APP BECOME STABLE OR NOT?
        //clearTimeout(timeoutId)
      })

    console.log(
      LOG_KEY,
      'macroTask',
      macrotaskName,
      ' was scheduled:',
      descriptionOfTask
    )
  }
  */

  /**
   *
   * @param promise pass the async function LIKE THIS!!!
   *                 `waitFor(() => myFunc(), "desc")`
   *                NOT IN ANY OTHER WAY. WILL NOT WORK OTHERWISE.
   * @param descriptionOfTask
   */
  waitForDEPRECATED(
    asyncOperation: () => Promise<void>,
    descriptionOfTask: string
  ) {
    // disabled for now
    if (0 + 0 == 0) {
      return
    }

    /*
    const macrotaskName = `WAITFOR-${this.taskId++}`
    const macroTask = Zone.current.scheduleMacroTask(
      macrotaskName,
      () => {},
      {},
      () => {}
    )

    // Create a new promise that rejects after TIMEOUT_AFTER_MS
    // but it runs outside of angular
    const timeoutPromise = new Promise((resolve, _) => {
      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          resolve('Operation timed out')
          console.log(LOG_KEY, 'timeout-promise rejected...')
        }, TIMEOUT_AFTER_MS)
      })
    })

    // Create a wrapper promise that runs outside of angular
    const promise = new Promise<void>((resolve, _) => {
      this.ngZone.runOutsideAngular(() => {
        asyncOperation()
          .then(() => {
            console.log(LOG_KEY, 'wrapped-promise finished successfully.')
          })
          .catch((e) => {
            console.log(LOG_KEY, 'wrapped-promise error:', e)
          })
          .finally(() => {
            resolve()
            console.log(LOG_KEY, 'wrapped-promise resolved...')
          })
      })
    })

    // Race the promise against the timeout
    Promise.race([promise, timeoutPromise]).finally(() => {
      macroTask.invoke()
      console.log(
        LOG_KEY,
        'wait-for took:',
        util_GetPassedTime_Formatted(macrotaskName),
        '|',
        descriptionOfTask
      )
    })

    util_TrackTime(macrotaskName)
    */
  }
}
