<div id="wrapper">
  <p id="title" *ngIf="title.length > 0">{{ title }}</p>

  <p class="desc" *ngIf="hint.length > 0">{{ hint }}</p>

  <hr />

  <textarea
    class="inputArea"
    placeholder="Enter text..."
    maxlength="{{ maxTextLength }}"
    [(ngModel)]="textEntered"
  >
  </textarea>

  <div id="buttonsWrapper">
    <div class="button" id="actionButton" (click)="action()">
      {{ actionButtonName }}
    </div>

    <div class="button" id="cancelButton" (click)="cancel()">
      {{ cancelButtonName }}
    </div>
  </div>
</div>
