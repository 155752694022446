<div id="wrapper">
  <header class="header" *ngIf="!considerZoomedIn && !hideHeader">
    <div id="side-button-bar">
      <div class="side-button">
        <img
          src="/assets/ic_close_2.png"
          class="side-button-img"
          mat-dialog-close
          alt="close"
        />
      </div>

      <div class="side-button">
        <img
          src="/assets/ic_download.png"
          alt="download"
          class="side-button-img"
          (click)="downloadImage()"
        />
      </div>

      <div class="side-button" *ngIf="isGIF && !gifIsFavorized">
        <img
          src="/assets/ic_star.png"
          alt="star"
          class="side-button-img"
          (click)="favorizeGIF()"
        />
      </div>

      <div class="side-button" *ngIf="isGIF && gifIsFavorized">
        <img
          src="/assets/ic_star_filled.png"
          alt="star"
          class="side-button-img"
          (click)="unfavorizeGIF()"
        />
      </div>
    </div>

    <div class="text-header" *ngIf="title || desc">
      <h2 class="title text-line-in-header" *ngIf="title">{{ title }}</h2>
      <p class="desc text-line-in-header" *ngIf="desc">{{ desc }}</p>
    </div>
  </header>

  <div id="image-container" (click)="swapHeaderHiding()">
    <img
      #image
      src="{{ mediaURL }}"
      id="image"
      alt="Fullscreen image"
      onerror="src='/assets/broken_file.png';onerror='';"
      cdkDrag
      cdkDragLockAxis="y"
      (cdkDragEnded)="onDragEnd($event)"
      (click)="swapHeaderHiding()"
    />
  </div>
</div>
