<div class="fh-page-wrapper">
  <header class="fh-header header bottom-border-divider">
    <app-backbuttontemplate></app-backbuttontemplate>
    <h1 class="title">Email settings</h1>
  </header>

  <main class="fh-main content">
    <p class="setting-desc remove-middle" id="username-wrapper">
      Current email: {{ currentEmail }}
    </p>

    <div class="formGroup remove-middle">
      <input
        type="text"
        class="formControl"
        placeholder="New email"
        maxlength="500"
        #email
        [(ngModel)]="emailEntered"
      />
    </div>
    <p #emailInvalidError class="email-invalid-error remove-middle">
      Invalid email
    </p>

    <div class="formGroup remove-middle">
      <input
        type="password"
        class="formControl"
        placeholder="Enter password"
        maxlength="500"
        #password
        [(ngModel)]="passwordEntered"
      />
    </div>

    <p
      class="password-options remove-middle"
      id="showpassword"
      #showpassword
      *ngIf="passwordEntered != ''"
      (click)="changePasswordVisibility()"
    >
      Show password
    </p>

    <p #passwordInvalidError class="email-invalid-error remove-middle">
      Invalid password
    </p>

    <p class="setting-desc remove-middle">
      You can change the email of your account. You must enter your password to
      confirm that is is you.
    </p>

    <button
      class="setting-button remove-middle"
      #changeButton
      (click)="changeEmail()"
    >
      Change email
    </button>
  </main>
</div>
