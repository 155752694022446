<div class="fh-page-wrapper">
  <header class="fh-header header bottom-border-divider" *appShowOnlyOnMobile>
    <app-backbuttontemplate />

    <h1 class="title" (click)="scrollTop()">Settings</h1>
  </header>

  <main class="fh-main content-settingspage" #contentWrapper>
    <h2 class="sub-title">Support</h2>

    <a
      class="subtle-button width-100 settings-item"
      routerLink="/@support"
      role="link"
      aria-label="Open support profile"
    >
      <span class="settings-img-wrap">
        <img
          class="settings-img-keep-original"
          src="/assets/favico.png"
          alt="app logo"
        />
      </span>
      <span class="settings-text"> Latest updates </span>
    </a>

    <a
      class="settings-item"
      routerLink="/webapp"
      role="link"
      aria-label="Install app"
      *ngIf="!isPWA"
    >
      <span class="settings-img-wrap settings-red-like">
        <img
          class="light-icon settings-img"
          src="/assets/outline_install_desktop_black_36dp.png"
          alt="install"
        />
      </span>
      <span class="settings-text"> Install app </span>
    </a>

    <h2 class="sub-title">Highlighted</h2>

    <a
      class="settings-item"
      routerLink="darkmode"
      href="darkmode"
      role="link"
      aria-label="Open darkmode settings"
    >
      <span class="settings-img-wrap settings-black">
        <img
          class="light-icon settings-img"
          src="/assets/ic_darkmode_moon.png"
          alt="dark theme"
        />
      </span>
      <span class="settings-text"> Dark mode </span>
    </a>

    <a
      class="subtle-button width-100 settings-item"
      routerLink="premium"
      href="premium"
      role="link"
      aria-label="Buy premium"
    >
      <span class="settings-img-wrap settings-lila2">
        <img
          class="light-icon settings-img"
          src="/assets/ic_emoji_button.png"
          alt="premium"
        />
      </span>
      <span class="settings-text"> Go premium </span>
    </a>

    <a
      class="subtle-button width-100 settings-item"
      routerLink="account"
      href="account"
      role="link"
      aria-label="Open manage account page"
    >
      <span class="settings-img-wrap settings-lila">
        <img
          class="light-icon settings-img"
          src="/assets/ic_set_avatar.png"
          alt="account"
        />
      </span>
      <span class="settings-text"> Manage account </span>
    </a>

    <h2 class="sub-title">General</h2>

    <a
      class="subtle-button width-100 settings-item"
      routerLink="editprofile"
      href="editprofile"
      role="link"
      aria-label="Go to edit profile"
    >
      <span class="settings-img-wrap settings-blue2">
        <img
          class="light-icon settings-img"
          src="/assets/bottomsheet_ic_edit.png"
          alt="edit"
        />
      </span>
      <span class="settings-text"> Edit profile </span>
    </a>

    <!--
        <div class="disabled-setting-in-web-version-for-now settings-item">
            <span class="settings-img-wrap settings-blue1">
                <img class="light-icon settings-img"
                    src="/assets/ic_language.png">
            </span>
            <span class="settings-text">
                Choose language
            </span>
        </button>

        <button class="disabled-setting-in-web-version-for-now settings-item">
            <span class="settings-img-wrap settings-blue2">
                <img class="light-icon settings-img"
                    src="/assets/ic_paint_design.png">
            </span>
            <span class="settings-text">
                Customize design
            </span>
        </button>
        -->

    <a
      class="subtle-button width-100 settings-item"
      routerLink="lastseen"
      role="link"
      aria-label="Open last-seen settings"
    >
      <span class="settings-img-wrap settings-blue8">
        <img
          class="light-icon settings-img"
          src="/assets/ic_set_lastseen.png"
          alt="privacy"
        />
      </span>
      <span class="settings-text"> Show last-Seen </span>
    </a>

    <a
      class="subtle-button width-100 settings-item"
      routerLink="chats"
      href="chats"
      role="link"
      aria-label="Open chats settings"
    >
      <span class="settings-img-wrap settings-blue4">
        <img
          class="light-icon settings-img"
          src="/assets/ic_set_chat.png"
          alt="chats"
        />
      </span>
      <span class="settings-text"> Chat settings </span>
    </a>

    <a
      class="subtle-button width-100 settings-item"
      routerLink="pushnotifications"
      href="pushnotifications"
      role="link"
      aria-label="Open push notification settings"
    >
      <span class="settings-img-wrap settings-blue5">
        <img
          class="light-icon settings-img"
          src="/assets/ic_set_notification.png"
          alt="notifications"
        />
      </span>
      <span class="settings-text"> Push notification settings </span>
    </a>

    <!--
        <div class="disabled-setting-in-web-version-for-now settings-item">
            <span class="settings-img-wrap settings-gray">
                <img class="light-icon settings-img"
                    src="/assets/ic_set_notification.png">
            </span>
            <span class="settings-text">
                Push notifications
            </span>
        </div>

        <div class="disabled-setting-in-web-version-for-now settings-item">
            <span class="settings-img-wrap settings-yellow1">
                <img class="light-icon settings-img"
                    src="/assets/ic_set_notification.png">
            </span>
            <span class="settings-text">
                In-app notifications
            </span>
        </div>
        -->

    <h2 class="sub-title">Feed</h2>

    <a
      class="subtle-button width-100 settings-item"
      routerLink="feed"
      href="feed"
      role="link"
      aria-label="Open feed settings"
    >
      <span class="settings-img-wrap settings-orange">
        <img
          class="light-icon settings-img"
          src="/assets/nav_home.png"
          alt="feed"
        />
      </span>
      <span class="settings-text"> Feed settings </span>
    </a>

    <a
      class="subtle-button width-100 settings-item"
      routerLink="blockedusers"
      href="blockedusers"
      role="link"
      aria-label="Open blocked users settings"
    >
      <span class="settings-img-wrap settings-red">
        <img
          class="light-icon settings-img"
          src="/assets/ic_alert7.png"
          alt="blocked"
        />
      </span>
      <span class="settings-text"> Manage blocked users </span>
    </a>

    <a
      class="subtle-button width-100 settings-item"
      routerLink="mutedusers"
      href="mutedusers"
      role="link"
      aria-label="Open muted users settings"
    >
      <span class="settings-img-wrap settings-red-like">
        <img
          class="light-icon settings-img"
          src="/assets/bottomsheet_ic_block.png"
          alt="muted"
        />
      </span>
      <span class="settings-text"> Manage muted users </span>
    </a>

    <h2 class="sub-title">Donations and subscriptions</h2>

    <a
      class="subtle-button width-100 settings-item"
      routerLink="/donate"
      href="/donate"
      role="link"
      aria-label="Make a donation"
    >
      <span class="settings-img-wrap settings-green2">
        <img
          class="light-icon settings-img"
          src="/assets/ic_set_notification2.png"
          alt="thumbs up icon"
        />
      </span>
      <span class="settings-text"> Donate </span>
    </a>

    <a
      class="subtle-button width-100 settings-item"
      routerLink="subscriptions"
      href="subscriptions"
      role="link"
      aria-label="Manage paid subscriptions"
    >
      <span class="settings-img-wrap settings-green1">
        <img
          class="light-icon settings-img"
          src="/assets/ic_clock.png"
          alt="thumbs up icon"
        />
      </span>
      <span class="settings-text"> Manage paid subscriptions </span>
    </a>

    <h2 class="sub-title">Other</h2>

    <a
      *ngIf="isPWA"
      class="subtle-button width-100 settings-item"
      routerLink="startingpage"
      href="startingpage"
      role="link"
      aria-label="Open starting page settings"
    >
      <span class="settings-img-wrap settings-blue4">
        <img
          class="light-icon settings-img"
          src="/assets/ic_pin.png"
          alt="starting page pin icon"
        />
      </span>
      <span class="settings-text"> Starting page </span>
    </a>

    <button class="subtle-button width-100 settings-item" (click)="helpClick()">
      <span class="settings-img-wrap settings-gray2">
        <img
          class="light-icon settings-img"
          src="/assets/ic_help.png"
          alt="help"
        />
      </span>
      <span class="settings-text"> Help </span>
    </button>

    <button
      class="subtle-button width-100 settings-item"
      (click)="askForLogout()"
      *ngIf="isLoggedIn"
    >
      <span class="settings-img-wrap settings-black">
        <img
          class="light-icon settings-img"
          src="/assets/ic_set_logout.png"
          alt="log out"
        />
      </span>
      <span class="settings-text"> Log out </span>
    </button>

    <a
      class="subtle-button width-100 settings-item"
      routerLink="/login"
      href="login"
      role="link"
      aria-label="Go to login"
      *ngIf="!isLoggedIn"
    >
      <span class="settings-img-wrap settings-black">
        <img
          class="light-icon settings-img"
          src="/assets/ic_set_avatar.png"
          alt="login"
        />
      </span>
      <span class="settings-text"> Sign in </span>
    </a>

    <div id="branding-wrapper">
      <img
        id="branding-logo"
        src="/assets/applogo_transparent.png"
        alt="{{ StrHlp.getAppName() }} logo"
      />
      <p id="branding-text">
        {{ StrHlp.getAppName() }} Web {{ StrHlp.getVersionString() }}
      </p>

      <a class="below-branding-link" routerLink="/changelog">Version history</a>
      <a class="below-branding-link" routerLink="/statistics">Statistics</a>
      <a class="below-branding-link" routerLink="/milestones">Milestones</a>
      <a class="below-branding-link" routerLink="/about">About us</a>
      <a class="below-branding-link" routerLink="/imprint">Imprint</a>
      <a class="below-branding-link" routerLink="/privacy">Privacy</a>
      <a class="below-branding-link" routerLink="/terms">Terms</a>
    </div>
  </main>
</div>
