<div id="wrapper">
  <header class="header" *ngIf="!considerZoomedIn!; hideHeader">
    <div id="side-button-bar">
      <div class="side-button">
        <img
          src="/assets/arrow_back.png"
          class="side-button-img"
          mat-dialog-close
          alt="close"
        />
      </div>
    </div>

    <div class="text-header" *ngIf="title || desc">
      <h2 class="title text-line-in-header" *ngIf="title">{{ title }}</h2>
      <p class="desc text-line-in-header" *ngIf="desc">{{ desc }}</p>
    </div>
  </header>

  <div
    id="image-container"
    #image
    (swipedown)="onSwipeDown()"
    (swipeup)="onSwipeUp()"
  >
    <iframe
      *ngIf="videoID"
      [src]="videoPlayerUrl"
      class="iframe-video-player"
      loading="lazy"
      allow="accelerometer; gyroscope; autoplay; encrypted-media;"
      allowfullscreen="false"
    >
    </iframe>
  </div>
</div>
