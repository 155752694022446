import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { RouterModule } from '@angular/router'
import { NotificationdetailspreviewboxComponent } from '../../templates/notificationdetailspreviewbox/notificationdetailspreviewbox.component'
import { MaindataService } from 'src/app/shared/services/data/maindata.service'
import { UsernametemplateComponent } from '../../templates/username/usernametemplate.component'
import { TrendingdataService } from 'src/app/shared/services/data/trendingdata.service'
import { NumberCommaFormatPipe } from 'src/app/pipes/number-comma-format.pipe'
import { ImgScaleHelperPipe } from 'src/app/pipes/img-scale-helper.pipe'
import { MainSideNavigationComponent } from '../../navigation/main-side-navigation/main-side-navigation.component'
import { MainFooterNavigationComponent } from '../../navigation/main-footer-navigation/main-footer-navigation.component'
import { ContentRowComponent } from './content-row/content-row.component'
import { RightSideRowComponent } from './right-side-row/right-side-row.component'
import { SystemService } from 'src/app/shared/services/system/systemservice.service'

@Component({
  selector: 'app-main-row',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    NotificationdetailspreviewboxComponent,
    UsernametemplateComponent,
    NumberCommaFormatPipe,
    ImgScaleHelperPipe,
    MainSideNavigationComponent,
    MainFooterNavigationComponent,
    ContentRowComponent,
    RightSideRowComponent
  ],
  templateUrl: './main-row.component.html',
  styleUrl: './main-row.component.css'
})
export class MainRowComponent {
  constructor(
    public maindataService: MaindataService,
    public trendingdataService: TrendingdataService
  ) {}
}
