<div class="searchbar-container">
  <div
    class="search-container usual-grey-rounded-widget"
    [ngClass]="{ 'outter-border': true }"
  >
    <input
      #inputBar
      class="inputSearch usual-grey-rounded-widget"
      type="text"
      placeholder="{{ suggestedQuery ? suggestedQuery : hint }}"
      name="search"
      tabindex="{{ disableAutoFocus ? -1 : 0 }}"
      (keydown)="onEnter($event)"
      (focus)="onSearchFocus()"
      (focusout)="onSearchLoseFocus()"
      [(ngModel)]="searchQueryEntered"
      autocomplete="off"
    />

    <button
      class="subtle-button"
      aria-label="close"
      id="clearButton"
      *ngIf="searchQueryEntered !== ''"
      (click)="clearInput()"
    >
      <img
        src="/assets/ic_close_this.png"
        id="clearIcon"
        class="dark-icon"
        alt="close icon"
      />
    </button>

    <button
      class="subtle-button search-button-wrapper"
      aria-label="search"
      id="searchButton"
      #searchButton
      (click)="startSearch()"
    >
      <img
        src="/assets/ic_search_5.png"
        id="searchIcon"
        class="dark-icon"
        alt="search icon"
      />
    </button>
  </div>

  <div
    class="recent-wrapper"
    *ngIf="
      !disableRecentsHint && hasFocus && searchService.recentSearches.length > 0
    "
  >
    <div class="recent-header">
      <h3 class="recent-title">Recent</h3>
      <button class="subtle-button clear-button" (click)="onClearAll()">
        Clear all
      </button>
    </div>

    <div>
      <div
        *ngFor="let query of searchService.recentSearches; let i = index"
        class="recent-query-item"
        (click)="recentQueryClick(query)"
      >
        {{ query }}

        <button
          class="subtle-button delete-single-query-button"
          (click)="onRemoveQuery(i)"
        >
          x
        </button>
      </div>
    </div>
  </div>
</div>
