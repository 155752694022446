<mat-dialog-content class="wrapper" appBottomSheetDialogContainer>
  <div id="header">
    <p id="title">{{ pageTitle }}</p>

    <img
      id="cancel-button"
      class="dark-icon"
      src="/assets/ic_close_2.png"
      alt="close"
      mat-dialog-close
    />
  </div>

  <div class="content-wrapper">
    <div class="search-wrapper">
      <app-backbuttontemplate
        *ngIf="searchMode"
        [disableAction]="true"
        (click)="cancelSearch()"
      >
      </app-backbuttontemplate>

      <app-searchbar
        id="search-bar"
        [searchCallback]="searchCallback"
        [disableAutoFocus]="disableAutoFocus"
        [disableRecentsHint]="true"
      >
      </app-searchbar>
    </div>

    <div class="no-search-wrapper" *ngIf="!searchMode">
      <div *ngIf="chataDataService.chatList.length > 0">
        <div *ngFor="let item of chataDataService.chatList; let i = index">
          <app-chatitemtemplate
            class="item"
            mat-dialog-close
            [item]="{
              item: item,
              indexInList: i,
              hideMenuButton: true,
              allowLongTap: false,
              itemClickCallback: clickCallback
            }"
          >
          </app-chatitemtemplate>
        </div>
      </div>

      <app-loadingcircle
        *ngIf="
          !chataDataService.listEmpty && chataDataService.chatList.length == 0
        "
      >
      </app-loadingcircle>

      <app-emptyhinttemplate *ngIf="chataDataService.listEmpty">
      </app-emptyhinttemplate>
    </div>

    <div class="serch-wrapper" *ngIf="searchMode">
      <h2 class="search-results-title">Search results:</h2>

      <div *ngIf="searchResultList.length > 0">
        <div *ngFor="let item of searchResultList; let i = index">
          <app-chatitemtemplate
            class="item"
            mat-dialog-close
            [item]="{
              item: item,
              indexInList: i,
              hideMenuButton: true,
              allowLongTap: false,
              itemClickCallback: clickCallback
            }"
          >
          </app-chatitemtemplate>
        </div>
      </div>

      <app-emptyhinttemplate *ngIf="emptySearch"> </app-emptyhinttemplate>

      <app-loadingcircle *ngIf="searchResultList.length == 0">
      </app-loadingcircle>
    </div>
  </div>
</mat-dialog-content>
