@if (!authService.isEmailVerified) {
  <div id="verify-email-hint">You need to verify your email to use chats</div>
} @else {
  <div class="chats-page-wrapper full-screen-height">
    <header id="header">
      <div class="flexSpaceBetween header-part-1">
        <div class="flexAlignCenter">
          <h1 class="page-title" (click)="scrollTop()">
            {{ pageTitle }}
          </h1>
        </div>

        <div class="chats-options-wrapper">
          @if (isLoggedIn) {
            <button
              id="search-button"
              aria-label="search"
              class="subtle-button"
              (click)="searchButtonClick()"
              matTooltip="Search chat"
              [matTooltipShowDelay]="1000"
            >
              <img
                class="dark-icon settings-icon search-icon"
                src="/assets/ic_search_5.png"
                alt="search"
              />
            </button>
          }

          <a
            id="settings-wrapper"
            routerLink="/settings/chats"
            matTooltip="Chat settings"
            [matTooltipShowDelay]="1000"
          >
            <img
              class="dark-icon settings-icon"
              src="/assets/ic_settings2.png"
              alt="chat settings"
            />
          </a>

          <button
            class="subtle-button bottom-button"
            (click)="openCreateNewGroup()"
            aria-label="create group"
          >
            <img
              src="/assets/ic_group.png"
              class="light-icon bottom-button-icon"
              alt="Group"
            />
          </button>
        </div>
      </div>

      <app-chat-choosing-bar
        id="feed-choosing-bar"
        [selectedMode]="selectedMode"
        [onSelectChatsMode]="onSelectChatsMode_AsParam"
        [onSelectGroupsMode]="onSelectGroupsMode_AsParam"
      />

      <app-usual-divider />
    </header>

    <div class="below-header-wrapper below-header-height">
      @if (selectedMode == 0 && chataDataService.chatList.length > 0) {
        <div
          id="listWrapper"
          (swiperight)="onSwipeRight()"
          (swipeleft)="onSwipeLeft()"
        >
          <cdk-virtual-scroll-viewport
            itemSize="75"
            class="below-header-height"
            #scrollContainerChats
          >
            <app-chatitemtemplate
              *cdkVirtualFor="
                let item of chataDataService.chatList;
                let i = index
              "
              class=""
              [item]="{
                item: item,
                indexInList: i,
                hideMenuButton: isMobile,
                allowLongTap: isMobile,
                itemClickCallback: openChatCallback,
                pinChatCallback: pinChatCallback,
                unpinChatCallback: unpinChatCallback
              }"
            />
          </cdk-virtual-scroll-viewport>

          @if (
            !chataDataService.listEmpty && chataDataService.chatList.length == 0
          ) {
            <div id="loading-indicator-wrapper">
              <app-loadingcircle />
            </div>
          }
        </div>
      }

      @if (selectedMode == 1 && groupdataService.itemList.length > 0) {
        <div
          id="listWrapper"
          (swiperight)="onSwipeRight()"
          (swipeleft)="onSwipeLeft()"
        >
          <div #scrollContainerGroups class="below-header-height y-scrollable">
            @for (
              item of groupdataService.itemList;
              track item.chatId;
              let i = $index
            ) {
              <app-groupitemtemplate
                [item]="{
                  item: item,
                  indexInList: i
                }"
              />
            }
          </div>

          @if (
            !groupdataService.empty && groupdataService.itemList.length == 0
          ) {
            <div id="loading-indicator-wrapper">
              <app-loadingcircle />
            </div>
          }
        </div>
      }

      <!--
            <div class="bottom-buttons-wrapper">
                <button 
                    class="subtle-button bottom-button" 
                    (click)="openCreateNewGroup()"
                    aria-label="create group"
                >
                    <img 
                        src="/assets/ic_group.png"
                        class="light-icon bottom-button-icon"
                        alt="Group"
                    >
                </button>
            </div>
            -->
    </div>

    <!--
            NOTE: 
            Due to SSR SEO we for now removed the virtual scrolling
            Since that is not shipped as HTML from the server.

        @if (selectedMode == 1 && groupdataService.itemList.length > 0) {
            <div 
                id="listWrapper"
                class="below-header-wrapper below-header-height"
                (swiperight)="onSwipeRight()"
                (swipeleft)="onSwipeLeft()"
            >
                <app-usual-divider />
                
                <cdk-virtual-scroll-viewport 
                    itemSize="75" 
                    class="below-header-height"
                    #virtualScrollViewport2
                >
                    <app-groupitemtemplate
                        *cdkVirtualFor="let item of groupdataService.itemList; let i = index"
                        [item]="{
                            item: item,
                            indexInList: i
                        }"
                    />
                </cdk-virtual-scroll-viewport>
            
                @if (!groupdataService.empty && groupdataService.itemList.length == 0) {
                    <div id="loading-indicator-wrapper">
                        <app-loadingcircle />
                    </div>
                }
            </div>
        }
        -->
  </div>
}
