<div class="container" appBottomSheetDialogContainer>
  <div class="report-header">
    <p id="title">Report</p>

    <img
      class="dark-icon"
      id="cancel-button"
      src="/assets/ic_close_2.png"
      alt="close"
      mat-dialog-close
    />
  </div>

  <div class="report-item" mat-dialog-close (click)="askMakeReport(1)">
    <span class="settings-img-wrap settings-gray">
      <img
        class="light-icon settings-img"
        src="/assets/ic_nsfw.png"
        alt="nsfw"
      />
    </span>
    <span class="settings-text"> Nudity or sexual content </span>
  </div>

  <div class="report-item" mat-dialog-close (click)="askMakeReport(2)">
    <span class="settings-img-wrap settings-black">
      <img
        class="light-icon settings-img"
        src="/assets/bottomsheet_ic_block.png"
        alt="illegal"
      />
    </span>
    <span class="settings-text"> Illegal activites or content </span>
  </div>

  <div class="report-item" mat-dialog-close (click)="askMakeReport(3)">
    <span class="settings-img-wrap settings-orange">
      <img
        class="light-icon settings-img"
        src="/assets/ic_spam_alert.png"
        alt="scam"
      />
    </span>
    <span class="settings-text"> Scam, fraud or spam </span>
  </div>

  <div class="report-item" mat-dialog-close (click)="askMakeReport(4)">
    <span class="settings-img-wrap settings-red">
      <img
        class="light-icon settings-img"
        src="/assets/ic_gun.png"
        alt="violence"
      />
    </span>
    <span class="settings-text"> Violence </span>
  </div>

  <div class="report-item" mat-dialog-close (click)="askMakeReport(5)">
    <span class="settings-img-wrap settings-lila">
      <img
        class="light-icon settings-img"
        src="/assets/ic_alert7.png"
        alt="abuse"
      />
    </span>
    <span class="settings-text"> Child abuse </span>
  </div>

  <div class="report-item" mat-dialog-close (click)="openLegalIssueReporting()">
    <span class="settings-img-wrap settings-blue2">
      <img
        class="light-icon settings-img"
        src="/assets/ic_copyright.png"
        alt="legal"
      />
    </span>
    <span class="settings-text"> Infringes my rights </span>
  </div>

  <div class="report-item" mat-dialog-close (click)="askMakeReport(6)">
    <span class="settings-img-wrap settings-blue1">
      <img
        class="light-icon settings-img"
        src="/assets/menu_waagerecht.png"
        alt="other"
      />
    </span>
    <span class="settings-text"> Other </span>
  </div>

  <div id="reviewed-hint">
    Reports are reviewed by our staff 24 hours a day, 7 days a week.
  </div>
</div>
