import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { HTMLFormattingService } from 'src/app/shared/services/formatting/html/htmlformatting.service'
import { NumberFormatService } from 'src/app/shared/services/formatting/number/numberformat.service'
import { RoutinghelperService } from 'src/app/shared/services/router/routinghelper.service'
import { TimeLimitsService } from 'src/app/shared/services/timelimits/timelimits.service'
import { PostshareclickComponent } from '../../dialogs/postshareclick/postshareclick.component'
import { MatDialog } from '@angular/material/dialog'
import { FullscreenService } from 'src/app/shared/image/fullscreen.service'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { EncodingService } from 'src/app/shared/services/encoding/encoding.service'
import { CommentsDialogComponent } from '../../comments-dialog/comments-dialog.component'
import { ImageLoadingService } from 'src/app/shared/services/imageloading/imageloading.service'
import { FeeddataService } from 'src/app/shared/services/data/feeddata.service'
import { InputdialogService } from 'src/app/shared/services/dialogs/inputdialog.service'
import { OnedialogserviceService } from 'src/app/shared/services/dialogs/onedialogservice.service'
import { TwobuttonsdialogService } from 'src/app/shared/services/dialogs/twobuttonsdialogservice.service'
import { MuteUsersService } from 'src/app/shared/services/muteusers.service'
import { HotToastService } from '@ngneat/hot-toast'
import { LoadingDialogComponent } from '../../dialogs/loading-dialog/loading-dialog.component'
import { ReportComponent } from '../../report/report.component'
import { DownloadserviceService } from 'src/app/shared/services/media/downloadservice.service'
import { ThreebuttonsdialogService } from 'src/app/shared/services/dialogs/threebuttonsdialogservice.service'
import { SystemService } from 'src/app/shared/services/system/systemservice.service'
import { Router } from '@angular/router'
import { DomSanitizer } from '@angular/platform-browser'
import { DatasharingService } from 'src/app/shared/services/data/datasharing.service'
import { CacheService } from 'src/app/shared/services/caching/cache-service.service'
import { ScreenOverlay } from 'src/app/shared/datatypes/screen-overlay'
import { RootStateService } from 'src/app/shared/services/state/root-state.service'
import { take } from 'rxjs'
import { SetTimeoutService } from 'src/app/shared/services/ssr/set-timeout.service'
import { SITE_PROTOCOL } from 'src/app/shared/constants'
import { IsBrowserService } from 'src/app/shared/services/ssr/isbrowser.service'
import { PostActionsService } from 'src/app/shared/services/actions/post-actions.service'
import { prntError, prnt } from 'src/app/shared/helper/Logger'

// Used to be: "/assets/ic_heart_like_filled.png"
const heartLikeAssetPath = '/assets/heart_for_like.png'

type LikeActionFrontend = 'like' | 'unlike'
type LikeActionBackend = 'nothing' | 'like' | 'unlike'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'post-template',
  templateUrl: 'posttemplate.component.html',
  styleUrls: ['posttemplate.component.css', '../../feed/feed.component.css']
})
export class PosttemplateComponent {
  @Input()
  updateStateCallback?: (post: any) => void

  @ViewChild('mediaWrapper')
  mediaWrapper!: ElementRef

  private _post: any = null

  @Input()
  set post(value: any) {
    if (value.post) {
      this._post = value.post
    }

    this.indexInList = value.indexInList
    this.hideMuteButton = value.hideMuteButton
    this.isSinglePagePost = value.isSinglePagePost
    this.postEnv = value.postEnv
    this.startingTimeSecs = value.startingTimeSecs

    this.itemChanged()
  }

  get post(): any {
    return this._post
  }

  startingTimeSecs = 0

  // needed for recycling reasons
  itemChanged() {
    this.isAd = this.post.isAd

    if (!this.captionLinesCounted) {
      this.countLinesCaption()
    }

    if (this.post.vid) {
      this.thumbnail = `https://vz-97291f5c-63e.b-cdn.net/${this.post.vid}/thumbnail.jpg`
      this.vidDurationString = this.getDurationString(this.post.vidDuration)
    } else {
      this.thumbnail = ''
      this.vidDurationString = ''
    }

    this.containsMultipleMedia = this.post.image2

    this.setUpCaption()

    //this.cdRef.markForCheck();

    this.post.profileImage$ = this.cacheService.getProfileImage(
      this.post.userID
    )
  }

  setUpCaption() {
    this.captionInnerHTML = this.htmlFormattingService.applyTextFormatting(
      this.htmlFormattingService.applyAll(
        this.post.caption.replaceAll('\n', '')
      )
    )
  }

  containsMultipleMedia = false

  indexInList: number = 0
  hideMuteButton = false
  isSinglePagePost = false

  captionInnerHTML = ''

  /**
   * 0 = normal post
   * 1 = clips post
   */
  postEnv = 0

  isMobile = SystemService.isMobile()

  // ---

  likeInProgress: boolean = false
  bookmarkingInProgress: boolean = false
  shareInProgress: boolean = false

  // helper vars for distinction of single tap and double tap
  media_timer: any
  media_preventSimpleClick: boolean = false
  media_preventSimpleClick_Delay: number = 350

  lastTimeOutID_LikeAnimation: any = null
  lastMediaClick_TimerID: any = null

  userID: any = null

  isAd = false

  //@ViewChild('postImage') postImage!: ElementRef;

  @ViewChild('captionEl') captionEl!: ElementRef
  captionCollapsedLineCount: number = 15
  captionLineCount: number = -1
  captionExceedsLineCount: boolean = false
  captionCollapsed: boolean = true

  captionLinesCounted = false

  thumbnail = ''
  vidDurationString = ''

  // only for clips
  showPostControls = true // init show them

  haveYouLiked = false

  // clips
  @ViewChild('heart') heart!: ElementRef
  doubleTapVideoCallback: (event: MouseEvent) => void = (event: MouseEvent) => {
    this.addLikeOverlay(event)
    this.doLikeAction(true)
  }
  // clips
  shouldShowControlsType1: (b: boolean) => void = (b: boolean) => {
    this.showPostControls = b
  }

  onDoubleTap: (event: MouseEvent) => void = (event) => {
    this.mediaDoubleClick(event)
  }

  onSingleTap: (event: MouseEvent, imageNr: number) => void = (
    event,
    imageNr: number = 1
  ) => {
    this.mediaSingleClick(imageNr)
  }

  markForCD() {
    this.cdRef.markForCheck()
  }

  constructor(
    private elementRef: ElementRef,
    public routingHelper: RoutinghelperService,
    public htmlFormattingService: HTMLFormattingService,
    public numberFormatService: NumberFormatService,
    public dialog: MatDialog,
    public encodingService: EncodingService,
    public authService: AuthService,
    public fullscreenHelper: FullscreenService,
    public imgHlp: ImageLoadingService,
    public feedDataService: FeeddataService,
    private inputDialogService: InputdialogService,
    private oneButtonDialogService: OnedialogserviceService,
    private twobuttonsdialogService: TwobuttonsdialogService,
    private muteUsersService: MuteUsersService,
    private toast: HotToastService,
    private downloadService: DownloadserviceService,
    private threebuttonsdialogService: ThreebuttonsdialogService,
    public strHlp: StrHlp,
    private router: Router,
    public sanitizer: DomSanitizer,
    public dataService: DatasharingService,
    private cacheService: CacheService,
    private rootState: RootStateService,
    private cdRef: ChangeDetectorRef,
    private setTimeoutService: SetTimeoutService,
    private isBrowserService: IsBrowserService,
    private postActionService: PostActionsService
  ) {
    this.userID = AuthService.getUID()
  }

  ngAfterViewInit() {
    //if (this.postImage) {
    //ZoomhelperService.setUpPinchToZoom(this.postImage.nativeElement, false, true);
    //}

    if (!this.captionLinesCounted) {
      this.countLinesCaption()
    }
  }

  // not best code, should be abstract (the if else thing)
  updateState() {
    if (this.updateStateCallback) {
      this.updateStateCallback(this.post)
    } else {
      this.feedDataService.postList[this.indexInList] = this.post

      // in service too
      this.feedDataService.updatePost(
        this.indexInList,
        this.post,
        this.feedDataService.feedType
      )
    }
  }

  // todo: make it a pipe
  getDurationString(durationInSecs: number) {
    const duration = durationInSecs * 1000

    const seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

    if (hours > 0) {
      return `${hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`
    } else {
      return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`
    }
  }

  pinPost() {
    if (this.isAd) {
      this.onActionRejectedBecauseItsAd()
      return
    }

    this.twobuttonsdialogService.show(
      'Pin post',
      'Pinned posts will appear at the top of your profile before all other posts.',
      () => {
        const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
          disableClose: true
        })

        this.postActionService
          .unpinPost(this.post)
          .then(() => {
            this.toast.success('Unpinned')
          })
          .catch((e) => {
            prntError(e)
            this.oneButtonDialogService.show('Error occurred', e.message)
          })
          .finally(() => {
            loadingDialogRef.close()
          })
      },
      () => {
        const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
          disableClose: true
        })

        this.postActionService
          .pinPost(this.post)
          .then(() => {
            this.toast.success('Pinned')
          })
          .catch((e) => {
            prntError(e)
            this.oneButtonDialogService.show('Error occurred', e.message)
          })
          .finally(() => {
            loadingDialogRef.close()
          })
      },
      'Do not pin',
      'Pin post'
    )
  }

  markAsNSFW() {
    this.twobuttonsdialogService.show(
      'Mark as NSFW',
      'Do you want to mark this post as NSFW? If it contains nudity it must be marked as NSFW and will get deleted otherwise.',
      () => {},
      () => {
        // call cloud function
        const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
          disableClose: true
        })

        this.postActionService
          .markAsNsfw(this.post)
          .then(() => {
            this.toast.success('Post marked as NSFW')
          })
          .catch((e) => {
            prntError(e)
            this.oneButtonDialogService.show('Failed', e.message)
          })
          .finally(() => {
            loadingDialogRef.close()
          })
      },
      'Cancel',
      'Yes'
    )
  }

  openProfile() {
    this.routingHelper.user(this.post.username, this.post.userID)
  }

  reportPost(postID: string): void {
    if (this.isAd) {
      this.onActionRejectedBecauseItsAd()
      return
    }

    if (!this.authService.isLoggedIn()) {
      this.authService.showLoginDialog()
      return
    }

    const data = {
      reportType: 0,
      reportID: postID
    }

    this.dialog.open(ReportComponent, {
      panelClass: 'bottom-sheet-dialog',
      maxWidth: '100vw',
      autoFocus: false,
      data: data
    })
  }

  startEditingPost(post: any) {
    if (this.isAd) {
      this.onActionRejectedBecauseItsAd()
      return
    }

    const callbackAction = (text: string) => {
      const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
        disableClose: true
      })

      this.postActionService
        .editPost(this.post, text)
        .then(() => {
          this.toast.success('Post edited')
        })
        .catch((e) => {
          prntError(e)
          this.oneButtonDialogService.show('Error occurred', e.message)
        })
        .finally(() => {
          loadingDialogRef.close()
        })
    }

    this.inputDialogService.openDialog(
      post.caption,
      'Save',
      'Cancel',
      3500,
      callbackAction,
      null,
      'Editing the caption will not change the hashtags if the post has any.',
      'Edit post'
    )
  }

  downloadImage(url: string) {
    this.downloadService.downloadImage(url, `${StrHlp.APP_NAME}_post`)
  }

  async downloadVideo(vidID: string) {
    this.cacheService
      .getVerified(this.userID)
      .pipe(take(1))
      .subscribe((isPrem) => {
        if (isPrem) {
          const url = `https://vz-97291f5c-63e.b-cdn.net/${vidID}/play_720p.mp4`
          this.toast.loading('Downloading...', { duration: 999_999_999 })
          this.downloadService.downloadVideo(url, `${StrHlp.APP_NAME}_video`)
        } else {
          // user must be premium to do this
          // inform user
          this.twobuttonsdialogService.show(
            'Download video',
            'You need Premium to download videos.',
            () => {},
            () => {
              this.router.navigate(['settings/premium'])
            },
            'Cancel',
            'Learn more...'
          )
        }
      })
  }

  deletePost(): void {
    if (!this.isBrowserService.isBrowser()) {
      return
    }

    if (this.isAd) {
      this.onActionRejectedBecauseItsAd()
      return
    }

    // user must confirm
    this.twobuttonsdialogService.show(
      'Delete post',
      'Do you want to delete this post? This cannot be undone.',
      () => {},
      () => {
        const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
          disableClose: true
        })

        this.postActionService
          .deletePost(this.post)
          .then(() => {
            this.toast.success('Deleted')
          })
          .catch((e) => {
            this.toast.error('Failed')
            prntError(e)
          })
          .finally(() => {
            loadingDialogRef.close()
          })
      },
      'Cancel',
      'Delete'
    )
  }

  async copyLinkPost(
    postID: string,
    toastSuccessText: string = 'Copied to clipboard'
  ) {
    if (!this.isBrowserService.isBrowser()) {
      return
    }

    if (this.isAd) {
      this.onActionRejectedBecauseItsAd()
      return
    }

    const postIdEncoded = this.encodingService.encodeForUrlArgument(postID)

    let link
    if (this.postEnv == 0) {
      link = `${SITE_PROTOCOL}://${StrHlp.APP_URL}/p/${postIdEncoded}`
    } else {
      link = `${SITE_PROTOCOL}://${StrHlp.APP_URL}/clip/${postIdEncoded}`
    }

    try {
      await navigator.clipboard.writeText(link)
      this.toast.success(toastSuccessText)
    } catch (err) {
      console.error('Failed to copy: ', err)
    }
  }

  async copyText(text: string) {
    if (!this.isBrowserService.isBrowser()) {
      return
    }
    try {
      await navigator.clipboard.writeText(text)
      this.toast.success('Copied to clipboard')
    } catch (err) {
      console.error('Failed to copy: ', err)
    }
  }

  onActionRejectedBecauseItsAd() {
    this.toast.show('Not available for this post')
  }

  openComments() {
    if (!this.isBrowserService.isBrowser()) {
      return
    }

    if (this.isAd) {
      this.onActionRejectedBecauseItsAd()
      return
    }

    if (!this.authService.isLoggedIn()) {
      // show login dialog
      this.authService.showLoginDialog()
      return
    }

    const data = {
      post: this.post,
      isReplies: false,
      originalComment: null
    }

    let maxWidth = '500px'
    let maxHeight = '80vh'

    if (this.isMobile) {
      maxWidth = '100vw'
      maxHeight = '70vh'
    }

    this.dialog.open(CommentsDialogComponent, {
      height: '100%',
      maxHeight: maxHeight,
      width: '100%',
      maxWidth: maxWidth,
      autoFocus: false,
      data: data,
      panelClass: 'bottom-sheet-dialog'
    })
  }

  doBookmarkAction(): void {
    if (this.isAd) {
      this.onActionRejectedBecauseItsAd()
      return
    }

    if (!this.authService.isLoggedIn()) {
      this.authService.showLoginDialog()
      return
    }

    if (!TimeLimitsService.isAllowed_Session('give-bookmark', 200)) {
      return
    }

    if (!this.bookmarkingInProgress) {
      this.bookmarkingInProgress = true

      // needed
      if (typeof this.post.bookmarkCount === 'undefined') {
        this.post.bookmarkCount = 0
      }

      // cloud
      let isBookmark
      if (this.post.interaction_isBookmarked) {
        this.post.bookmarkCount--
        this.post.interaction_isBookmarked = false
        isBookmark = false
      } else {
        this.post.bookmarkCount++
        this.post.interaction_isBookmarked = true
        isBookmark = true
      }

      this.updateState()

      if (isBookmark) {
        this.postActionService
          .bookmarkPost(this.post)
          .catch((e) => {
            prntError(e)
          })
          .finally(() => {
            this.bookmarkingInProgress = false
          })
      } else {
        this.postActionService
          .unbookmarkPost(this.post)
          .catch((e) => {
            prntError(e)
          })
          .finally(() => {
            this.bookmarkingInProgress = false
          })
      }
    }
  }

  /**
   *
   * @param imageNr if the post has multiple image, this must be the imageNr. starting with 1!!
   * @returns
   */
  mediaSingleClick(imageNr: number = 1): void {
    if (
      this.post.actionURL &&
      this.post.actionURL !== null &&
      this.post.actionURL !== ''
    ) {
      this.openAdLink()
      return
    }

    if (!this.isMobile) {
      this.openFullscreen(imageNr)
    }
  }

  openFullscreen(imageNr: number = 1) {
    if (!this.isBrowserService.isBrowser()) {
      return
    }

    let path: string = ''

    if (imageNr == 1) {
      path = this.post.imagePath
    } else if (imageNr == 2) {
      path = this.post.image2
    } else if (imageNr == 3) {
      path = this.post.image3
    } else if (imageNr == 4) {
      path = this.post.image4
    } else if (imageNr == 5) {
      path = this.post.image5
    }
    prnt('onClick path:', path)

    if (path) {
      this.cacheService
        .getUsername(this.post.userID)
        .pipe(take(1))
        .subscribe({
          next: (username) => {
            this.fullscreenHelper.open(
              this.imgHlp.do(path, 1100),
              '',
              `@${username}`,
              this.post.caption
            )
          },
          error: (e) => {
            prntError(e)

            this.fullscreenHelper.open(
              this.imgHlp.do(path, 1100),
              '',
              '',
              this.post.caption
            )
          }
        })
    } else {
      this.toast.error('Error occurred')
    }
  }

  mediaDoubleClick(event: MouseEvent): void {
    this.media_preventSimpleClick = true
    this.setTimeoutService.clearTimeout(this.media_timer)

    this.doLikeAction(true)

    this.addLikeOverlay(event)
  }

  /**
   * like action (like/unlike) will be figured out automatically
   */
  doLikeAction(isDoubleTap: boolean): void {
    if (this.isAd) {
      this.onActionRejectedBecauseItsAd()
      return
    }

    if (!this.authService.isLoggedIn()) {
      this.authService.showLoginDialog()
      return
    }

    if (!TimeLimitsService.isAllowed_Session('give-like', 200)) {
      return
    }

    const currIsLiked = this.post.interaction_isLiked

    // determine action
    // frontend action means like, haptic feedback, ...
    // backend means actually updating the DB
    let actionBackend: LikeActionBackend = 'nothing'

    if (currIsLiked) {
      if (isDoubleTap) {
        actionBackend = 'nothing'
      } else {
        actionBackend = 'unlike'
      }
    } else {
      actionBackend = 'like'
    }

    // backend
    if (actionBackend !== 'nothing') {
      this.likeInProgress = true

      if (actionBackend == 'like') {
        this.post.interaction_isLiked = true
        this.post.likeCount++

        SystemService.hapticsImpactMedium()
        this.updateState()

        this.postActionService.likePost(this.post).finally(() => {
          this.likeInProgress = false
        })
      } else if (actionBackend == 'unlike') {
        this.post.interaction_isLiked = false
        this.post.likeCount--

        this.updateState()

        this.postActionService.unlikePost(this.post).finally(() => {
          this.likeInProgress = false
        })
      }
    }
  }

  sharePost() {
    if (this.isAd) {
      this.onActionRejectedBecauseItsAd()
      return
    }

    this.openShareDialog(this.post)

    if (!this.authService.isLoggedIn()) {
      return
    }

    if (!TimeLimitsService.isAllowed_Session('give-share', 300)) {
      return
    }

    // backend
    if (!this.shareInProgress) {
      this.shareInProgress = true

      this.postActionService
        .sharePost(this.post)
        .catch((error) => {
          prntError(error)
        })
        .finally(() => {
          this.shareInProgress = false
        })
    }
  }

  openShareDialog(post: any) {
    if (!this.isBrowserService.isBrowser()) {
      return
    }

    if (this.isAd) {
      this.onActionRejectedBecauseItsAd()
      return
    }

    let currVideoTimestamp = 0

    if (post.vid) {
      // Find video element
      const videoElement = this.elementRef.nativeElement.querySelector('video')

      if (videoElement) {
        // Get the current time of the video and round it down
        currVideoTimestamp = Math.floor(videoElement.currentTime ?? 0)
      }
    }

    this.dialog.open(PostshareclickComponent, {
      panelClass: 'bottom-sheet-dialog',
      maxWidth: '100vw',
      data: {
        post: post,
        postEnv: this.postEnv,
        currVideoTimestamp: currVideoTimestamp
      }
    })
  }

  removePostsFromMutedUsers() {
    for (let i = this.feedDataService.postList.length - 1; i >= 0; i--) {
      if (
        this.muteUsersService.isMuted(this.feedDataService.postList[i].userID)
      ) {
        // remove
        this.feedDataService.postList.splice(i, 1)
      }
    }
  }

  showMuteDialog(userID: string) {
    if (this.isAd) {
      this.onActionRejectedBecauseItsAd()
      return
    }

    const mute24h = () => {
      this.muteUsersService.muteUser_24hours(userID)
      this.removePostsFromMutedUsers()
    }
    const mutePerma = () => {
      this.muteUsersService.muteUser(userID)
      this.removePostsFromMutedUsers()
    }

    this.threebuttonsdialogService.show(
      'Mute user',
      "Do you want to mute this user? You will no longer see any activity of this user. You will no longer see messages of this user in chat rooms. \n\nNote that this user can stil write private messages to you. If you don't want that, you need to block the user instead.",
      mute24h,
      mutePerma,
      () => {},
      'Mute user for 24 hours',
      'Mure user permanently',
      'Cancel'
    )
  }

  countLinesCaption() {
    if (!this.isBrowserService.isBrowser()) {
      return
    }

    if (this.captionEl) {
      this.captionLinesCounted = true

      this.captionLineCount =
        this.captionEl.nativeElement.getClientRects().length

      this.captionExceedsLineCount =
        this.captionLineCount > this.captionCollapsedLineCount
    }
  }

  openAdLink() {
    if (!this.isBrowserService.isBrowser()) {
      return
    }

    if (this.post.isSystemAd) {
      this.router.navigateByUrl(this.post.actionURL)
    } else {
      window.open(this.post.actionURL, '_blank')
    }
  }

  openVideoPlayer() {
    // OLD
    //this.openVideoPlayerService.show(this.post.vid);

    // NEW:
    // Open in clips
    //const data = {
    //post: this.post
    //};
    //this.router.navigate(['/clip/'], { state: data });

    this.router.navigate(['/clip/' + this.post.postID])
  }

  addLikeOverlay(event: MouseEvent) {
    this.rootState.addOverlay(
      new ScreenOverlay(heartLikeAssetPath, event.x, event.y, 2000)
    )
  }
}
