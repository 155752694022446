<div id="wrapper">
  <h1>Statistics {{ strHlp.getAppName() }}</h1>
  <p>
    This page displays a limited set of statistics from
    {{ strHlp.getAppName() }}. It is important to acknowledge that this is not
    an exhaustive representation.
  </p>

  <p>Last updated on: 03/10/2023</p>

  <hr class="inline-divider" />
  <div class="version">
    <h2>Website</h2>
    <p>Released on: 02/01/2023</p>
    <ul>
      <!--
                Important note:

                HOW TO CALCULATE:

                Since we have a SPA, we need to implement the counting process. Before it only counted
                **page-views** and no navigation then at all. Screen views were added after some time only.
                They do account for all clicks, not the page views from before.

                Page views before: 50,000
                Estimated screen views from that: 250,000 (50,000 * 5)

                Hence the formula is:
                Total-Screenviews = Measured-Screenviews + 250,000
            -->
      <li><b>Total screen views:</b> 900.000</li>
      <li><b>Monthly screen views:</b> 85.000</li>
      <li><b>Views from USA:</b> 65%</li>
      <!--<li><b>Total users:</b> 5.000</li>-->
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version">
    <h2>Android App</h2>
    <p>Released on: 01/10/2021</p>
    <ul>
      <li><b>Total screen views:</b> 123.000.000</li>
      <li><b>Monthly screen views:</b> 850.000</li>
      <li><b>Screen views from USA:</b> 60%</li>
      <li><b>Total users:</b> 1.100.000</li>
      <li><b>Monthly users:</b> 50.000</li>
    </ul>
  </div>

  <hr class="inline-divider" />
  <div class="version">
    <h2>iOS App</h2>
    <p>Released on: -not yet-</p>
    <ul>
      <li>-empty-</li>
    </ul>
  </div>

  <hr class="inline-divider" />
</div>
