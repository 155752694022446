import { Injectable } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { StrHlp } from '../StringGetter/getstring.service'
import { combineLatest, take } from 'rxjs'
import { CacheService } from '../caching/cache-service.service'
import { Location } from '@angular/common'
import { ImageLoadingService } from '../imageloading/imageloading.service'
import { SITE_PROTOCOL } from '../../constants'

@Injectable({
  providedIn: 'root'
})
export class SeoHelperService {
  constructor(
    private meta: Meta,
    private titleService: Title,
    private cacheService: CacheService,
    private imgHlp: ImageLoadingService
  ) {}

  setProfile(
    bio: string,
    fullname: string,
    username: string,
    imageInput: string
  ) {
    const img = this.imgHlp.do(imageInput, 250)

    const pageTitle = `${fullname} (@${username}) | ${StrHlp.APP_NAME}`
    const desc = `${fullname} (@${username}) on ${StrHlp.APP_NAME} | ${bio} | Newest images and videos from ${fullname} (@${username}) | ${StrHlp.APP_NAME}`

    const siteURL = this.getSiteURL()
    const canonical = `${siteURL}/@${username}`

    this.set_General(pageTitle, desc, canonical, img)
  }

  setPost(
    postID: string,
    imageURL: string,
    videoID: string,
    caption: string,
    name: string,
    fullname: string
  ) {
    let postType = 'Post'
    let isVideo = false

    if (videoID) {
      isVideo = true
      postType = 'Video'
    } else if (imageURL) {
      postType = 'Image'
    }

    if (!caption) {
      caption = postType
    }

    let pageTitle
    let desc

    if (isVideo) {
      pageTitle = `${postType} by ${fullname} (@${name}) | The TikTok of ${StrHlp.COMMUNITY_NAME} | ${StrHlp.APP_NAME}`
      desc = `${caption} | Find short videos on the TikTok of ${StrHlp.COMMUNITY_NAME}. Clip by ${fullname} (@${name}) | ${StrHlp.APP_NAME}`
    } else {
      pageTitle = `${postType} by ${fullname} (@${name}) | ${StrHlp.APP_NAME}`
      desc = `${caption} | by ${fullname} (@${name}) | ${StrHlp.APP_NAME}`
    }

    const siteURL = this.getSiteURL()

    const canonical = isVideo
      ? `${siteURL}/p/${postID}`
      : `${siteURL}/clip/${postID}`

    let img = ''

    let thumbnailURL = ''
    if (isVideo) {
      thumbnailURL = `https://vz-97291f5c-63e.b-cdn.net/${videoID}/thumbnail.jpg` // TODO: moduralize this
    }

    if (thumbnailURL) {
      img = thumbnailURL
    } else if (imageURL) {
      img = imageURL
    } else {
      img = `${siteURL}/assets/favico.png`
    }

    this.set_General(pageTitle, desc, canonical, img)
  }

  setHashtag(tag: string, numberPosts: number) {
    const pageTitle = `${tag} videos and images | ${StrHlp.APP_NAME}`
    const desc = `Find the best ${tag} videos, images and memes. ${numberPosts} posts. Find content from the ${StrHlp.COMMUNITY_NAME} on ${StrHlp.APP_NAME} | Hashtag ${tag}`

    const siteURL = this.getSiteURL()
    const canonical = `${siteURL}/tag/${tag}`

    const img = `${siteURL}/assets/favico.png`

    this.set_General(pageTitle, desc, canonical, img)
  }

  setGroupPage(groupID: string, bio: string, name: string, image: string) {
    const pageTitle = `${name} chat room | ${StrHlp.APP_NAME}`
    const desc = `${bio} | ${name} chat group on ${StrHlp.APP_NAME}`

    const siteURL = this.getSiteURL()
    const canonical = `${siteURL}/group/${groupID}`

    this.set_General(pageTitle, desc, canonical, image)
  }

  setLocation(location: string, numberPosts: number) {
    const pageTitle = `${location} location | ${StrHlp.APP_NAME}`
    const desc = `${location} images and videos. ${numberPosts} posts. Find content from the ${StrHlp.COMMUNITY_NAME} on ${StrHlp.APP_NAME} | Location ${location}`

    const siteURL = this.getSiteURL()
    const canonical = `${siteURL}/location/${location}`

    const img = `${siteURL}/assets/favico.png`

    this.set_General(pageTitle, desc, canonical, img)
  }

  private getSiteURL() {
    //return document.location.origin;
    return `${SITE_PROTOCOL}://${StrHlp.APP_URL}`
  }

  private setError() {
    const pageTitle = `${StrHlp.APP_NAME}`
    const desc = `Explore ${StrHlp.COMMUNITY_NAME} content on ${StrHlp.APP_NAME}`

    const siteURL = this.getSiteURL()
    const img = `${siteURL}/assets/favico.png`

    this.set_General(pageTitle, desc, '', img)
  }

  getGenericDesc() {
    return `Explore the ${StrHlp.COMMUNITY_NAME} community and discover trending videos. Find the best ${StrHlp.COMMUNITY_NAME} images and videos`
  }

  /**
   * Set generic page. It will add things like ".... | SocialHub" to the end.
   */
  setForSomePage(
    pageTitle: string,
    pageDescription: string,
    imageURL: string = '',
    canonical: string = '',
    dontAppendToTitle: boolean = false,
    dontAppendToDesc: boolean = false
  ) {
    if (imageURL === '') {
      const siteURL = this.getSiteURL()
      imageURL = `${siteURL}/assets/favico.png`
    }

    const title = dontAppendToTitle
      ? pageTitle
      : `${pageTitle} | ${StrHlp.APP_NAME}`
    const description = dontAppendToDesc
      ? pageDescription
      : `${pageDescription} | ${StrHlp.APP_NAME}`

    this.set_General(title, description, canonical, imageURL)
  }

  getTitleForClips(isNSFW: boolean) {
    if (StrHlp.CLOUD_PATH === 'ANIME' && isNSFW) {
      return `Hentai TikTok`
    } else if (StrHlp.CLOUD_PATH === 'TESTHUB') {
      return `Trump TikTok`
    } else {
      return `${StrHlp.COMMUNITY_NAME} TikTok`
    }
  }

  setClips(isNSFW: boolean) {
    if (StrHlp.CLOUD_PATH === 'ANIME') {
      if (isNSFW) {
        this.setForSomePage(
          `${this.getTitleForClips(isNSFW)} - Adult Hentai Videos`,
          `Best Hentai videos. The TikTok for adult hentai and manga, discover trending short videos. NSFW Hentai TikTok. Swipe for the next clip | Hentai TikTok`,
          '',
          '',
          true,
          true
        )
      } else {
        this.setClipsNormally()
      }
    } else if (StrHlp.CLOUD_PATH === 'TESTHUB') {
      this.setForSomePage(
        `${this.getTitleForClips(isNSFW)} - Trending ${StrHlp.COMMUNITY_NAME} Videos`,
        `Conservative TikTok. The best Trump TikTok videos, discover trending short videos now. Swipe for the next clip`,
        '',
        '',
        true
      )
    } else {
      this.setClipsNormally()
    }
  }

  private setClipsNormally() {
    this.setForSomePage(
      `${this.getTitleForClips(false)} - Trending ${StrHlp.COMMUNITY_NAME} Videos`,
      `Best videos of the ${StrHlp.COMMUNITY_NAME} community. The TikTok for the ${StrHlp.COMMUNITY_NAME} community, discover trending short videos now. Swipe for the next clip.`,
      '',
      '',
      true
    )
  }

  /**
   *
   * @param pageTitle
   * @param description
   * @param canonical
   * @param imageURL
   *
   */
  private set_General(
    pageTitle: string,
    description: string,
    canonical: string,
    imageURL: string
  ) {
    this.setTitle(pageTitle)
    this.setDesc(description)
    this.setCanonical(canonical)

    this.setGoogleNoTranslate()
    this.setPreventFormatDetection()

    this.setTwitter(imageURL, pageTitle, description, imageURL)

    this.setOpenGraph(pageTitle, description, imageURL, canonical)
  }

  private setTitle(s: string) {
    if (s) {
      // we dont allow linebreaks in here
      s = s.replace(/\n/g, ' ')

      this.titleService.setTitle(s)
    }
  }

  /**
   * Include a meta description that summarizes the content of your page. This often appears in search engine results.
   */
  private setDesc(s: string) {
    if (s) {
      // we dont allow linebreaks in here
      s = s.replace(/\n/g, ' ')

      this.meta.updateTag({
        name: 'description',
        content: s
      })
    }
  }

  /**
   * Set the canonical URL to avoid duplicate content issues and specify the preferred version of the page.
   */
  private setCanonical(s: string) {
    if (s) {
      this.meta.updateTag({
        rel: 'canonical',
        href: s
      })
    }
  }

  /**
   * Useful?
   *
   * Set the language of the content
   */
  private setLanguage(s: string) {
    this.meta.addTag({
      'http-equiv': 'Content-Language',
      content: 'en'
    })
  }
  /**
   * Useful?
   *
   * Allow robot to crawl and follow links
   */
  private setRobots() {
    this.meta.addTag({ name: 'robots', content: 'index, follow' })
  }

  /**
   *
   * Improve how your content appears when shared on social media platforms.
   *
   * Missing: image width, image height
   *
   * @param title page title
   * @param description page description
   * @param imageURL URL to my image
   * @param url 'https://yourdomain.com/your-page'
   * @param type 'website'
   */
  private setOpenGraph(
    title: string,
    description: string,
    imageURL: string,
    url: string,
    type = 'website',
    site_name = StrHlp.APP_NAME
  ) {
    if (title) {
      this.meta.updateTag({
        name: 'og:title',
        content: title
      })
    }

    if (description) {
      // we dont allow linebreaks in here
      description = description.replace(/\n/g, ' ')

      this.meta.updateTag({
        name: 'og:description',
        content: description
      })
    }

    if (imageURL) {
      this.meta.updateTag({
        name: 'og:image',
        content: imageURL
      })

      if (title) {
        this.meta.updateTag({
          name: 'og:image:alt',
          content: title
        })
      }
    }

    if (url) {
      this.meta.updateTag({
        name: 'og:url',
        content: url
      })
    }

    if (type) {
      this.meta.updateTag({
        name: 'og:type',
        content: type
      })
    }

    if (site_name) {
      this.meta.updateTag({
        name: 'og:site_name',
        content: site_name
      })
    }
  }

  /**
   * Improve how your content appears when shared on Twitter.
   *
   * @param card summary large image url
   * @param title page title
   * @param description page description
   * @param image url to my image
   */
  private setTwitter(
    card: string,
    title: string,
    description: string,
    image: string
  ) {
    if (card) {
      this.meta.updateTag({
        name: 'twitter:card',
        content: card
      })
    }

    if (title) {
      this.meta.updateTag({
        name: 'twitter:title',
        content: title
      })
    }

    if (description) {
      // we dont allow linebreaks in here
      description = description.replace(/\n/g, ' ')

      this.meta.updateTag({
        name: 'twitter:description',
        content: description
      })
    }

    if (image) {
      this.meta.updateTag({
        name: 'twitter:image',
        content: image
      })
    }
  }

  private setGoogleNoTranslate() {
    this.meta.addTag({
      name: 'google',
      content: 'notranslate'
    })
  }

  /**
   * Used to prevent automatic detection and formatting of phone numbers as links on mobile devices. When this tag is included with the telephone=no content attribute, it instructs the browser not to convert phone numbers into clickable links.
   */
  private setPreventFormatDetection() {
    this.meta.addTag({
      name: 'format-detection',
      content: 'telephone=no'
    })
  }
}
