<mat-dialog-content
  #wrapper
  id="wrapper"
  appBottomSheetDialogContainer
  [@dialog]="state"
>
  <app-commentpagetemplate
    #commentpage
    [isReplies]="isReplies"
    [originalComment]="originalComment"
    [post]="post"
    [openKeyboardAlready]="openKeyboardAlready"
  />
</mat-dialog-content>
