<footer class="gif-picker-class fh-page-wrapper wrapper">
  <header class="gif-picker-class fh-header bottom-border-divider header">
    <div class="gif-picker-class nav-bar">
      <button
        class="gif-picker-class subtle-button"
        aria-label="search tab"
        (click)="switchToSearchTab()"
      >
        <img
          class="gif-picker-class dark-icon nav-icon"
          [ngClass]="{ 'nav-selected-button': dataService.currTab == 0 }"
          src="/assets/ic_search_5.png"
          alt="search"
        />
      </button>

      <button
        class="gif-picker-class subtle-button"
        aria-label="recent tab"
        (click)="switchToRecentTab()"
      >
        <img
          class="gif-picker-class dark-icon nav-icon"
          [ngClass]="{ 'nav-selected-button': dataService.currTab == 1 }"
          src="/assets/ic_clock.png"
          alt="curerent tab"
        />
      </button>

      <button
        class="gif-picker-class subtle-button"
        aria-label="favorites tab"
        (click)="switchToFavoritesTab()"
      >
        <img
          class="gif-picker-class dark-icon nav-icon"
          [ngClass]="{ 'nav-selected-button': dataService.currTab == 2 }"
          src="/assets/ic_star.png"
          alt="favorites tab"
        />
      </button>
    </div>

    <div
      class="gif-picker-class search-wrapper"
      *ngIf="dataService.currTab == 0"
    >
      <app-backbuttontemplate
        class="gif-picker-class"
        *ngIf="searchMode"
        [disableAction]="true"
        (click)="cancelSearch()"
      />

      <app-searchbar
        class="gif-picker-class"
        id="search-bar"
        [hint]="'Search GIF on Tenor'"
        [searchCallback]="searchButtonCallback"
        [disableRecentsHint]="true"
        [hasBorder]="true"
      />
    </div>
  </header>

  <main
    class="gif-picker-class fh-main content"
    (swiperight)="onSwipeRight()"
    (swipeleft)="onSwipeLeft()"
  >
    <div
      class="gif-picker-class search-results"
      *ngIf="dataService.currTab == 0 && searchMode"
    >
      <div class="gif-picker-class gifs-container">
        <div
          class="gif-picker-class gif-result-item"
          *ngFor="let item of dataService.searchResults"
        >
          <img
            class="gif-picker-class gif-preview"
            #gifImg
            alt="GIF preview"
            (click)="onGifClick(item, gifImg)"
            (longTap)="showDialog_AddToFavorites_Item(item)"
            src="{{ item['media_formats']['nanogif']['url'] }}"
          />
        </div>
      </div>

      @if (emptySearch) {
        <app-emptyhinttemplate class="gif-picker-class" />
      }
    </div>

    <div
      class="gif-picker-class features-gifs"
      *ngIf="dataService.currTab == 0 && !searchMode"
    >
      <div class="gif-picker-class gifs-container">
        <div
          class="gif-picker-class gif-result-item"
          *ngFor="let item of dataService.featuredGIFs"
        >
          <img
            class="gif-picker-class gif-preview"
            #gifImg
            alt="GIF preview"
            (click)="onGifClick(item, gifImg)"
            (longTap)="showDialog_AddToFavorites_Item(item)"
            src="{{ item['media_formats']['nanogif']['url'] }}"
          />
        </div>
      </div>

      <app-emptyhinttemplate class="gif-picker-class" *ngIf="emptyRecommended">
      </app-emptyhinttemplate>
    </div>

    <div
      class="gif-picker-class features-gifs"
      *ngIf="dataService.currTab == 1"
    >
      <h2>Recently used</h2>

      <div class="gif-picker-class gifs-container">
        <div
          class="gif-picker-class gif-result-item"
          *ngFor="let gifURL of dataService.recentGIFs"
        >
          <img
            class="gif-picker-class gif-preview"
            #gifImg
            alt="GIF preview"
            (click)="
              handleGifClick_wo_AspectRatio(gifURL, gifURL, null, gifImg)
            "
            (longTap)="showDialog_AddToFavorites(gifURL)"
            src="{{ gifURL }}"
          />
        </div>
      </div>

      <app-emptyhinttemplate
        class="gif-picker-class"
        *ngIf="dataService.emptyRecent"
      >
      </app-emptyhinttemplate>
    </div>

    <div
      class="gif-picker-class features-gifs"
      *ngIf="dataService.currTab == 2"
    >
      <h2>Favorites</h2>

      <div class="gif-picker-class gifs-container">
        <div
          class="gif-picker-class gif-result-item"
          *ngFor="let gifURL of dataService.favorites"
        >
          <img
            class="gif-picker-class gif-preview"
            #gifImg
            alt="GIF preview"
            (click)="
              handleGifClick_wo_AspectRatio(gifURL, gifURL, null, gifImg)
            "
            (longTap)="showDialog_RemoveFromFavorites(gifURL)"
            src="{{ gifURL }}"
          />
        </div>
      </div>

      <app-emptyhinttemplate
        class="gif-picker-class"
        *ngIf="dataService.emptyFavorites"
        [text]="'Empty... Long tap a GIF to add it to your favorites!'"
      >
      </app-emptyhinttemplate>
    </div>

    <app-loadingcircle *ngIf="indicateLoading"></app-loadingcircle>

    <button
      class="gif-picker-class subtle-button load-more-button"
      (click)="loadMore()"
      *ngIf="!indicateLoading && dataService.currTab == 0"
    >
      Load more...
    </button>
  </main>
</footer>
