<div id="wrapper">
  <!--
    <div id="goback">
        <app-backbuttontemplate
            [showText]="true"
            [background]="true">
        </app-backbuttontemplate>
    </div>
    -->

  <h1>Install {{ title }}</h1>

  <div *ngIf="showAndroid">
    <hr class="inline-divider" />
    <div class="version normal-text">
      <h2 class="sub-title" *ngIf="!android_HideFirstTwo">Android</h2>
      <ol>
        <li *ngIf="!android_HideFirstTwo">
          Open Chrome
          <img
            class="mid-text-icon"
            src="/assets/ic_chrome_tiny.png"
            alt="Google chrome"
          />
        </li>
        <li *ngIf="!android_HideFirstTwo">Go to {{ url }}</li>
        <li>Tap the 3 dots at the top right</li>
        <img
          class="banner-hint"
          src="{{ chrome_bar_url | imgScaleHelper: 250 }}"
          alt="Chrome bar"
        />

        <li>Click "Install app" or "Add to home screen"</li>
      </ol>
    </div>
  </div>

  <div *ngIf="showIos">
    <hr class="inline-divider" />
    <div class="version normal-text">
      <h2 class="sub-title" *ngIf="!safari_HideFirstTwo">iOS</h2>
      <ol>
        <li *ngIf="!safari_HideFirstTwo">
          Open Safari
          <img
            class="mid-text-icon"
            src="/assets/ic_safari_tiny.png"
            alt="Safari"
          />
        </li>
        <li *ngIf="!safari_HideFirstTwo">Go to {{ url }}</li>
        <li>Tap the share button at the bottom middle</li>
        <img
          class="banner-hint"
          src="{{ safari_bar_url | imgScaleHelper: 250 }}"
          alt="Safari bar"
        />

        <li>Scroll down and click "Add to Home Screen"</li>
      </ol>
    </div>
  </div>

  <div *ngIf="showDesktop">
    <hr class="inline-divider" />
    <div class="version normal-text">
      <h2 class="sub-title">Desktop</h2>
      <p>
        Not all browser support Web Apps. Firefox for example does not. We
        recommend using Chrome or Edge.
      </p>
      <ol>
        <li>
          Open Chrome
          <img
            class="mid-text-icon"
            src="/assets/ic_chrome_tiny.png"
            alt="Google chrome"
          />
        </li>
        <li>Go to {{ url }}</li>
        <li>
          Tap the install button at the top right
          <img
            class="dark-icon mid-text-icon"
            src="/assets/outline_install_desktop_black_36dp.png"
            alt="install"
          />
        </li>
        <li>Click "Install"</li>
      </ol>
    </div>
  </div>

  <hr class="inline-divider" />

  <p class="normal-text">
    That's it! You will find the {{ strHlp.getAppName() }} icon on your phone's
    home screen.
  </p>

  <hr class="inline-divider" *ngIf="showBottomInfoText" />

  <p class="normal-text" *ngIf="showBottomInfoText">
    {{ strHlp.getAppName() }} is banned from the Google Play Store and Apple App
    Store because we stand for free speech. Our web app cannot be censored and
    is as functional as the original Android or iOS app.
  </p>

  <ng-container *appShowOnlyOnMobile>
    <ng-container *ngIf="!isLoggedIn">
      <hr class="inline-divider" />

      <a
        class="subtle-button open-settings-button usual-border-radius"
        role="link"
        aria-label="Open settings"
        href="/settings"
        routerLink="/settings"
      >
        Open settings
      </a>

      <a
        class="subtle-button open-settings-button usual-border-radius"
        role="link"
        aria-label="Sign in"
        href="/login"
        routerLink="/login"
      >
        Sign in
      </a>
    </ng-container>
  </ng-container>
</div>
