<!-- Normal post -->
<article class="normal-post-wrapper" *ngIf="postEnv == 0">
  <div class="postTopWrapper">
    <div class="postTopWrapperSub1">
      <a
        class="subtle-button"
        routerLink="/user/{{ post.userID }}"
        role="link"
        attr.aria-label="Open profile of user {{ post.username }}"
      >
        <img
          [src]="post.profileImage$ | async | imgScaleHelper: 50"
          alt="{{ post.username }} photo"
          class="profile-image userImage"
          onerror="src='/assets/default_profile_pic.jpg';onerror='';"
          loading="lazy"
        />
      </a>

      <div>
        <app-usernametemplate [values]="{ userID: post.userID }" />

        <div class="postTopWrapperSub2">
          <div class="post-desc-text">
            @if (post.pinned) {
              <img
                src="/assets/pin_filled.png"
                class="dark-icon repostImg"
                alt="pin icon"
                loading="lazy"
              />
              <span class="bold">Pinned</span>
              <span class="simple_divider">•</span>
            }
          </div>

          <a
            class="subtle-button post-desc-text"
            *ngIf="post.rePostID && post.rePostID.length > 0"
            routerLink="/p/{{ post.rePostID }}"
            role="link"
            aria-label="Go to original post"
          >
            <img
              src="/assets/ic_repost.png"
              class="dark-icon repostImg"
              alt="repost icon"
              loading="lazy"
            />
            Reposted
          </a>

          <span class="simple_divider" *ngIf="post.rePostID">•</span>

          <a
            class="subtle-button post-desc-text"
            *ngIf="post.location"
            routerLink="/location/{{
              encodingService.encodeForUrlArgument(post.location)
            }}"
          >
            <img
              src="/assets/ic_location.png"
              class="dark-icon repostImg"
              alt="location icon"
              loading="lazy"
              role="link"
              attr.aria-label="Open location {{ post.location }}"
            />
            {{ post.location }}
          </a>

          <span class="simple_divider" *ngIf="post.location">•</span>

          <a
            class="subtle-button post-desc-text"
            routerLink="/p/{{ post.postID }}"
            role="link"
            attr.aria-label="Post on {{ strHlp.getAppName() }} from {{
              post.username
            }}"
            attr.aria-describedby="{{ post.caption }} on {{
              strHlp.getAppName()
            }} from user {{ post.username }}"
          >
            {{ post.timestamp | timeformat }}
          </a>

          <span class="simple_divider" *ngIf="containsMultipleMedia">•</span>
          <img
            src="/assets/ic_double_picture.png"
            *ngIf="containsMultipleMedia"
            class="dark-icon repostImg"
            alt="double image collection icon"
            loading="lazy"
          />

          <span class="simple_divider" *ngIf="post.wasEdited">•</span>
          <span class="post-desc-text" *ngIf="post.wasEdited">Edited</span>

          <span class="simple_divider" *ngIf="post.isAd">•</span>
          <span class="post-desc-text" *ngIf="post.isAd">Sponsored</span>
        </div>
      </div>
    </div>

    <div class="margin-left-auto">
      <button
        class="subtle-button post-action-wrapper menu-button"
        mat-button
        [matMenuTriggerFor]="menu"
        #menuTrigger
        aria-label="options"
        matTooltip="Options"
        [matTooltipShowDelay]="1000"
      >
        <img
          class="dark-icon post-options-button dimmed-icon-strong"
          src="/assets/menu.png"
          alt="options icon"
          loading="lazy"
        />
      </button>
    </div>

    <mat-menu
      class="standard-options-menu bottom-sheet-matmenu-wrapper"
      #menu="matMenu"
      backdropClass="black-backdrop-light"
    >
      <div appBottomSheetDialogContainer>
        <div
          class="standard-options-dialog-item"
          (click)="copyLinkPost(post.postID)"
        >
          <span class="options-img-wrap">
            <img
              class="dark-icon options-img"
              src="/assets/ic_set_copy.png"
              alt="copy icon"
              loading="lazy"
            />
          </span>
          <span class="options-text"> Copy link </span>
        </div>

        <div
          class="standard-options-dialog-item"
          (click)="pinPost()"
          *ngIf="post.userID === userID"
        >
          <span class="options-img-wrap">
            <img
              class="dark-icon options-img"
              src="/assets/ic_pin.png"
              alt="pin icon"
              loading="lazy"
            />
          </span>
          <span class="options-text"> Pin post </span>
        </div>

        <div
          class="standard-options-dialog-item"
          (click)="openFullscreen()"
          *ngIf="post.imagePath.length > 0"
        >
          <span class="options-img-wrap">
            <img
              class="dark-icon options-img"
              src="/assets/ic_expand.png"
              alt="expand icon"
              loading="lazy"
            />
          </span>
          <span class="options-text"> Fullscreen </span>
        </div>

        <div
          class="standard-options-dialog-item"
          (click)="downloadImage(imgHlp.do(post.imagePath, 1100))"
          *ngIf="post.imagePath.length > 0"
        >
          <span class="options-img-wrap">
            <img
              class="dark-icon options-img"
              src="/assets/ic_download_2.png"
              alt="download icon"
              loading="lazy"
            />
          </span>
          <span class="options-text"> Save image </span>
        </div>

        <div
          class="standard-options-dialog-item"
          (click)="downloadVideo(post.vid)"
          *ngIf="post.vid"
        >
          <span class="options-img-wrap">
            <img
              class="dark-icon options-img"
              src="/assets/ic_download_2.png"
              alt="download icon"
              loading="lazy"
            />
          </span>
          <span class="options-text"> Save video </span>
        </div>

        <div
          class="standard-options-dialog-item"
          (click)="copyText(post.caption)"
          *ngIf="post.caption"
        >
          <span class="options-img-wrap">
            <img
              class="dark-icon options-img"
              src="/assets/ic_copy.png"
              alt="copy icon"
              loading="lazy"
            />
          </span>
          <span class="options-text"> Copy text </span>
        </div>

        <div
          class="standard-options-dialog-item"
          (click)="startEditingPost(post)"
          *ngIf="post.userID === userID"
        >
          <span class="options-img-wrap">
            <img
              class="dark-icon options-img"
              src="/assets/ic_set_edit_cut.png"
              alt="edit icon"
              loading="lazy"
            />
          </span>
          <span class="options-text"> Edit post </span>
        </div>

        <div
          class="standard-options-dialog-item"
          (click)="deletePost()"
          *ngIf="post.userID === userID"
        >
          <span class="options-img-wrap">
            <img
              class="dark-icon options-img"
              src="/assets/ic_set_delete.png"
              alt="delete icon"
              loading="lazy"
            />
          </span>
          <span class="options-text"> Delete post </span>
        </div>

        <div
          class="standard-options-dialog-item"
          *ngIf="
            !hideMuteButton &&
            post.userID !== userID &&
            post.userID !== 'nwnqQ1LieaaofxvtAg9ojOBRIeD2'
          "
          (click)="showMuteDialog(post.userID)"
        >
          <span class="options-img-wrap">
            <img
              class="dark-icon options-img"
              src="/assets/bottomsheet_ic_block.png"
              alt="mute icon"
              loading="lazy"
            />
          </span>
          <span class="options-text"> Mute user </span>
        </div>

        <div
          class="standard-options-dialog-item"
          (click)="reportPost(post.postID)"
          *ngIf="post.userID !== userID"
        >
          <span class="options-img-wrap">
            <img
              class="dark-icon options-img"
              src="/assets/ic_set_bug.png"
              alt="report icon"
              loading="lazy"
            />
          </span>
          <span class="options-text"> Report </span>
        </div>

        <div
          class="standard-options-dialog-item alert-red-bg"
          (click)="markAsNSFW()"
          *ngIf="
            strHlp.getAllowsNSFW() &&
            (post.userID === userID || dataService.areYouMod)
          "
        >
          <span class="options-img-wrap">
            <img
              class="dark-icon options-img"
              src="/assets/ic_alert7.png"
              alt="report icon"
              loading="lazy"
            />
          </span>
          <span class="options-text"> Mark as NSFW </span>
        </div>

        <app-bottomsheet-bottom-branding-spacer *appShowOnlyOnMobile />
      </div>
    </mat-menu>
  </div>

  <div class="postCaption" *ngIf="post.caption">
    <span
      #captionEl
      class="allow-user-selection"
      [ngClass]="{
        postCaptionCollapsed: captionLineCount != -1 && captionCollapsed,
        postCaptionExpanded: captionLineCount != -1 && !captionCollapsed
      }"
      [innerHTML]="
        post
          ? htmlFormattingService.applyTextFormatting(
              htmlFormattingService.applyAll(post.caption)
            )
          : ''
      "
    >
    </span>

    <button
      class="subtle-button seeMoreButton"
      *ngIf="captionExceedsLineCount && captionCollapsed"
      (click)="captionCollapsed = false"
    >
      <b>See more...</b>
    </button>

    <app-donations-progress-bar
      *ngIf="post.isAd && post.isSystemAd && post.showMonthlyDonationProgress"
      [progressPercentage]="post.monthlyDonationProgressPercent"
      (click)="openAdLink()"
      [useAsButton]="true"
    >
    </app-donations-progress-bar>

    <button
      class="subtle-button ad-action-button"
      *ngIf="post.showButton"
      [ngClass]="{ 'red-ad-action-button': post.redButton }"
      (click)="openAdLink()"
    >
      {{ post.buttonText }}

      <img
        src="/assets/ic_arrow_right.png"
        class="light-icon learn-more-ad-icon"
        alt="learn more icon"
        loading="lazy"
      />
    </button>
  </div>

  <div
    *ngIf="!containsMultipleMedia && post.imagePath"
    class="mediaWrapper"
    id="mediaWrapper"
    #mediaWrapper
    (onSingleTap)="onSingleTap($event, 1)"
    (onDoubleTap)="onDoubleTap($event)"
  >
    <img
      #postImage
      height="{{ post.h ? post.h : 500 }}"
      width="{{ post.w ? post.w : 500 }}"
      alt="{{ post.username }} on {{ strHlp.getAppName() }}: {{ post.caption }}"
      class="postImage postImageActual"
      src="{{ post.imagePath | imgScaleHelper: 1100 }}"
      onerror="src='/assets/broken_file.png';onerror='';"
    />

    <!-- For double click like animation (heart on image) -->
    <div id="heart" class="pos-relative" *ngIf="post.imagePath"></div>
  </div>

  <div
    *ngIf="containsMultipleMedia && post.imagePath"
    class="mediaWrapper"
    #mediaWrapper
    id="mediaWrapper"
  >
    <swiper-container
      #swiperRef
      class="swiper grey-bg"
      pagination-bullet="true"
      pagination-fraction="true"
      [navigation]="!isMobile"
    >
      <!-- No lazy loading on first for better UX !! -->
      <swiper-slide
        *ngIf="post.imagePath"
        class="swiper-slide"
        (onSingleTap)="onSingleTap($event, 1)"
        (onDoubleTap)="onDoubleTap($event)"
      >
        <img
          height="{{ post.h ? post.h : 500 }}"
          width="{{ post.w ? post.w : 500 }}"
          alt="{{ post.username }} on {{ strHlp.getAppName() }}: {{
            post.caption
          }}"
          class="postImage postImageActual"
          src="{{ post.imagePath | imgScaleHelper: 1100 }}"
          onerror="src='/assets/broken_file.png';onerror='';"
        />
      </swiper-slide>

      <swiper-slide
        *ngIf="post.image2"
        lazy="true"
        class="swiper-slide"
        (onSingleTap)="onSingleTap($event, 2)"
        (onDoubleTap)="onDoubleTap($event)"
      >
        <img
          alt="{{ post.username }} on {{ strHlp.getAppName() }}: {{
            post.caption
          }} second image"
          class="postImage postImageActual"
          src="{{ post.image2 | imgScaleHelper: 1100 }}"
          onerror="src='/assets/broken_file.png';onerror='';"
          loading="lazy"
        />
      </swiper-slide>

      <swiper-slide
        *ngIf="post.image3"
        lazy="true"
        class="swiper-slide"
        (onSingleTap)="onSingleTap($event, 3)"
        (onDoubleTap)="onDoubleTap($event)"
      >
        <img
          alt="{{ post.username }} on {{ strHlp.getAppName() }}: {{
            post.caption
          }} third image"
          class="postImage postImageActual"
          src="{{ post.image3 | imgScaleHelper: 1100 }}"
          onerror="src='/assets/broken_file.png';onerror='';"
          loading="lazy"
        />
      </swiper-slide>

      <swiper-slide
        *ngIf="post.image4"
        lazy="true"
        class="swiper-slide"
        (onSingleTap)="onSingleTap($event, 4)"
        (onDoubleTap)="onDoubleTap($event)"
      >
        <img
          alt="{{ post.username }} on {{ strHlp.getAppName() }}: {{
            post.caption
          }} fourth image"
          class="postImage postImageActual"
          src="{{ post.image4 | imgScaleHelper: 1100 }}"
          onerror="src='/assets/broken_file.png';onerror='';"
          loading="lazy"
        />
      </swiper-slide>

      <swiper-slide
        *ngIf="post.image5"
        lazy="true"
        class="swiper-slide"
        (onSingleTap)="onSingleTap($event, 5)"
        (onDoubleTap)="onDoubleTap($event)"
      >
        <img
          alt="{{ post.username }} on {{ strHlp.getAppName() }}: {{
            post.caption
          }} fifth image"
          class="postImage postImageActual"
          src="{{ post.image5 | imgScaleHelper: 1100 }}"
          onerror="src='/assets/broken_file.png';onerror='';"
          loading="lazy"
        />
      </swiper-slide>
    </swiper-container>

    <!-- For double click like animation (heart on image) -->
    <div id="heart" class="pos-relative" *ngIf="post.imagePath"></div>
  </div>

  <!--
        video thumbnail
    -->
  <div class="imageContainer" *ngIf="post.vid">
    <div class="video-thumbnail-wrapper" (click)="openVideoPlayer()">
      <img
        class="thumbnail-image"
        #imgEl
        src="{{ thumbnail }}"
        onerror="this.src='/assets/video_colored.png';this.onerror='';"
        alt="video thumbnail"
        loading="lazy"
      />

      <div class="video-hint-play-icon-wrapper">
        <img
          src="assets/ic_play.png"
          alt="play video icon"
          class="light-icon video-hint-play-icon"
          loading="lazy"
        />
      </div>
    </div>

    <div class="timestamp-on-image">
      <img
        src="assets/ic_play.png"
        *ngIf="post.vidDuration"
        alt="play video icon"
        class="light-icon msg-bottom-bar-icon"
        loading="lazy"
      />
      <span class="msg-bottom-bar-text" *ngIf="post.vidDuration">{{
        vidDurationString
      }}</span>
    </div>
  </div>

  <div
    class="video-not-supported-hint"
    *ngIf="post.videoPath && post.videoPath.length > 0"
  >
    Video is no longer available since it was not posted with the web app
  </div>

  <div class="postBottomWrapper">
    <div class="postActionButtonsWrapper">
      <!-- Like button -->
      <button
        class="subtle-button post-action-wrapper"
        (click)="doLikeAction(false)"
        aria-label="Like"
        matTooltip="Like"
        [matTooltipShowDelay]="1000"
      >
        <img
          class="post-action-icon"
          [ngClass]="{ 'dark-icon dimmed-icon': !post.interaction_isLiked }"
          [src]="
            post.interaction_isLiked
              ? '/assets/ic_heart_like_filled.png'
              : '/assets/ic_heart_regular.png'
          "
          alt="Like"
          loading="lazy"
        />
        <div
          class="post-action-text"
          [ngClass]="{ 'liked-color': post.interaction_isLiked }"
        >
          {{ post.likeCount | numberCommaFormat }}
        </div>
      </button>

      <!-- Comment button -->
      <button
        class="subtle-button post-action-wrapper"
        (click)="openComments()"
        aria-label="Comments"
        matTooltip="Comments"
        [matTooltipShowDelay]="1000"
      >
        <img
          class="dark-icon post-action-icon dimmed-icon"
          src="/assets/ic_comment_2.png"
          alt="Comments"
          loading="lazy"
        />

        <div class="post-action-text">
          {{ post.commentCount | numberCommaFormat }}
        </div>
      </button>

      <!-- Bookmark button -->
      <button
        class="subtle-button post-action-wrapper"
        (click)="doBookmarkAction()"
        aria-label="Bookmark"
        matTooltip="Bookmark"
        [matTooltipShowDelay]="1000"
      >
        <img
          class="dark-icon post-action-icon"
          [ngClass]="{ 'dimmed-icon': !post.interaction_isBookmarked }"
          [src]="
            post.interaction_isBookmarked
              ? '/assets/ic_bookmark_filled.png'
              : '/assets/ic_bookmark_2.png'
          "
          alt="Bookmark"
          loading="lazy"
        />

        <div class="post-action-text">
          {{
            (post.bookmarkCount ? post.bookmarkCount : 0) | numberCommaFormat
          }}
        </div>
      </button>

      <!-- Share button -->
      <button
        class="subtle-button post-action-wrapper"
        (click)="sharePost()"
        aria-label="Share"
        matTooltip="Share"
        [matTooltipShowDelay]="1000"
      >
        <img
          class="dark-icon post-action-icon dimmed-icon"
          src="/assets/ic_share_send.png"
          alt="Share"
          loading="lazy"
        />
        <div class="post-action-text">
          {{ post.shareCount | numberCommaFormat }}
        </div>
      </button>
    </div>
  </div>
</article>

<!-- Clips post -->
<article class="clip-post-wrapper clip-item-height" *ngIf="postEnv == 1">
  <div
    class="clip-post-side-bar"
    [ngStyle]="{ visibility: showPostControls ? 'visible' : 'hidden' }"
  >
    <!-- Like button -->
    <button
      class="subtle-button clip-post-action-wrapper"
      (click)="doLikeAction(false)"
      aria-label="Like"
      matTooltip="Like"
      [matTooltipShowDelay]="1000"
    >
      <img
        class="light-action-icon clip-post-action-icon"
        [ngClass]="{ 'light-icon dimmed-icon': !post.interaction_isLiked }"
        [src]="
          post.interaction_isLiked
            ? '/assets/ic_heart_like_filled.png'
            : '/assets/ic_heart_like_filled_black.png'
        "
        alt="Like"
        loading="lazy"
      />

      <div class="post-action-text clip-post-action-text clips-text-shadow">
        {{ post.likeCount | numberCommaFormat }}
      </div>
    </button>

    <!-- Comment button -->
    <button
      class="subtle-button clip-post-action-wrapper"
      (click)="openComments()"
      aria-label="Comments"
      matTooltip="Comments"
      [matTooltipShowDelay]="1000"
    >
      <img
        class="light-icon dimmed-icon clip-post-action-icon"
        src="/assets/ic_comment2_filled.png"
        alt="Comments"
        loading="lazy"
      />

      <div class="post-action-text clip-post-action-text clips-text-shadow">
        {{ post.commentCount | numberCommaFormat }}
      </div>
    </button>

    <!-- Bookmark button -->
    <button
      class="subtle-button clip-post-action-wrapper"
      (click)="doBookmarkAction()"
      aria-label="Bookmark"
      matTooltip="Bookmark"
      [matTooltipShowDelay]="1000"
    >
      <img
        class="clip-post-action-icon"
        [ngClass]="{ 'light-icon': !post.interaction_isBookmarked }"
        src="{{
          post.interaction_isBookmarked
            ? '/assets/ic_bookmark_filled_blue.png'
            : '/assets/ic_bookmark_filled.png'
        }}"
        alt="Bookmark"
        loading="lazy"
      />

      <div class="post-action-text clip-post-action-text clips-text-shadow">
        {{ (post.bookmarkCount ? post.bookmarkCount : 0) | numberCommaFormat }}
      </div>
    </button>

    <!-- Share button -->
    <button
      class="subtle-button clip-post-action-wrapper"
      (click)="sharePost()"
      aria-label="Share"
      matTooltip="Share"
      [matTooltipShowDelay]="1000"
    >
      <img
        class="light-icon dimmed-icon clip-post-action-icon"
        src="/assets/ic_reply_filled.png"
        alt="Share"
        loading="lazy"
      />

      <div class="post-action-text clip-post-action-text clips-text-shadow">
        {{ post.shareCount | numberCommaFormat }}
      </div>
    </button>

    <!-- Options button -->
    <button
      class="subtle-button clip-post-action-wrapper"
      mat-button
      [matMenuTriggerFor]="menu"
      #menuTrigger
      aria-label="options"
      matTooltip="Options"
      [matTooltipShowDelay]="1000"
    >
      <img
        class="light-icon dimmed-icon clip-post-action-icon transform-90deg"
        src="/assets/menu.png"
        alt="menu icon three dots"
        loading="lazy"
      />
    </button>
  </div>

  <div
    class="clip-post-bottom-info"
    [ngStyle]="{ visibility: showPostControls ? 'visible' : 'hidden' }"
  >
    <a
      class="subtle-button"
      routerLink="/user/{{ post.userID }}"
      role="link"
      attr.aria-label="Open profile of user {{ post.username }}"
    >
      <img
        [src]="post.profileImage$ | async | imgScaleHelper: 50"
        alt="profile photo"
        class="profile-image userImage-clips"
        onerror="src='/assets/default_profile_pic.jpg';onerror='';"
        loading="lazy"
      />
    </a>

    <div class="clips-bottom-info-second-wrapper">
      <div class="clip-username-wrapper">
        <app-usernametemplate
          [values]="{
            userID: post.userID,
            whiteText: true,
            textShadow: true
          }"
          class="clips-text-shadow"
        />
      </div>

      @if (post.caption) {
        <div class="clip-postCaption">
          <span
            #captionEl
            class="allow-user-selection clips-postCaption clips-text-shadow"
            (click)="openComments()"
            [innerHTML]="captionInnerHTML"
          >
          </span>
        </div>
      }

      <mat-menu
        class="standard-options-menu bottom-sheet-matmenu-wrapper"
        #menu="matMenu"
        backdropClass="black-backdrop-light"
      >
        <div appBottomSheetDialogContainer>
          <div
            class="standard-options-dialog-item"
            (click)="copyLinkPost(post.postID)"
          >
            <span class="options-img-wrap">
              <img
                class="dark-icon options-img"
                src="/assets/ic_set_copy.png"
                alt="copy icon"
                loading="lazy"
              />
            </span>
            <span class="options-text"> Copy link </span>
          </div>

          <div
            class="standard-options-dialog-item"
            (click)="downloadVideo(post.vid)"
            *ngIf="post.vid"
          >
            <span class="options-img-wrap">
              <img
                class="dark-icon options-img"
                src="/assets/ic_download_2.png"
                alt="download icon"
                loading="lazy"
              />
            </span>
            <span class="options-text"> Save video </span>
          </div>

          <div
            class="standard-options-dialog-item"
            *ngIf="
              !hideMuteButton &&
              post.userID !== userID &&
              post.userID !== 'nwnqQ1LieaaofxvtAg9ojOBRIeD2'
            "
            (click)="showMuteDialog(post.userID)"
          >
            <span class="options-img-wrap">
              <img
                class="dark-icon options-img"
                src="/assets/bottomsheet_ic_block.png"
                alt="mute icon"
                loading="lazy"
              />
            </span>
            <span class="options-text"> Mute user </span>
          </div>

          <div
            class="standard-options-dialog-item"
            (click)="reportPost(post.postID)"
            *ngIf="post.userID !== userID"
          >
            <span class="options-img-wrap">
              <img
                class="dark-icon options-img"
                src="/assets/ic_set_bug.png"
                alt="report icon"
                loading="lazy"
              />
            </span>
            <span class="options-text"> Report </span>
          </div>

          <app-bottomsheet-bottom-branding-spacer *appShowOnlyOnMobile />
        </div>
      </mat-menu>
    </div>
  </div>

  <!-- Custom player for clips -->
  <app-clipplayer
    [videoID]="post.vid"
    [postID]="post.postID"
    [postID]="post.postID"
    [indexInList]="indexInList"
    [doubleTapCallback]="doubleTapVideoCallback"
    [shouldShowControlsType1]="shouldShowControlsType1"
    [startingTimeSecs]="startingTimeSecs"
  />

  <!-- For double click like animation (heart on image) -->
  <div id="heart" class="pos-absolute clip-video-heart" #heart></div>
</article>
