<div class="wrapper">
  <input
    id="addImageButton_Hidden"
    style="display: none"
    #addImageButton_Hidden
    type="file"
    name="medium"
    accept="{{ input_AcceptsString }}"
  />

  <div class="mediumPreviewWrapper">
    <div
      class="mediumPreview"
      *ngFor="let medium of mediaUploaded; let i = index"
    >
      <img
        class="mediumPreviewImage"
        alt="Upload image or video icon"
        src="{{ getMediumDisplayURL(medium) }}"
        (click)="openPreviewFullscreen(getMediumDisplayURL(medium))"
      />

      <div class="button-wrapper">
        <button
          class="subtle-button interaction-button"
          (click)="deleteMediumByIndex(i)"
        >
          Remove
        </button>
      </div>
    </div>
  </div>

  <button
    class="subtle-button uploadImageWrapper"
    *ngIf="mediaUploaded.length < maxAllowedMedia"
    #uploadImageWrapper
    (click)="openSelectImage()"
  >
    <img
      class="uploadImageIcon dark-icon"
      alt="Upload image or video icon"
      src="/assets/ic_upload.png"
    />

    <p class="uploadImageText">Upload {{ mediumName }}</p>
  </button>

  <div class="red-error-type-button-style imageRatioError" #ratioError>
    Image ratio not permitted. Please select a more square-shaped image.
  </div>
</div>
