import {
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core'
import { ReplaySubject, fromEvent, takeUntil } from 'rxjs'
import { SetTimeoutService } from '../shared/services/ssr/set-timeout.service'

/**
 * Used for single Tap and double Tap.
 */
@Directive({
  selector: '[onSingleTap]'
})
export class TapDetectDirective implements OnInit, OnDestroy {
  private timeout: any
  private tapCount = 0
  private destroySubject: ReplaySubject<void> = new ReplaySubject()

  @Output()
  onSingleTap = new EventEmitter()

  @Output()
  onDoubleTap = new EventEmitter()

  constructor(
    private host: ElementRef<HTMLElement>,
    private setTimeoutService: SetTimeoutService
  ) {}

  ngOnInit(): void {
    fromEvent(this.host.nativeElement, 'click')
      .pipe(takeUntil(this.destroySubject))
      .subscribe({
        next: (event: any) => {
          this.onClick(event)
        },
        error: console.error
      })
  }

  onClick(event: MouseEvent) {
    const singleTap_CheckingDelay = 250

    this.tapCount++

    if (this.tapCount == 1) {
      // check via timeout that no next tap is coming soon enough
      // then it would be a double tap
      this.timeout = this.setTimeoutService.setTimeout(() => {
        if (this.tapCount == 1) {
          if (this.onSingleTap) {
            this.onSingleTap.emit(event)
          }
        }
        this.tapCount = 0
      }, singleTap_CheckingDelay)
    } else {
      // means: double tap
      if (this.onDoubleTap) {
        this.onDoubleTap.emit(event)
      }

      // reset tap count
      this.tapCount = 0

      if (this.timeout) {
        this.setTimeoutService.clearTimeout(this.timeout)
      }
    }
  }

  ngOnDestroy(): void {
    this.destroySubject.next()
    this.destroySubject.complete()
  }
}
