<div class="wrapper">
  <div id="header-comments" class="universal-padding">
    <div id="header-left-wrapper">
      <app-backbuttontemplate
        *ngIf="isReplies"
        [disableAction]="true"
        mat-dialog-close
      >
      </app-backbuttontemplate>

      <h2>
        <strong>{{ titleString }}</strong>
      </h2>

      <div
        class="count-showing-div-wrapper count-wrapper"
        *ngIf="
          (!isReplies && commentCount > 0) || (isReplies && repliesCount > 0)
        "
      >
        <div class="count-showing-div-text">
          {{
            isReplies
              ? repliesCount > 0
                ? repliesCount
                : ''
              : commentCount > 0
                ? commentCount
                : ''
          }}
        </div>
      </div>
    </div>

    <app-closebutton
      *ngIf="showCancelButtonAtTop"
      [historyBack]="false"
      mat-dialog-close
    >
    </app-closebutton>
  </div>

  <div class="comment-content-wrapper universal-padding">
    <div id="contentPreview" *ngIf="contentPreviewString !== ''">
      <!--
            <div id="contentPreviewTitle">
                {{contentPreviewTitle}}
            </div>
            -->

      <div
        id="contentPreviewText"
        [innerHTML]="
          post ? htmlFormattingService.applyAll(contentPreviewString) : ''
        "
      ></div>
    </div>

    <div id="listWrapper" *ngFor="let comment of itemList; let i = index">
      <app-commenttemplate
        [comment]="comment"
        [itemList]="itemList"
        [isReplies]="isReplies"
        [originalCommentID]="originalCommentID"
        [post]="post"
        [replyClickCallback]="replyClickCallback"
        [indexInList]="i"
      >
      </app-commenttemplate>
    </div>

    <app-loadingcircle *ngIf="!empty_comments && itemList.length == 0">
    </app-loadingcircle>

    <app-emptyhinttemplate *ngIf="empty_comments" [text]="emptyHintString">
    </app-emptyhinttemplate>
  </div>

  <div
    class="addCommentSection universal-padding"
    *ngIf="authService.isLoggedIn()"
  >
    <hr class="inline-divider" />

    <div class="input-wrapper" *ngIf="!areYouBlocked">
      <img
        class="dark-icon side-button"
        id="emojiButton"
        (click)="emojiButtonClick()"
        src="{{
          showEmojiPicker
            ? '/assets/ic_keyboard.png'
            : '/assets/ic_emoji_button.png'
        }}"
        alt="pick emoji icon"
      />

      <textarea
        #textareainput
        placeholder="{{ inputPlaceHolder }}"
        maxlength="1000"
        [(ngModel)]="commentEntered"
        [formControl]="textareaControl"
        (keydown.enter)="onEnter()"
      >
      </textarea>

      <div
        id="gifButtonWrapper"
        *ngIf="commentEntered.trim() == ''"
        (click)="gifButtonClick()"
      >
        <img
          class="dark-icon side-button"
          id="gifButtonImage"
          src="{{
            showGifsPicker ? '/assets/ic_keyboard.png' : '/assets/gif.png'
          }}"
          alt="pick gif icon"
        />
      </div>

      <div
        id="gifButtonWrapper"
        *ngIf="commentEntered.trim()"
        (click)="postComment()"
      >
        <img
          class="dark-icon side-button"
          id="gifButtonImage"
          src="/assets/ic_send_message.png"
          alt="send message icon"
        />
      </div>
    </div>

    <hr class="inline-divider" />

    <!--
        <div id="buttonsWrapper" *ngIf="(areYouBlocked_Determined && !areYouBlocked) && commentEntered.length > 0">
            <button class="subtle-button actionButton" 
                    id="cancelButton" 
                    (click)="cancelEntering()">
                Cancel
            </button>
            <button class="subtle-button actionButton"
                    id="commentButton"
                    (click)="postComment()">
                Comment
            </button>
        </div>
        -->

    <p *ngIf="areYouBlocked" id="blockedHint">
      You cannot comment because you are blocked by this user
    </p>
  </div>
</div>
