<div
  class="comment"
  (onSingleTap)="onSingleTap($event)"
  (onDoubleTap)="onDoubleTap($event)"
>
  <img
    src="{{
      comment.profilePhoto
        ? (comment.profilePhoto | imgScaleHelper: 50)
        : '/assets/default_profile_pic.jpg'
    }}"
    alt="{{ comment.username }} photo"
    onerror="this.src='/assets/default_profile_pic.jpg';this.onerror='';"
    class="profile-image userImage_comments"
    mat-dialog-close
    (click)="routingHelper.user(comment.username, comment.userID)"
    loading="lazy"
  />

  <div class="postTopWrapperSub1_Comments">
    <div class="postTopWrapperSub2_Comments">
      <app-usernametemplate
        mat-dialog-close
        [values]="{
          userID: comment.userID,
          smallType1: true,
          greyBackground: comment.userID == post.userID
        }"
      />

      <div class="simple_divider">•</div>
      <div class="timestamp">{{ comment.timestamp | timeformat }}</div>
    </div>

    <div class="commentText allow-user-selection">
      <div
        [ngClass]="{
          commentTextCollapsed: !comment.expanded,
          commentTextExpanded: comment.expanded
        }"
        [innerHTML]="
          comment
            ? htmlFormattingService.applyTextFormatting(
                htmlFormattingService.applyAll(comment.text)
              )
            : ''
        "
        *ngIf="comment.text !== ''"
      ></div>
      <div
        class="deletedHintText"
        *ngIf="
          comment.text === '' && (!comment.gifURL || comment.gifURL === '')
        "
      >
        This comment was deleted
      </div>
    </div>

    <button
      class="subtle-button seeMoreButton"
      *ngIf="comment.text.length > 400 && !comment.expanded"
      (click)="comment.expanded = true"
    >
      <b>See more...</b>
    </button>

    <img
      *ngIf="comment.gifURL && comment.gifURL !== ''"
      src="{{ comment.gifURL }}"
      alt="GIF"
      onerror="this.src='/assets/broken_file.png';this.onerror='';"
      (click)="openImageFullscreen($event)"
      class="comment-gif"
      loading="lazy"
    />

    <div class="interactionWrapper-outer">
      <div class="interactionWrapper">
        <div
          class="postTopWrapperSub2 action-button"
          (click)="likeComment(indexInList)"
          matTooltip="Like"
          [matTooltipShowDelay]="1000"
        >
          <img
            class="dark-icon vote-icon"
            src="{{
              comment.liked
                ? '/assets/navbar_like_filled.png'
                : '/assets/navbar_like_unfilled.png'
            }}"
            alt="give like icon"
            loading="lazy"
          />

          <span class="vote-count">
            {{
              !comment.likeCount || comment.likeCount < 0
                ? 0
                : comment.likeCount
            }}
          </span>
        </div>

        <div
          class="postTopWrapperSub2 action-button"
          (click)="dislikeComment(indexInList)"
          matTooltip="Dislike"
          [matTooltipShowDelay]="1000"
        >
          <img
            class="dark-icon vote-icon downvote-icon"
            src="{{
              comment.disliked
                ? '/assets/navbar_like_filled.png'
                : '/assets/navbar_like_unfilled.png'
            }}"
            alt="give dislike icon"
            loading="lazy"
          />

          <span class="vote-count">
            {{
              !comment.dislikeCount || comment.dislikeCount < 0
                ? 0
                : comment.dislikeCount
            }}
          </span>
        </div>
      </div>

      <div class="reply-button" (click)="replyButtonClick(comment)">Reply</div>

      <div class="liked-by-creator" *ngIf="comment.likedByCr">
        Liked by creator
      </div>
    </div>

    <div
      class="view-replies-hint"
      *ngIf="comment.replyCount > 0"
      (click)="openRepliesDialog(comment)"
    >
      <span
        >View {{ comment.replyCount }}
        {{ comment.replyCount == 1 ? 'reply' : 'replies' }}</span
      >

      <img
        src="/assets/ic_arrow_right.png"
        class="dark-icon view-replies-hint-icon"
        alt="view replies icon"
        loading="lazy"
      />
    </div>
  </div>

  <img
    class="dark-icon options-button"
    src="/assets/menu.png"
    alt="options icon"
    mat-button
    [matMenuTriggerFor]="menu"
    #menuTrigger
    loading="lazy"
    matTooltip="Options"
    [matTooltipShowDelay]="1000"
  />

  <mat-menu
    class="standard-options-menu bottom-sheet-matmenu-wrapper"
    #menu="matMenu"
    backdropClass="black-backdrop-light"
  >
    <div appBottomSheetDialogContainer>
      <div
        class="standard-options-dialog-item"
        (click)="reportComment(post.postID, comment.commentID, comment.userID)"
      >
        <span class="options-img-wrap">
          <img
            class="dark-icon options-img"
            src="/assets/ic_set_bug.png"
            alt="report icon"
            loading="lazy"
          />
        </span>
        <span class="options-text"> Report </span>
      </div>
      <div
        class="standard-options-dialog-item"
        *ngIf="comment.userID === userID && comment.text !== ''"
        (click)="deleteComment(post.postID, comment, indexInList)"
      >
        <span class="options-img-wrap">
          <img
            class="dark-icon options-img"
            src="/assets/ic_set_delete.png"
            alt="delete icon"
            loading="lazy"
          />
        </span>
        <span class="options-text"> Delete </span>
      </div>

      <app-bottomsheet-bottom-branding-spacer *appShowOnlyOnMobile />
    </div>
  </mat-menu>
</div>
