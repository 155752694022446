import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog'
import { HotToastService } from '@ngneat/hot-toast'
import { EncodingService } from 'src/app/shared/services/encoding/encoding.service'
import { ChatactionComponent } from '../chataction/chataction.component'
import { ImageLoadingService } from 'src/app/shared/services/imageloading/imageloading.service'
import { Router } from '@angular/router'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { StrHlp } from 'src/app/shared/services/StringGetter/getstring.service'
import { LoadingDialogComponent } from '../loading-dialog/loading-dialog.component'
import { CacheService } from 'src/app/shared/services/caching/cache-service.service'
import { FormBuilder, FormControl } from '@angular/forms'
import { take } from 'rxjs'
import { SITE_PROTOCOL } from 'src/app/shared/constants'
import { SystemService } from 'src/app/shared/services/system/systemservice.service'

@Component({
  selector: 'app-postshareclick',
  templateUrl: './postshareclick.component.html',
  styleUrls: [
    './postshareclick.component.css',
    '../../../stylesheets/dialogs/dialogstylesheet.css'
  ]
})
export class PostshareclickComponent {
  startAtCheckbox = new FormControl(false)
  startAtInput
  startAtForm

  currVideoTimestampInSecs = 0

  navigatorShareAvailable: boolean = navigator.share !== undefined

  post: any
  private postEnv: number = 0

  isMobile = false

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public encodingService: EncodingService,
    private toast: HotToastService,
    public imgHlp: ImageLoadingService,
    private router: Router,
    public dialog: MatDialog,
    public authService: AuthService,
    private cacheService: CacheService,
    private builder: FormBuilder
  ) {
    this.post = data.post
    this.postEnv = data.postEnv ? data.postEnv : 0

    if (data.currVideoTimestamp) {
      this.currVideoTimestampInSecs = data.currVideoTimestamp
    }

    this.startAtInput = new FormControl(String(this.currVideoTimestampInSecs))

    this.startAtForm = this.builder.group({
      checkBox: this.startAtCheckbox,
      input: this.startAtInput
    })

    this.isMobile = SystemService.isMobile()
  }

  getLink(): string {
    const postIdEncoded = this.encodingService.encodeForUrlArgument(
      this.post.postID
    )

    if (this.postEnv == 0) {
      return `${SITE_PROTOCOL}://${StrHlp.APP_URL}/p/${postIdEncoded}`
    } else {
      if (this.currVideoTimestampInSecs != 0 && this.startAtCheckbox.value) {
        return `${SITE_PROTOCOL}://${StrHlp.APP_URL}/clip/${postIdEncoded}?t=${this.currVideoTimestampInSecs}`
      } else {
        return `${SITE_PROTOCOL}://${StrHlp.APP_URL}/clip/${postIdEncoded}`
      }
    }
  }

  async copyLink() {
    const link = this.getLink()
    try {
      await navigator.clipboard.writeText(link)
      this.toast.success('Copied to clipboard')
    } catch (err) {
      console.error('Failed to copy: ', err)
    }
  }

  forwardToChat() {
    const data = {
      pageTitle: 'Share this post',
      disableAutoFocus: true,
      clickCallback: (itemChat: any) => {
        const link = this.getLink()
        const caption = this.post.caption + '\n\n' + link

        const dataForCallback = {
          chatID: itemChat.chatID,
          name: itemChat.name,
          verified: itemChat.verified,
          image: itemChat.image,
          isGroup: itemChat.isGroup,
          isPrivate: itemChat.isPrivate,
          otherUserID: itemChat.otherUserID,
          isMeUser1: itemChat.isMeUser1,
          newMessagesCount: 0,

          // chatOK does not work here
          chatOK1: true,
          chatOK2: true,

          replyMessageFrom: '',
          replyMessage: '',

          // Forward stuff
          forwardMessage: caption,
          forwardImageURL: this.imgHlp.do(this.post.imagePath, 1100),
          forwardVideoID: this.post.vid,
          forwardVideoDuration: this.post.vidDuration
        }

        this.router.navigate(['msg'], { state: dataForCallback })
      }
    }

    this.dialog.open(ChatactionComponent, {
      panelClass: 'bottom-sheet-dialog',
      maxWidth: '100vw',
      width: '100%',
      data: data
    })
  }

  repost() {
    const imageArray: string[] = []

    if (this.post.imagePath) {
      imageArray.push(this.post.imagePath)
    }
    if (this.post.image2) {
      imageArray.push(this.post.image2)
    }
    if (this.post.image3) {
      imageArray.push(this.post.image3)
    }
    if (this.post.image4) {
      imageArray.push(this.post.image4)
    }
    if (this.post.image5) {
      imageArray.push(this.post.image5)
    }

    const state = {
      isRepost: true,
      repostVideoID: this.post.vid,
      repostImageURL_Array: imageArray,
      repostLocation: this.post.location,
      repostCaption: this.post.caption,
      rePostID: this.post.postID
    }
    this.router.navigateByUrl('/post', { state: state })
  }

  async shareToOtherApps() {
    if (!this.navigatorShareAvailable) {
      return
    }

    const url = this.getLink()

    this.cacheService
      .getUsername(this.post.userID)
      .pipe(take(1))
      .subscribe(async (username) => {
        const title = `@${username} on ${StrHlp.APP_NAME}`

        const hashtag = StrHlp.APP_NAME.replace(' ', '')
        const text = `${this.post.caption}\n\nSee the post by @${username} on #${hashtag}`

        // check if image exists. Vids and GIFs not allowed to by actually sent.
        const files = []
        if (this.post.imagePath) {
          // show loading as async operation is coming
          const loadingDialogRef = this.dialog.open(LoadingDialogComponent, {
            disableClose: true
          })

          try {
            const loadingURL = this.imgHlp.do(this.post.imagePath, 1100)
            const response = await fetch(loadingURL)
            const blob = await response.blob()
            const file = new File([blob], 'image.jpg', { type: blob.type })
            files.push(file)
          } catch (error) {
            console.log(error)
          }

          loadingDialogRef.close()
        }

        navigator
          .share({
            files: files,
            title: title,
            text: text,
            url: url
          })
          .then(() => {
            //console.log('The content was shared successfully');
          })
          .catch((error) => {
            console.log(error)
            console.error('Error sharing the content', error)
          })
      })
  }
}
